import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

export default function ConfirmationModal({ open, setOpen, title, description, handleOnConfirm, confirmButtonText, type }) {
  const { t } = useTranslation([resources.projects]);

  const getTypeIconBackground = () => {
    switch (type) {
      case 'info':
        return 'bg-blue-100'
      case 'error':
        return 'bg-red-100'
      case 'success':
      default:
        return 'bg-brand-green-light border border-brand-green-dark'
    }
  }

  const getTypeIcon = () => {
    switch (type) {
      case 'info':
        return <ExclamationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
      case 'error':
        return <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
      case 'success':
      default:    
        return <CheckIcon className="h-6 w-6 text-brand-green-dark" aria-hidden="true" />
    }
  }

  const getTypeButton = () => {
    switch (type) {
      case 'info':
        return 'bg-blue-500 hover:bg-blue-700 border border-transparent text-white '
      case 'error':
        return 'bg-red-500 hover:bg-red-700 border border-transparent text-white '
      case 'success':
      default:
        return 'bg-brand-green-light border-brand-green-dark border text-brand-black'
    }
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 ${getTypeIconBackground()}`}>
                      {getTypeIcon()}
                    </div>
                    
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded px-4 py-2 text-base font-medium shadow-sm sm:ml-3 sm:w-auto sm:text-sm ${getTypeButton()}}`}
                      onClick={handleOnConfirm}
                    >
                      {confirmButtonText} 
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded border border-brand-green-dark bg-white px-4 py-2 text-base font-medium text-brand-black shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('cancel')}
                    </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}