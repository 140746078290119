import React, { useEffect, useState } from 'react';
import { getBranding } from '../../api/branding';
import { useToastAction } from '../../hooks/useToastAction';

export default function SceneLayout({children}) {
    const [branding, setBranding] = useState(null);
    const fetchBrandingAction = useToastAction();

    const fetchBranding = async () => {
        fetchBrandingAction.execute(async () => {
            const brand = await getBranding();
            setBranding(brand);
        }, 'Failed to load branding');
    }

    useEffect(() => {
        fetchBranding();
    }, []);

    return (
        <>
            { 
                branding &&
                <div className="min-h-screen bg-gray-50 flex">
                    <div className="flex-1 flex flex-col justify-center py-6 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div>
                                <img className="max-w-xs w-7/12 sm:w-9/12 mb-8 sm:mb-12 bg-gray-50" src={branding?.logo || '/images/centerpoint-logo.png'} alt="CenterPoint Logo"/>
                            </div>
                            <div>
                                {children}
                            </div>
                        </div>
                    </div>
                    <div id="scene-img-container" className="hidden md:block relative w-0 flex-1">
                        <img className="absolute inset-0 h-full w-full object-cover" src={branding?.currentScene || '/images/scene-image.png'} alt="Current scene" />
                    </div>
                </div>
            }
        </>
    )
}