import React, { useState } from 'react';
import SimaProductsList from './SimaProductsList';
import SimaProductsDetails from './SimaProductsDetails';
import { SimaProductsContext } from '../../../contexts';

const MarketplaceProductsModules = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const onProductSelected = (productId) => {
    setSelectedProduct(productId);
  }

  return (
    <SimaProductsContext.Provider value={{
      selectedProductId: selectedProduct,
      onProductSelected
    }}>

      <main
        className="bg-brand-gray-light focus:outline-none h-full"
      >
        <div className="max-w-screen-3xl mx-auto mt-5 px-2 sm:px-4 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-3xl">
              <div className="w-full h-full">
                <div className='flex justify-between gap-8'>
                  <div className='w-1/2'>
                    <SimaProductsList />
                  </div>
                  <div className='w-1/2'>
                    <SimaProductsDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </SimaProductsContext.Provider>
  );
};

export default MarketplaceProductsModules;