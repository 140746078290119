import './styles.scss';
import React, { useState } from 'react';
import DateTimeParser from '../../utils/DateTimeParser';
import { AuditContext } from '../../contexts';
import AuditList from './AuditList'
import EventLog from './EventLog'

const AuditLogList = () => {
  const formInitialValues = {
    source: '',
    level: '',
    dateFrom: DateTimeParser.toLocaleDateString(DateTimeParser.subtract(new Date(), 1, 'months')),
    dateTo: DateTimeParser.toLocaleDateString(new Date()),
    search: '',
  }

  const [searchResult, setSearchResult] = useState({
    searchTimeSeconds: 0,
    totalRecords: 0
  });

  const [selectedEventLog, setSelectedEventLog] = useState(null);
  const [formValues, setFormValues] = useState(formInitialValues);

  return (
    <AuditContext.Provider value={{
      searchResult: searchResult,
      setSearchResult: setSearchResult
    }}>
      <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
        >
        <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
          {
            selectedEventLog ? (
                <EventLog onClearSelected={() => { setSelectedEventLog(null) }} eventLog={selectedEventLog} />
            ) : (
                <AuditList
                    formValues={formValues}
                    setFormValues={setFormValues}
                    onSelectEventLog={el => setSelectedEventLog(el)}
                    searchOnInit={true} />
            )
          }
          </div>
        </div>
      </main>
    </AuditContext.Provider >
  )
};

export default AuditLogList;