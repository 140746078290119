export const adminProductId = "611b8ac465064876fb35e1cf";
export const designFeatureCode = "DSF";
export const dataFeatureCode = "DDF";
export const templateFeatureCode = "DTF";

export const productModuleTypes = {
  standardWidthGlassWall: 1,
  customWidthGlassWall: 2,
  glassDoor: 3,
  glassTape: 4,
  glassWallFrame: 5,
  dryWallPartitionMount: 6,
  solidDoor: 7,
}

export const productModuleTypeNames = {
  [productModuleTypes.standardWidthGlassWall]: 'Standard Width Glass',
  [productModuleTypes.customWidthGlassWall]: 'Custom Width Glass',
  [productModuleTypes.glassDoor]: 'Glass Door',
  [productModuleTypes.dryWallPartitionMount]: 'Drywall Mount',
  [productModuleTypes.solidDoor]: 'Solid Door'
}

export const glassWallFrameTypes = {
  left: 1,
  right: 2,
  both: 3,
  none: 4
}

export const frameColors = {
  black: 'black',
  aluminum: 'aluminum'
}

export const frameColorNames = {
  [frameColors.black]: 'Black',
  [frameColors.aluminum]: 'Aluminum'
}

export const frameColorsHex = {
  [frameColors.black]: '#000000',
  [frameColors.aluminum]: '#ADB2BD'
}

export const frameProfileTypes = {
  U_Profile: 1,
  L_Profile: 2
}
export const frameProfileTypeNames = {
  [frameProfileTypes.U_Profile]: 'U Profil',
  [frameProfileTypes.L_Profile]: 'L Profil'
}

export const doorDirections = {
  left: 'LeftDoor',
  right: 'RightDoor'
}
export const doorDirectionEnums = {
  right: 1,
  left: 2,

}
export const glassWallConstants = {
  apertureHeightInAdditionToGlassHeight: 35
}
export const doorDirectionNames = {
  [doorDirections.left]: 'Venstre',
  [doorDirections.right]: 'Høyre'
}
export const doorDirectionEnumNames = {
  [doorDirectionEnums.left]: 'Venstre',
  [doorDirectionEnums.right]: 'Høyre'
}
export const productTypes = {
  glassWall: 1,
}

export const productTypeNames = {
  [productTypes.glassWall]: "Glassvegg",
}

export const productStates = {
  active: 1,
  disabled: 2,
  draft: 3,
}

export const productStateName = {
  [productStates.active]: "Aktivert",
  [productStates.disabled]: "Deaktivert",
  [productStates.draft]: "Utkast",
}

export const structurePropertyElementType = {
  selectInput: 1,
  inputNumber: 2,
  inputText: 3
}

export const logLevelTypes = {
  info: 1,
  warn: 2,
  error: 3
}

export const orderState = {
  new: 10,
  received: 20,
  picked: 30,
  shipped: 40,
  cancelled: 999,
}

export const doorThresholdTypes = {
  none: 'none',
  normal: 'normal',
  dropdown: 'dropdown'
}

export const glassWallPartitionTypes = {
  glassWall: 1,
  dryWall: 2
}

export const glassWallPartitionTypeNames = {
  [glassWallPartitionTypes.glassWall]: 'GlassWall',
  [glassWallPartitionTypes.dryWall]: 'Drywall'
}

export const metaDataCommonKeys = {
  hexcolor: 'hexcolor'
}

export const logLevels = [
  { name: '<All>', value: '' },
  {
    name: 'Information',
    value: logLevelTypes.info
  },
  {
    name: 'Warning',
    value: logLevelTypes.warn
  },
  {
    name: 'Error',
    value: logLevelTypes.error
  }
];

export const logSources = [
  { name: '<All>', value: '' },
  { name: 'CenterPoint', value: 1 },
  { name: 'IdentityServer', value: 8 },
  { name: 'Sima', value: 15 },
];

export const projectItemStatus = {
  draft: 1,
  cart: 2,
  ordered: 3
}
export const project_status = {
  in_progress: 'InProgress',
  closed: 'Closed',
  canceled: 'Cancel',
};

export const productGroups = [
  {
    "number": 99,
    "name": "Inventory,accessories",
    "parent": "inventory"
  },
  {
    "number": 91,
    "name": "Inventar, panels",
    "parent": "inventory"
  },
  {
    "number": 79,
    "name": "Doors, accessories",
    "parent": "doors"
  },
  {
    "number": 78,
    "name": "Doors, fire & sound rated",
    "parent": "doors"
  },
  {
    "number": 77,
    "name": "Doors, sliding systems",
    "parent": "doors"
  },
  {
    "number": 76,
    "name": "Doors, portal systems",
    "parent": "doors"
  },
  {
    "number": 75,
    "name": "Doors, frame wood",
    "parent": "doors"
  },
  {
    "number": 74,
    "name": "Doors, frame aluminium",
    "parent": "doors"
  },
  {
    "number": 73,
    "name": "Doors, leaves massive",
    "parent": "doors"
  },
  {
    "number": 72,
    "name": "Doors, leaves glass w/ frame",
    "parent": "doors"
  },
  {
    "number": 71,
    "name": "Doors, leaves glass frameless",
    "parent": "doors"
  },
  {
    "number": 62,
    "name": "Acoustics, wood-veneer",
    "parent": "acoustics"
  },
  {
    "number": 61,
    "name": "Acoutics, wool-fabric",
    "parent": "acoustics"
  },
  {
    "number": 59,
    "name": "Ceiling, accesoriess",
    "parent": "ceiling"
  },
  {
    "number": 56,
    "name": "Ceiling, decoratives",
    "parent": "ceiling"
  },
  {
    "number": 55,
    "name": "Ceiling, metal",
    "parent": "ceiling"
  },
  {
    "number": 54,
    "name": "Ceiling, wood wool",
    "parent": "ceiling"
  },
  {
    "number": 53,
    "name": "Ceiling, wool",
    "parent": "ceiling"
  },
  {
    "number": 52,
    "name": "Ceiling, minerals",
    "parent": "ceiling"
  },
  {
    "number": 51,
    "name": "Ceiling, systems",
    "parent": "ceiling"
  },
  {
    "number": 49,
    "name": "Wall, decoration wall",
    "parent": "wall"
  },
  {
    "number": 44,
    "name": "Wall Panel",
    "parent": "wall"
  },
  {
    "number": 42,
    "name": "Wall, partition wall",
    "parent": "wall"
  },
  {
    "number": 41,
    "name": "Wall, glas wall",
    "parent": "wall"
  },
  {
    "number": 39,
    "name": "Floor, accessories",
    "parent": "floor"
  },
  {
    "number": 33,
    "name": "Floor, decor carpet",
    "parent": "floor"
  },
  {
    "number": 32,
    "name": "Floor, carpet tiles",
    "parent": "floor"
  },
  {
    "number": 31,
    "name": "Floor, vinyl tiles",
    "parent": "floor"
  },
  {
    "number": 29,
    "name": "Light, cabels & accessories",
    "parent": "light"
  },
  {
    "number": 26,
    "name": "Light, linear LED fixtures",
    "parent": "light"
  },
  {
    "number": 25,
    "name": "Light, 230V tracks & fixtures",
    "parent": "light"
  },
  {
    "number": 24,
    "name": "Light, Low voltage tracks & fixtures",
    "parent": "light"
  },
  {
    "number": 23,
    "name": "Light, profiles & LED stipes",
    "parent": "light"
  },
  {
    "number": 22,
    "name": "Light, downlights",
    "parent": "light"
  },
  {
    "number": 21,
    "name": "Light, LED panels & drivers",
    "parent": "light"
  },
  {
    "number": 19,
    "name": "Furniture, accessories",
    "parent": "furniture"
  },
  {
    "number": 12,
    "name": "Furntiure, lounge",
    "parent": "furniture"
  },
  {
    "number": 11,
    "name": "Furniture, work",
    "parent": "furniture"
  }
]
