import { SearchIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import { v4 as uuidv4 } from 'uuid';

const SearchActions = {
  checkbox: 'checkbox'
}

const CheckboxSearchAction = ({label, onChange}) => {
  const actionId = uuidv4();

  return (
    <div className="mt-2 flex items-center">
      <input id={actionId} type="checkbox" onChange={onChange} className="h-4 w-4 text-brand-black focus:ring-brand-black border-brand-green-dark rounded" />
      <label htmlFor={actionId} className="pl-2 text-sm font-medium text-gray-500">{label}</label>
    </div>
  )
}

const Search = ({onSearch, actions, className}) => {
  const { t } = useTranslation([resources.common]);

  return (
    <div className={`flex pb-2 justify-between ${className}`}>
      <div className="w-96">
        <label htmlFor="desktop-search-candidate" className="sr-only">
          {t('search')}
        </label>
        <div className="flex rounded-md shadow-sm">
          <div className="relative flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="desktop-search-candidate"
              id="desktop-search-candidate"
              className="hidden focus:ring-gray-400 focus:border-gray-400 w-full rounded-md pl-10 sm:block sm:text-sm border-brand-green-dark"
              placeholder= {`${t('search')}...`}
              onChange={onSearch}
            />
          </div>
        </div>
      </div>
      {
        actions?.map((action) => {          
          switch(action.type) {
            case 'checkbox':
              return <CheckboxSearchAction label={action.label} onChange={action.onChange} />
            default:
              return <></>;
          }
        })
      }
    </div>
  );
};

Search.searchActions = SearchActions;

export default Search;
