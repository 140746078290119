import React, { useState, useEffect, useContext } from 'react';
import { Form, Formik } from 'formik';
import { ArrowRightIcon } from '@heroicons/react/outline';
import FormikInput from '../../components/Form/Formik/FormikInput';
import { FormikSubmitButton } from '../../components/Form/Formik/FormikSubmit';
import * as Yup from 'yup';
import { getCountries } from '../../api/userProfile';
import { FormikSelectInput } from '../../components/Form/Formik/FormikSelect';
import { createNewCompany, validateOrgNumber } from '../../api/userProfile';
import toast from 'react-hot-toast';
import { useToastAction } from '../../hooks/useToastAction';
import { OidcRoutesContext } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const formInitialValues = {
    orgNumber: '',
    companyName: '',
    country: '',
    homePageUri: '',
    contactEmail: ''
}

const statusTypes = {
    Success: 0,
    Failed: 1,
    Warning: 2
}

const CompanyRegistration = ({onCompleted}) => {
    const { t } = useTranslation([resources.company]);
    const userContext = useContext(OidcRoutesContext);
    const [countries, setCountries] = useState([]);
    const [formValues, setFormValues] = useState(formInitialValues);
    const validationSchema = Yup.object().shape({
        orgNumber: Yup.number().typeError(`${t('onlyNumbersareAllowed')}`).required(`${t('fieldIsrequired')}`),
        country: Yup.string().required(`${t('fieldIsrequired')}`),
        companyName: Yup.string().required(`${t('fieldIsrequired')}`),
        contactEmail: Yup.string().required(`${t('fieldIsrequired')}`).email(`${t('validEmail')}`),
    });

    const countriesAction = useToastAction();
    const saveAction = useToastAction(false, 'saveAction', true);

    const fetchCountries = async () => {
        countriesAction.execute(async () => {
          const response = await getCountries();
          setCountries(response);
          setFormValues({...formValues, country: response[0].code });
        }, `${t('failedToLoadData')}`);
    };

    const handleSubmit = async (values, actions) => {
        let isValidOrgNo = true;
        if(values.country.toLowerCase() === "no")
        {
            isValidOrgNo = await validateOrgNumber(values.orgNumber);
        }
        if(isValidOrgNo)
        {
            saveAction.execute(
            async () => {
                const countryObject = countries.find((c) => c.code === values.country);
                var request = {...values, country: countryObject, userId: userContext.userProfile?.userId};
                await createNewCompany(request);
                onCompleted();
            },
            `${t('failedToSaveData')}`,
            null,
            null,
            () => { actions.setSubmitting(false); }
            );
        } else {
            toast.error(`${t('invalidorganizationNumber')}`);
        }
    };

    useEffect(async () => {
        await fetchCountries();
    }, []);

    return (
        <>
            <div>
                <h1 className="text-3xl text-gray-900 font-bold mb-3">{t('letsGetStarted')}</h1>
                <h3 className="mt-6 text-xl text-gray-900">
                    {t('pleaseProvideDataAboutyourCompany')}
                </h3>
            </div>
            <div className="mt-6 sm:mt-8">
                <Formik 
                    validationSchema={validationSchema}
                    initialValues={formValues} 
                    onSubmit={handleSubmit} 
                    enableReinitialize={true}>
                    {(props) => (
                        <Form>
                            <div className="mt-2">
                                <FormikInput
                                    label={t('companyname')}
                                    name="companyName"
                                    formikProps={props}
                                />
                            </div>
                            <div className="mt-2">
                                <FormikInput
                                    label={t('organizationalNumber')}
                                    name="orgNumber"
                                    formikProps={props}
                                />
                            </div>
                            <div className="mt-2">
                                <FormikSelectInput
                                    label={t('country')}
                                    name="country"
                                    options={countries.map((c) => ({
                                        name: c.name,
                                        value: c.code
                                    }))}
                                    formikProps={props}
                                    />
                            </div>
                            <div className="mt-2">
                                <FormikInput
                                    label={t('homePage')}
                                    name="homePageUri"
                                    formikProps={props}
                                />
                            </div>
                            <div className="mt-2">
                                <FormikInput
                                    label={t('contactEmail')}
                                    name="contactEmail"
                                    formikProps={props}
                                />
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <FormikSubmitButton
                                id="save"
                                className="sm:col-end-3 flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-black hover:bg-indigo-700 focus:outline-none"
                                formikProps={props}
                            >
                                {t('next')}
                                <ArrowRightIcon
                                className="ml-2 h-5 w-5 font-bold"
                                />
                            </FormikSubmitButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
};

export default CompanyRegistration;
