import React, { useContext, useState } from "react";
import Button from "../../../components/Button";
import { glassWallConstants, productModuleTypes, productStateName, productTypeNames, productTypes } from "../../../utils/constants";
import { Formik } from "formik";
import { SelectInput } from "../../../components/Form/SelectInput";
import { TextInput } from "../../../components/Form/TextInput";
import { ConfigurationProductContext } from "../../../contexts";




export default function ConfigurationProductForm({ product, onCancel, onSave }) {

    const { configurationModules } = useContext(ConfigurationProductContext)

    const createInitialValues = (productConfig) => {

        const { productConfigurationModules, ...productProps } = productConfig;

        const configuredModules = configurationModules.filter(x => productConfigurationModules.includes(x.id));

        const standardGlass = configuredModules.find(x => x.type == productModuleTypes.standardWidthGlassWall);
        const customGlass = configuredModules.find(x => x.type == productModuleTypes.customWidthGlassWall);
        const glassDoor = configuredModules.find(x => x.type == productModuleTypes.glassDoor);
        const solidDoor = configuredModules.find(x => x.type == productModuleTypes.solidDoor);
        const glassTape = configuredModules.find(x => x.type == productModuleTypes.glassTape);
        const glassWallFrame = configuredModules.find(x => x.type == productModuleTypes.glassWallFrame);
        const dryWallPartitionMount = configuredModules.find(x => x.type == productModuleTypes.dryWallPartitionMount);

        const glassWall = {
            standardGlass: standardGlass?.id,
            customGlass: customGlass?.id,
            glassDoor: glassDoor?.id,
            solidDoor: solidDoor?.id,
            glassTape: glassTape?.id,
            glassWallFrame: glassWallFrame?.id,
            dryWallPartitionMount: dryWallPartitionMount?.id
        };


        const values = {
            ...productProps,
            glassWall: {
                ...glassWall
            }
        }
        return values;
    }

    const [initialValues, setInitialValues] = useState(createInitialValues(product))

    const convertValuesToProductConfig = (values) => {
        const { glassWall, ...props } = values;

        let productConfigurationModules = [];

        let productOptions = {}

        if (props.productType === productTypes.glassWall) {
            productConfigurationModules = Object.keys(glassWall).map(k => glassWall[k]).filter(x => !!x);

            const configuredModules = configurationModules.filter(x => productConfigurationModules.includes(x.id));
            const standardGlass = configuredModules.find(x => x.type == productModuleTypes.standardWidthGlassWall);
            const heightValues = standardGlass.wall?.height;

            productOptions = {
                glassWallConfiguration: {
                    minHeight: +(heightValues?.minValue || 2100) + glassWallConstants.apertureHeightInAdditionToGlassHeight,
                    maxHeight: +(heightValues?.maxValue || 2750) + glassWallConstants.apertureHeightInAdditionToGlassHeight
                }

            };
        }

        return {
            ...props,
            productConfigurationModules: [...productConfigurationModules],
            productOptions: productOptions
        }
    }


    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    debugger;
                    const configProduct = convertValuesToProductConfig(values)
                    console.log(configProduct)
                    onSave(configProduct, () => {
                        setSubmitting(false);
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    validateForm,
                    setFieldValue
                    /* and other goodies */
                }) => (
                    <form
                        className="text-left space-y-2"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(values);
                        }}
                    >
                        <div className='space-y-4'>
                            <TextInput
                                name={"name"}
                                value={values?.name}
                                label='Name'
                                required={true}
                                onChange={(value, name) => handleChange({ target: { name, value } })}
                            />

                            <SelectInput
                                name={"productType"}
                                value={values?.productType}
                                label={"Product Type"}
                                onChange={(value, name) => {
                                    handleChange({ target: { name, value: +value } })
                                }}
                                options={
                                    [{ name: '', value: null }, ...Object.keys(productTypeNames).map(k => ({ name: productTypeNames[k], value: k }))]
                                }
                            />
                            <SelectInput
                                name={"state"}
                                value={values?.state}
                                label={"Status"}
                                onChange={(value, name) => {
                                    handleChange({ target: { name, value: +value } })
                                }}
                                options={
                                    [{ name: '', value: null }, ...Object.keys(productStateName).map(k => ({ name: productStateName[k], value: k }))]
                                }
                            />
                            {
                                values?.productType === productTypes.glassWall && (
                                    <GlassWallProductConfigFields handleChange={handleChange} values={values} product={product} />
                                )
                            }
                        </div>
                        <div>
                            <div className="flex justify-end space-x-2 mt-6">
                                <Button onClick={e => onCancel()} variant={'secondary'} size={'md'} >Cancel</Button>
                                <Button variant={'primary'} size={'md'} type='submit' >Save</Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

const GlassWallProductConfigFields = ({ values, handleChange, product }) => {
    console.log(values);
    const { configurationModules } = useContext(ConfigurationProductContext)

    const getModulesByType = (type) => {
        return configurationModules?.filter(x => x.type == type).map(x => ({ name: x.name, value: x.id }));
    }

    const standardGlassConfigs = getModulesByType(productModuleTypes.standardWidthGlassWall);
    const customGlassConfigs = getModulesByType(productModuleTypes.customWidthGlassWall);
    const glassDoorConfigs = getModulesByType(productModuleTypes.glassDoor);
    const solidDoorConfigs = getModulesByType(productModuleTypes.solidDoor);
    const glassTapeConfigs = getModulesByType(productModuleTypes.glassTape);
    const glassWallFrameConfigs = getModulesByType(productModuleTypes.glassWallFrame);
    const dryWallPartitionMountConfigs = getModulesByType(productModuleTypes.dryWallPartitionMount);

    return (
        <div>
            <SelectInput
                name={"glassWall.standardGlass"}
                value={values?.glassWall?.standardGlass}
                label={"Standard Glass"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...standardGlassConfigs]
                }
            />
            <SelectInput
                name={"glassWall.customGlass"}
                value={values?.glassWall?.customGlass}
                label={"Custom Glass"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...customGlassConfigs]
                }
            />
            <SelectInput
                name={"glassWall.glassDoor"}
                value={values?.glassWall?.glassDoor}
                label={"Glass Dør"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...glassDoorConfigs]
                }
            />
            <SelectInput
                name={"glassWall.solidDoor"}
                value={values?.glassWall?.solidDoor}
                label={"Tett Dør"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...solidDoorConfigs]
                }
            />
            <SelectInput
                name={"glassWall.glassTape"}
                value={values?.glassWall?.glassTape}
                label={"Glass Tape"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...glassTapeConfigs]
                }
            />
            <SelectInput
                name={"glassWall.glassWallFrame"}
                value={values?.glassWall?.glassWallFrame}
                label={"Glassvegg Ramme"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...glassWallFrameConfigs]
                }
            />
            <SelectInput
                name={"glassWall.dryWallPartitionMount"}
                value={values?.glassWall?.dryWallPartitionMount}
                label={"Gips skillevegg feste"}
                onChange={(value, name) => {
                    handleChange({ target: { name, value } })
                }}
                options={
                    [{ name: '', value: null }, ...dryWallPartitionMountConfigs]
                }
            />
        </div>
    )
}
