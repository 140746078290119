import { useEffect, useState } from 'react';
import { useClassNames } from '../../hooks/useClassNames';
import * as Yup from 'yup';
import { runYupValidationSchemaSync } from '../../utils/yup';

export const TextInput = ({
  name,
  label = null,
  value,
  onChange,
  type = null,
  placeholder = null,
  required = false,
  onKeyPress = null,
  validation: validate,
  horizontal = false,
  disabled,
  min = null,
  max = null,
  width = 'w-full',
  labelWidth = null,
  labelColor = null,
  errors = null
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [validationMessage, setValidationMessage] = useState('')
  const [blurred, setBlurred] = useState(false)
  const classes = useClassNames()
  const [hasValidationError, setHasValidationError] = useState(false)

  const createValidationSchemaOnMinAndMax = () => {
    let schema = Yup.number()

    if (min) {
      schema = schema.min(min, `Minimum value is ${min}`)
    }
    if (max) {
      schema = schema.max(max, `Maximum value is ${max}`)
    }

    return schema
  }
  
  const checkForValidationErrors = (value) => {
    let errors;

    if(type === 'number') {
      errors =  runYupValidationSchemaSync(createValidationSchemaOnMinAndMax(), value)
      setHasValidationError(errors)
      setValidationMessage(errors?.error_1)
    }

    return errors
  }

  const handleOnChange = (value, name) => {
    const errors = checkForValidationErrors(value)
    setInputValue(value)

    if(!errors) {
      onChange(value, name)
    } 
  }

  if (blurred && validate) {
    setHasValidationError(!validate(inputValue))
  }

  useEffect(() => {
      setHasValidationError(errors && errors[name])
  }, [errors])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const classNames = classes.classNames(
    "shadow-sm block sm:text-sm border-brand-green-dark rounded",
    hasValidationError
      ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-brand-green-light focus:border-brand-green-light',
    disabled ? 'bg-gray-100' : '',
    width
  )

  return (
    <>
      {
        label &&
        <div className={classes.classNames(horizontal
          ? 'flex items-center mt-1 gap-2'
          : '',
        )}>
          <label htmlFor={name} className={classes.classNames(
            horizontal ? (labelWidth ? labelWidth : 'flex-1') : 'mt-1',
            hasValidationError ? 'text-red-600' : labelColor ? '' : 'text-brand-black',
            disabled ? 'opacity-50' : '',
            'block text-sm font-medium'
          )}>
            {label}
            {required === true && <span className="text-red-400"> *</span>}
          </label>
          <div className={classes.classNames(horizontal
            ? 'flex-1'
            : 'mt-1'
          )}>
            <input
              type={type || "text"}
              name={name}
              value={inputValue || ''}
              onChange={(evt) => {
                let value = evt.target.value;
                // if(type === 'number'){
                //   const numValue = +value
                //   if(max && numValue > +max){
                //     value = '' + max
                //   }
                //   if(min && numValue < +min){
                //     value = '' + min
                //   }
                // }

                handleOnChange(type === 'number' ? +value : value, name)
              }}
              onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
              className={classNames}
              placeholder={placeholder}
              onBlur={() => setBlurred(true)}
              min={min}
              max={max}
              disabled={disabled}
            />
            {
              hasValidationError &&
              <p className="mt-2 text-sm text-red-600" id="email-error">
                {validationMessage}
              </p>
            }
          </div>
        </div>
      }
      {
        !label &&
        <input
          type={type || "text"}
          name={name}
          value={inputValue || ''}
          onChange={(evt) => {
            handleOnChange(evt.target.value, name)}
          }
          onKeyPress={(evt) => onKeyPress && onKeyPress(evt)}
          className={classNames}
          placeholder={placeholder}
          onBlur={() => setBlurred(true)}
          min={min}
          max={max}
          disabled={disabled}
        />
      }
    </>
  )
}