import React, { useContext } from "react"
import { resources, translations } from "../../../../../translations/translationsConstants";
import { useTranslation } from "react-i18next";
import { FormikSelectInput } from "../../../../../components/Form/Formik/FormikSelect";
import { ModifyElementType, ModifyElementTypeNames } from "../../ModuleTypes";
import FormikInput from "../../../../../components/Form/Formik/FormikInput";
import { FormProductModuleConfigContext } from "../../../../../contexts";
import ProductPropertyOptions from "./ProductPropertyOptions";
import Doors from "../doors";
import DoorFrames from "../doorFrames";
import FrameProfiles from "../frameProfiles";
import DrywallMounts from "../drywallMounts";

const configurators = {
    doors: 'doors',
    solidDoors: 'soliddoors',
    doorFrames: 'doorframes',
    frameProfiles: 'frameprofiles',
    dryWallMounts: 'dryWallMounts',
    default: 'default'
}

const ProductModulePropertyForm = ({ props, fieldName, useProductModuleRef = false, fieldConfig, configurator = configurators.default }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { avaialableProducts, ...formModuleContext } = useContext(FormProductModuleConfigContext);
    const field = formModuleContext.data[fieldName]
    const { values } = props;

    const handleUpdateConfig = (updatedField, onComplete) => {
        props.setFieldValue(fieldName, updatedField);
        formModuleContext.onUpdate(formModuleContext.data);
        onComplete();
    }

    if (configurator == configurators.doors || configurator == configurators.solidDoors) {
        return (
            <Doors doors={field} avaialableProducts={avaialableProducts} onUpdateDoors={handleUpdateConfig} isSolidDoors={configurator == configurators.solidDoors} />
        )
    }

    if (configurator == configurators.doorFrames) {
        return (
            <DoorFrames doorFrames={field} avaialableProducts={avaialableProducts} onUpdate={handleUpdateConfig} />
        )
    }

    if (configurator == configurators.frameProfiles) {
        return (
            <FrameProfiles frameProfiles={field} avaialableProducts={avaialableProducts} onUpdate={handleUpdateConfig} />
        )
    }
    if (configurator == configurators.dryWallMounts) {
        return (
            <DrywallMounts mounts={field} avaialableProducts={avaialableProducts} onUpdate={handleUpdateConfig} />
        )
    }

    return (
        <>
            <div className="grid grid-cols-4 mb-4 gap-4">
                <FormikSelectInput
                    label={translateProducts(translations.simaProducts.configModulesForm.modifyElement)}
                    name={`${fieldName}.modifyElement`}
                    options={Object.getOwnPropertyNames(ModifyElementTypeNames).map((key) => { return { name: translateProducts(ModifyElementTypeNames[key]), value: key } })}
                    formikProps={props}
                    valueIsNumber={true}
                />
                <FormikInput
                    label={translateProducts(translations.simaProducts.configModulesForm.displayName)}
                    name={`${fieldName}.displayName`}
                    formikProps={props}
                />
                {
                    values && values[fieldName].modifyElement === ModifyElementType.SelectInput && (
                        <FormikSelectInput
                            label={"Default value"}
                            name={`${fieldName}.value`}
                            options={
                                [...(field.propertyOptions || []).map(({ name, value }) => ({ name, value }))]
                            }
                            showEmptyOption={!field.value}
                            formikProps={props}
                        />
                    )
                }
                {
                    values && values[fieldName].modifyElement != ModifyElementType.SelectInput && (<>

                        <FormikInput
                            label={translateProducts(translations.simaProducts.configModulesForm.maxValue)}
                            name={`${fieldName}.maxValue`}
                            formikProps={props}
                        />
                        <FormikInput
                            label={translateProducts(translations.simaProducts.configModulesForm.minValue)}
                            name={`${fieldName}.minValue`}
                            formikProps={props}
                        />
                    </>)
                }


            </div>
            <div className="grid grid-cols-4 mb-4 gap-4">
                {
                    useProductModuleRef && (
                        <div className="col-span-2">
                            <FormikSelectInput
                                label={"Product"}
                                name={`${fieldName}.productModuleRef`}
                                formikProps={props}

                                options={[{ name: '', value: null },
                                ...avaialableProducts.map(x => {
                                    return { name: x.name, value: x.id }
                                })
                                ]}
                                showEmptyOption={props.values.productModuleRef === ''}
                            />
                        </div>
                    )
                }
            </div>
            {
                values && values[fieldName]?.modifyElement == ModifyElementType.SelectInput && (
                    <div className="mt-4">
                        <ProductPropertyOptions
                            metaDataPropertyName={fieldName}
                            onUpdatePropertyOptions={(propertyOptions) => {
                                if(!field.value && propertyOptions?.length > 0){
                                    field.value = propertyOptions[0].value;
                                }
                                field.propertyOptions = propertyOptions;
                                formModuleContext.getPropertyOptionsProducts();
                                formModuleContext.onUpdate(formModuleContext.data);
                            }}
                            fieldConfig={fieldConfig}
                            propertyOptions={field.propertyOptions}
                            products={formModuleContext.propertyOptionsProducts} />
                    </div>)
            }
        </>
    )
}

ProductModulePropertyForm.configurators = configurators;
export default ProductModulePropertyForm;