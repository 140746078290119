import { get, post, BaseUrl } from './api'


export const getMyUser = async () => {
  return await get('api/accountSettings/myAccount', BaseUrl.Api);
}

export const updateUserProfile = async (request) => {
  return await post('api/AccountSettings/myAccount', request, null, BaseUrl.Api);
}

export const getLanguages = async () => {
  return await get('api/accountSettings/languages', BaseUrl.Api);
}