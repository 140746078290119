import { useToastAction } from '@metaforcelabs/metaforce-core';
import { useEffect, useState } from 'react';
import {
  getCompaniesSettingsApi,
  updateSettingsCompanyApprovals
} from '../../../api/simaAdmin';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import { Checkbox } from '../../../components/Form/Checkbox';
import Button from '../../../components/Button';

const SimaSettings = () => {
  const { t:translationSima } = useTranslation([resources.simaAdmin]);
  const { t:translationCommon } = useTranslation([resources.common]);
  const { t } = useTranslation([resources.internal]);
  const [settings, setSettings] = useState({
    approvals: {
      activateCustomerAutomaticaly: false
    }
  });

  const getCompaniesSettingsAction = useToastAction();
  const updateSettingsCompanyApprovalsAction = useToastAction();

  const getCompaniesSettings = () => {
    getCompaniesSettingsAction.execute(async () => {
      const result = await getCompaniesSettingsApi();
      setSettings(result);
    }, `${t('failedMessage')}`);
  };

  const update = () => {
    updateSettingsCompanyApprovalsAction.execute(async () => {
      await updateSettingsCompanyApprovals({
        activateCustomerAutomaticaly: settings.approvals.activateCustomerAutomaticaly
      });
    }, `${t('failedtoUpdate')}`, `${t('settingsSaved')}`);
  };

  useEffect(() => {
    getCompaniesSettings();
  }, []);

  return (
  <main
    className="main-container focus:outline-none h-full mt-5">
      <div className="max-w-screen-3xl mx-auto px-2 sm:px-4 lg:px-8">
      <div className="flex flex-col pb-5 mb-6 border-b border-brand-green-dark">
        <h1 className="text-3xl font-bold">
          {translationSima(translations.simaAdmin.settings.title)}
        </h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          {translationSima(translations.simaAdmin.settings.subtitle)}
        </p>
      </div>

      <div className="relative mt-4">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <div className="md:grid md:grid-cols-4 md:gap-6 pt-5">
              <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                <div className="grid grid-cols-1">
                  <Checkbox
                    name="setting"
                    label={translationSima(translations.simaAdmin.settings.activeStatusByDefault)}
                    checked={settings.approvals.activateCustomerAutomaticaly}
                    onChange={(checked) => {
                      setSettings({
                        ...settings,
                        approvals: {
                          ...settings.approvals,
                          activateCustomerAutomaticaly: checked
                        }
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-6">
            <div className="ml-auto">
              <Button variant={Button.variants.primary} onClick={update}>
                {translationCommon(translations.common.save)}
              </Button>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </main>
  );
};

export default SimaSettings;
