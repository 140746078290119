import React, {useContext, useState} from 'react'
import {ManageTeamContext} from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

export default function FilterPanel({onSearchUsers}) {
  const defaultFilter = {email: '', role: '', name: ''};
  const [searchFilter, setSearchFilter] = useState(defaultFilter);
  const manageTeamContext = useContext(ManageTeamContext);
  const { t } = useTranslation([resources.admin]);
  const searchUsers = () => {
    onSearchUsers(searchFilter);
  }
  const handleChange = ({name, value}) => {
    setSearchFilter(prev => {
      const newObj = {...prev, [name]: value};
      return newObj;
    });
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-4 w-full">
      <div className="lg:grid grid-cols-4">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {t('Email')}
        </label>
        <div className="mt-1 col-span-3">
          <input type="text" name="email" id="email"
                 onChange={e => handleChange(e.target)}
                 value={searchFilter.email}
                 className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"/>
        </div>
      </div>
      <div className="lg:grid grid-cols-4">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {t('role')}
        </label>
        <div className="mt-1 col-span-3">
          <select name="role" id="role"
                  onChange={e => handleChange(e.target)}
                  value={searchFilter.role}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md">
            <option value=""></option>
            {
              manageTeamContext?.roles.map(r => {
                return <option key={r.roleId} value={r.roleId}>{r.roleName}</option>
              })
            }
          </select>
        </div>
      </div>
      <div className="lg:grid grid-cols-4">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {t('name')}
        </label>
        <div className="mt-1 col-span-3">
          <input type="text" name="name" id="name"
                 onChange={e => handleChange(e.target)}
                 value={searchFilter.name}
                 className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"/>
        </div>
      </div>
      <div className="col-span-full">
        <div className="grid grid-cols-2 sm:grid-cols-4">
          <button type="button"
                  onClick={e => {
                    searchUsers();
                  }}
                  className="col-span-2 sm:col-span-1 sm:col-end-5 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {t('search')}
          </button>
        </div>
      </div>
    </div>
  );
}
