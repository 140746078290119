import { translations } from '../../translations/translationsConstants';

export const ProductModuleType = {
    WidthBuildingElementBegin: 0,
    StandardWidthWallElement: 1,
    CustomWidthWallElement: 3,
    DoorElement: 4,
    GlassWall: 5,
    DoorProducts: 6,
    WidthBuildingElementEnd: 20,
    FrameProfile_U: 21,
    FrameProfile_L: 22,
    ModuleAccessory: 41,
    GlassCutTop: 51,
    GlassCutSideWPolish: 52,
    DrywallPartionMount: 61
}

export const ProductModuleTypes = {
    [ProductModuleType.WidthBuildingElementBegin]: translations.simaProducts.productTypes.widthBuildingElementBegin,
    [ProductModuleType.StandardWidthWallElement]: translations.simaProducts.productTypes.standardWidthWallElement,
    [ProductModuleType.CustomWidthWallElement]: translations.simaProducts.productTypes.customWidthWallElement,
    [ProductModuleType.DoorElement]: translations.simaProducts.productTypes.doorElement,
    [ProductModuleType.GlassWall]: translations.simaProducts.productTypes.glassWall,
    [ProductModuleType.DoorProducts]: translations.simaProducts.productTypes.doorProducts,
    [ProductModuleType.WidthBuildingElementEnd]: translations.simaProducts.productTypes.widthBuildingElementEnd,
    [ProductModuleType.FrameProfile_U]: translations.simaProducts.productTypes.frameProfile_U,
    [ProductModuleType.FrameProfile_L]: translations.simaProducts.productTypes.frameProfile_L,
    [ProductModuleType.ModuleAccessory]: translations.simaProducts.productTypes.moduleAccessory,
    [ProductModuleType.GlassCutTop]: translations.simaProducts.productTypes.glassCutTop,
    [ProductModuleType.GlassCutSideWPolish]: translations.simaProducts.productTypes.glassCutSideWPolish,
    [ProductModuleType.DrywallPartionMount]: translations.simaProducts.productTypes.drywallPartionMount,
    [ProductModuleType.DrywallPartionMount]: translations.simaProducts.productTypes.drywallPartionMount,
}