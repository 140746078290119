const Badge = ({
  type = 'success', // 'success', 'error', 'warn'
  text,
  className
}) => {
  const colorClasses = () => {
    switch (type) {
      case 'success':
        return 'bg-brand-green-light text-brand-green-dark border border-brand-green-dark';
      case 'error':
        return 'bg-red-100 text-red-800 border border-red-800';
      case 'warn':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-800';
      case 'info':
        return 'bg-blue-100 text-blue-800 border border-blue-800';
      case 'default':
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-800';
    }
  };

  return (
    <span
      className={`inline-flex items-center px-2 py-0.5 rounded text-sm font-medium ${colorClasses()} ${className}}`}
    >
      {text}
    </span>
  );
};
export default Badge;
