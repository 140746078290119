import { useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../../translations/translationsConstants';
import { getProductsByIds } from '../../../../../api/simaProductsConfiguration';
import { FormProductModuleConfigContext } from '../../../../../contexts';
import ProductConfigurationModuleName from '../productConfigurationModuleName';
import { Form, Formik } from 'formik';
import ProductModulePropertyForm from './ProductModulePropertyForm';
import { FormikSubmitButton } from '../../../../../components/Form/Formik/FormikSubmit';
import FormikErrorMessage from '../../../../../components/Form/Formik/FormikErrorMessage';
import * as Yup from 'yup';

const CommonForm = ({ configModule, onUpdate, onNameChange, fields, propName, avaialableProducts, validationSchema = Yup.object().shape({}) }) => {
    const property = configModule[propName];
    const { classNames } = useClassNames();
    const { t: translateCommon } = useTranslation([resources.common]);
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const [propertyOptionsProducts, setPropertyOptionsProducts] = useState([]);
    const [currentField, setCurrentField] = useState(fields[0]);
    const getAction = useToastAction();

    const getPropertyOptionsProducts = () => {
        const productsIds = property[currentField.fieldName].propertyOptions?.map(x => x.productModuleRef) || [];
        getAction.execute(async () => {
            const result = await getProductsByIds(productsIds);
            setPropertyOptionsProducts(result);
        }, 'Failed to load config module');
    };

    // const validationSchema = Yup.object().shape(
    //   fields.reduce((prev, curr) => {
    //     return { ...prev, [curr.fieldName]: Yup.string().required("Field is required") }
    //   }, {}));

    const handleOnTabItemClick = (tab, e) => {
        e.preventDefault();
        setCurrentField({ ...tab });
    };

    const isCurrentField = (field) => {
        return field.fieldName === currentField.fieldName;
    }

    const createWidths = () => {
        const widthField = fields.find(x => x.useAsWidthMap);
        if (!widthField) { return []; }
        let widths = (property[widthField.fieldName] || []).map(x => ({ name: `${x.width || 0}mm`, value: x.width || 0 })).filter((value, i, self) => self.indexOf(value) === i)

        if (widthField.metaDataMap) {
            widths = widths.map(x => ({ ...x, metaData: { ...widthField.metaDataMap(property[widthField.fieldName].find(z => x.value == z.width)) } }))
        }
        return widths;
    }

    useEffect(() => {
        getPropertyOptionsProducts();
    }, [currentField])

    return (
        <>
            {property &&
                <FormProductModuleConfigContext.Provider value={{
                    data: property,
                    propertyOptionsProducts: propertyOptionsProducts,
                    getPropertyOptionsProducts: getPropertyOptionsProducts,
                    onUpdate: onUpdate,
                    avaialableProducts: avaialableProducts,
                    availableWidths: createWidths()
                }}>
                    <ProductConfigurationModuleName name={configModule.name} onUpdate={name => onNameChange(name)} />
                    <nav className="flex justify-between border-b border-brand-green-dark pb-2 mb-2">
                        <div className="block">
                            <ol className="flex items-center space-x-4">
                                {fields.map((field, index) => (
                                    <li key={index}>
                                        <div className=" flex items-center">
                                            <button
                                                className={classNames(
                                                    isCurrentField(field)
                                                        ? 'bg-gray-300 text-gray-900'
                                                        : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900 ',
                                                    'block rounded-md py-1 px-3 text-sm font-medium'
                                                )}
                                                onClick={(e) => handleOnTabItemClick(field, e)}>
                                                {translateProducts(field.name)}
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </nav>
                    <Formik initialValues={property} onSubmit={(values, actions) => {
                        onUpdate(values, actions)
                    }} enableReinitialize={true} validationSchema={validationSchema}>
                        {(props) => (
                            <Form className='space-y-4 divide-y divide-gray-300'>
                                <div className="">
                                    <ProductModulePropertyForm fieldConfig={currentField} props={props} fieldName={currentField.fieldName} useProductModuleRef={currentField.useProductModuleRef} configurator={currentField.configurator} />
                                    <div>
                                        {
                                            Object.keys(props.errors).map(k => (<FormikErrorMessage name={k} />))
                                        }
                                    </div>
                                    <div className="flex mt-4 justify-end">
                                        <FormikSubmitButton
                                            text={translateCommon(translations.common.save)}
                                            formikProps={props}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </FormProductModuleConfigContext.Provider>}
        </>
    );
}

export default CommonForm;