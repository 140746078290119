import { Form, Formik } from 'formik';
import { Table as TableCore, useTableActions } from '@metaforcelabs/metaforce-core';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import * as Yup from 'yup';
import FileInput from '../../../components/Form/FileInput';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { SimaSelectedProductContext } from '../../../contexts';
import { resources, translations } from '../../../translations/translationsConstants';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Checkbox } from '../../../components/Form/Checkbox';
import { useToastAction } from '../../../hooks/useToastAction';
import { uploadDocumentOnProduct, downloadProductDocument, removeProductDocument, previewProductDocument, replaceDocumentOnProduct } from '../../../api/simaProductsConfiguration';
import FormDataMapper from '../../../utils/FormDataMapper';
import { DownloadIcon, TrashIcon, DocumentIcon, UploadIcon, TableIcon } from '@heroicons/react/outline';
import Modal from '../../../components/Modal/index';
import Button from '../../../components/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';

const initialDocumentData = {
  category: "FDV",
  language: "English",
  documentId: '',
  type: 'PDF',
  content: ''
}

const ProductDocuments = () => {
  const { t:translateProducts } = useTranslation([resources.simaProducts]);
  const { t:translateCommon } = useTranslation([resources.common]);
  const [formValues, setFormValues] = useState({...initialDocumentData});
  const [documentContent, setDocumentContent] = useState("");
  const productContext = useContext(SimaSelectedProductContext);
  const tableActions = useTableActions(productContext.productcDocuments, 10);
  const uploadProductDocumentAction = useToastAction();
  const previewDocumentAction = useToastAction();
  const removeDocumentAction = useToastAction();
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const validationSchema = Yup.object().shape({
    documentId: Yup.string().required("Field is required")
  });
  const storeFile = (values, actions) => {
    const request = FormDataMapper.toFormData(values);

    if(values.archiveDocumentId === undefined)
    {
      uploadProductDocumentAction.execute(async () => {
        await uploadDocumentOnProduct(productContext.selectedProduct.id, request);
      }, 'Failed to upload product document', null, null, () => { resetFormAfterUpload(actions); });
    } else {
      uploadProductDocumentAction.execute(async () => {
        await replaceDocumentOnProduct(productContext.selectedProduct.id, request);
      }, 'Failed to upload product document', null, null, () => { resetFormAfterUpload(actions); });
    }
  }

  const resetFormAfterUpload = (actions) => {
    actions.setSubmitting(false); 
    actions.resetForm(); 
    productContext.reloadProductDocuments(); 
    setShowUploadModal(false);
    setSelectedDocuments([]);
  }

  const removeDocument = () => {
    if(selectedDocuments.length === 0){
      return;
    }
    removeDocumentAction.execute(async () => {
      await removeProductDocument(productContext.selectedProduct.id, selectedDocuments);
    }, 'Failed to remove product documents', "Document(s) sucessfully removed", null, () => { productContext.reloadProductDocuments(); setShowRemoveConfirmModal(false); setSelectedDocuments([]); });
  }

  const editDocument = async () => {
    const documentBlobId = selectedDocuments[0];
    const fileDetails = productContext.productcDocuments.find(x => x.archiveDocumentId === documentBlobId);
    if(fileDetails?.type !== "PDF")
    {
      var documentPreview = await previewProductDocument(documentBlobId);
      fileDetails.content = documentPreview;
    }
    setFormValues({...fileDetails});
    setShowUploadModal(true);
  }

  const downloadDocument = () => {
    if(selectedDocuments.length === 0){
      return;
    }
    const documentBlobId = selectedDocuments[0];
    const fileDetails = productContext.productcDocuments.find(x => x.archiveDocumentId == documentBlobId);
    const filename = fileDetails?.fileName;
    const type = fileDetails?.type;
    if(type === "PDF")
    {
      downloadProductDocument(documentBlobId, filename);
    } else {
      previewDocument(documentBlobId);
    }
  }

  const previewDocument = (documentBlobId) => {
    previewDocumentAction.execute(async () => {
      var documentPreview = await previewProductDocument(documentBlobId);
      setDocumentContent(documentPreview);
      setShowModal(true);
    }, 'Failed to get product document');
  }

  const toggleSelectedProduct = (documentId) => {
    const documentsIds = [...selectedDocuments];
    if(documentsIds.includes(documentId))
    {
      documentsIds.splice(documentsIds.indexOf(documentId), 1);
    } else {
      documentsIds.push(documentId);
    }
    setSelectedDocuments(documentsIds);
  }

  return (
    <div>
      <h1 className="text-md font-bold mt-3 mb-3">{translateProducts(translations.simaProducts.form.productDocuments)}: {productContext.selectedProduct?.name} </h1>
      <div className="mt-2 md:col-span-4">
        <div className="gap-x-3">
          <Button size={Button.sizes.sm} variant={Button.variants.icon} className="text-xs hover:bg-gray-50"
            onClick={() => { setFormValues({...initialDocumentData}); setShowUploadModal(true);}}>
            <UploadIcon className="h-4 w-4 mr-1 text-indigo-500" aria-hidden="true"/> Upload
          </Button>
          <Button disabled={selectedDocuments.length !== 1} size={Button.sizes.sm} variant={Button.variants.icon} className="text-xs hover:bg-gray-50"
            onClick={editDocument}>
            <TableIcon className="h-4 w-4 mr-1 text-indigo-500" aria-hidden="true"/> Edit
          </Button>
          <Button disabled={selectedDocuments.length !== 1} size={Button.sizes.sm} variant={Button.variants.icon} className="text-xs hover:bg-gray-50"
            onClick={downloadDocument}>
            <DownloadIcon className="h-4 w-4 mr-1 text-indigo-500" aria-hidden="true"/> Download / Preview
          </Button>
          <Button disabled={selectedDocuments.length === 0} size={Button.sizes.sm} variant={Button.variants.icon} className="text-xs hover:bg-gray-50"
            onClick={() => setShowRemoveConfirmModal(true)}>
            <TrashIcon className="h-4 w-4 mr-1 text-indigo-500" aria-hidden="true"/> Delete
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableHeader size='small'><DocumentIcon className="h-4 w-4" aria-hidden="true"/></TableHeader>
            <TableHeader size='small'>Document Id</TableHeader>
            <TableHeader size='small'>Type</TableHeader>
            <TableHeader size='small'>Language</TableHeader>
            <TableHeader size='small'>Category</TableHeader>
            {/* <TableHeader>Actions</TableHeader> */}
            {/* {translateProducts(translations.simaProducts.name)} */}
          </TableHead>
          <TableBody>
            {tableActions.pageData?.map((row, index) => (
              <Fragment key={index}>
                <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                  <TableColumn size='small'>
                    <Checkbox 
                      onChange={() => toggleSelectedProduct(row.archiveDocumentId)} 
                      checked={selectedDocuments.includes(row.archiveDocumentId)}
                    />
                  </TableColumn>
                  <TableColumn size='small'>{row.documentId}</TableColumn>
                  <TableColumn size='small'>{row.type}</TableColumn>
                  <TableColumn size='small'>{row.language}</TableColumn>
                  <TableColumn size='small'>{row.category}</TableColumn>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
          
        </Table>
        <TableCore.Pagination tableActions={tableActions} />
        <Modal
            isOpen={showModal}
            onClose={() => {setShowModal(false); setDocumentContent("")}}
            size={'medium'}
            title={`Document preview`}
          >
            <div className='space-y-4'>
              <div className='grid grid-cols-1 gap-x-2'>
                {documentContent}
              </div>
              <div className='flex justify-end'>
                <button onClick={() => {setShowModal(false); setDocumentContent("")}} className="px-4 py-2 border border-transparent font-medium rounded text-white bg-brand-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                  Close
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={showUploadModal}
            onClose={() => {setShowUploadModal(false);}}
            size={'large'}
            title={`Document upload`}
          >
            <div className='space-y-4'>
              <div className='grid grid-cols-1 gap-x-2'>
                <Formik initialValues={formValues} onSubmit={storeFile} enableReinitialize={true} validationSchema={validationSchema}>
                  {(props) => (
                  <Form className='space-y-4'>
                    <div className="grid grid-cols-4 gap-x-3">
                      <FormikSelectInput
                        label={translateProducts(translations.simaProducts.documentForm.category)}
                        name="category"
                        options={[
                          { name: "FDV", value:"FDV" },
                          { name: "Technical", value:"Technical" },
                          { name: "Installation", value:"Installation" },
                          { name: "Other", value:"Other" }
                        ]}
                        formikProps={props}
                      />
                      <FormikInput 
                        label={translateProducts(translations.simaProducts.documentForm.documentId)} 
                        name="documentId" 
                        formikProps={props} 
                      />
                      <FormikSelectInput
                        label={translateProducts(translations.simaProducts.documentForm.language)}
                        name="language"
                        options={[
                          { name: "EN", value:"English" },
                          { name: "NO", value:"Norwegian" },
                          { name: "PL", value:"Polish" }
                        ]}
                        formikProps={props}
                      />
                      <FormikSelectInput
                        label={translateProducts(translations.simaProducts.documentForm.type)}
                        name="type"
                        options={[
                          { name: "PDF", value:"PDF" },
                          { name: "Video Link", value:"Video Link" },
                          { name: "URL", value:"URL" }
                        ]}
                        formikProps={props}
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-x-3">
                      {props.values.type !== "PDF"
                      ?
                        <FormikInput 
                          label={translateProducts(translations.simaProducts.documentForm.documentContent)} 
                          name="content" 
                          formikProps={props} 
                        />
                        :
                        <FileInput
                          label={translateProducts(translations.simaProducts.documentForm.documentContent)} 
                          name="content"
                          accept=".pdf"
                          onChange={(file) => {props.setValues({...props.values, content: file || ''})}}
                        /> 
                      }
                    </div>
                    <div className="flex justify-end mt-3">
                      <FormikSubmitButton
                        text={translateCommon(translations.common.save)}
                        formikProps={props} 
                      />
                      <button 
                        type='button'
                        onClick={() => {setShowUploadModal(false);}} 
                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                        Close
                      </button>
                    </div>
                  </Form>
                )}
                </Formik>
              </div>
            </div>
          </Modal>
        <ConfirmationModal
          open={showRemoveConfirmModal}
          setOpen={() => setShowRemoveConfirmModal(false)}
          type="error"
          handleOnConfirm={removeDocument}
          description="Do you want to remove selected documents? Operation is permament and irreversible."
          confirmButtonText="Remove"
          title="Are you sure?"
        />
      </div>
    </div> 
  );
};

export default ProductDocuments;