import './styles.scss';
import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import toast from "react-hot-toast";
import { getMyUser, getLanguages, updateUserProfile } from '../../api/accountSettings';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import { useHistory } from 'react-router-dom';
import { getCustomerInfo } from '../../api/customer';

const AccountSettings = () => {
  const [languages, setLanguages] = useState([]);
  const [userProfile, setUserProfile] = useState({
    accountName: '', orgNumber: '', country: '',  firstName: '', lastName: '', userProfileImage: '', phoneNumber: '', email: '', languageCode: ''
  });
  const { t, i18n } = useTranslation([resources.accountSettings]);
  const history = useHistory();
  
  const loadData = async () => {
    const { firstName, lastName, userProfileImage, phoneNumber, email, languageCode } = await getMyUser();
    const langData = await getLanguages();
    const {name, orgNumber, country} = await getCustomerInfo();
    setLanguages(langData);
    setUserProfile({
        accountName: name, orgNumber, country, firstName, lastName, userProfileImage, phoneNumber, email, languageCode
    });
}
  
  useEffect(() => {
    loadData();
  }, []);

  const handleUserImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file.size > 550000) {
        toast.error(`${t('maximumFileSize')}`);
        return;
    }
    var reader = new FileReader();
    reader.onloadend = () => {
        const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
        const imageData = `data:${file.type};base64,${base64String}`
        setFieldValue(event.target.name, imageData);
    };
    reader.readAsDataURL(file);
  }
  
    const handleSubmitForm = async (values) => {
      try {
        await updateUserProfile(values);
        setUserProfile(values);
        toast.success(`${t('userProfileUpdated')}`);
    } catch (error) {
        toast.error(`${t('failedToUpdateUserProfile')}`);
    }
    }

    const handleChangeLanguage = (e, handleChange) => {
        i18n.changeLanguage(e.target.value);
        handleChange(e);
      }

      const handleCancel = () => {
        history.goBack();
      } 

    return (
    <main className='main-container bg-brand-gray-light focus:outline-none h-full'>
        <div className='max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8 bg-brand-gray-light'>
            <div className="flex flex-col pb-10 h-full">
                <div className='max-w-screen-3xl'>
                <div className="w-full h-full mb-8">
                        <h1 className="text-3xl font-extrabold text-blue-gray-900">{t('accountSettings')}</h1>
                        <Formik
                            initialValues={userProfile}
                            enableReinitialize={true}
                        >
                            {({
                                values
                            }) => (
                                <form className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                    <div className="sm:col-span-6">
                                            <h2 className="text-xl font-medium text-blue-gray-900">{t('companyInfo')}</h2>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="accountName" className="block text-sm font-medium text-blue-gray-900">
                                                {t('accountName')}
                                            </label>
                                            <input
                                                type="text"
                                                name="accountName"
                                                id="accountName"
                                                value={values.accountName ? values.accountName : ''}
                                                disabled={true}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="organizationalNumber" className="block text-sm font-medium text-blue-gray-900">
                                                {t('organizationalNumber')}
                                            </label>
                                            <input
                                                type="text"
                                                name="organizationalNumber"
                                                id="organizationalNumber"
                                                value={values.orgNumber ? values.orgNumber : ''}
                                                disabled={true}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium text-blue-gray-900">
                                                {t('country')}
                                            </label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                value={values.country ? values.country.name : ''}
                                                disabled={true}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                    </div>
                                </form>)}
                        </Formik>
                    </div>
                    <div className="w-full h-full">
                        <h1 className="text-3xl font-extrabold text-blue-gray-900">{t('account')}</h1>
                        <Formik
                            initialValues={userProfile}
                            enableReinitialize={true}
                            onSubmit={async (values, { setSubmitting }) => {
                                await handleSubmitForm(values);
                                setSubmitting(false);
                                handleChangeLanguage(values.languageCode);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                                validateForm,
                                setFieldValue
                            }) => (
                                <form className="mt-6 space-y-8 divide-y divide-y-blue-gray-200"
                                    onSubmit={e => {
                                        e.preventDefault();
                                        handleSubmit(e)
                                    }}
                                    onReset={resetForm} >
                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                        <div className="sm:col-span-6">
                                            <h2 className="text-xl font-medium text-blue-gray-900">{t('profile')}</h2>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                                                {t('firstName')}
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                autoComplete="given-name"
                                                value={values.firstName ? values.firstName : ''}
                                                onChange={handleChange}
                                                required
                                                disabled={isSubmitting}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="lastName" className="block text-sm font-medium text-blue-gray-900">
                                                {t('lastName')}
                                            </label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                autoComplete="family-name"
                                                value={values.lastName ? values.lastName : ''}
                                                onChange={handleChange}
                                                required
                                                disabled={isSubmitting}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="firstName" className="block text-sm font-medium text-blue-gray-900">
                                                {t('language')}
                                            </label>
                                            <select
                                                name="languageCode"
                                                id="languageCode"
                                                value={values?.languageCode}
                                                onChange={(e) => handleChangeLanguage(e, handleChange)}
                                                required
                                                disabled={isSubmitting}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-brand-green-dark rounded-md">
                                                {
                                                    languages?.map(l => (<option value={l.languageCode} key={'language-value-' + l.languageCode}>{l.name}</option>))
                                                }
                                            </select>
                                        </div>

                                        <div className="sm:col-span-6">
                                            <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                                                {t('photo')}
                                            </label>
                                            <div className="mt-1 flex items-center">
                                                {
                                                    !values.userProfileImage && (
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                        </svg>
                                                    )
                                                }
                                                {
                                                    values.userProfileImage && (
                                                        <img
                                                            className="inline-block h-12 w-12 rounded-full"
                                                            src={values.userProfileImage}
                                                            alt=""
                                                        />
                                                    )
                                                }
                                                <div className="ml-4 flex">
                                                    <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                        <label
                                                            htmlFor="user-photo"
                                                            className="cursor-pointer relative text-sm font-medium text-blue-gray-900"
                                                        >
                                                            <span className="cursor-pointer">{t('change')}</span>
                                                            <span className="sr-only"> {t('userPhoto')}</span>
                                                        </label>
                                                        <input
                                                            id="userProfileImage"
                                                            name="userProfileImage"
                                                            type="file"
                                                            onChange={e => {
                                                                handleUserImageChange(e, setFieldValue);
                                                            }}
                                                            disabled={isSubmitting}
                                                            className="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-brand-green-dark rounded-md"
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={e => {
                                                            setFieldValue("userProfileImage", null)
                                                        }}
                                                        disabled={isSubmitting}
                                                        className="disabled:opacity-50 ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                                                    >
                                                        {t('remove')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                        <div className="sm:col-span-6">
                                            <h2 className="text-xl font-medium text-blue-gray-900">{t('personalInformation')}</h2>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="email-address" className="block text-sm font-medium text-blue-gray-900">
                                                {t('emailAddress')}
                                            </label>
                                            <input
                                                type="text"
                                                name="email-address"
                                                id="email-address"
                                                autoComplete="email"
                                                readOnly
                                                value={values.email ? values.email : ''}
                                                disabled={true}
                                                className="disabled:opacity-50 cursor-not-allowed bg-gray-100 mt-1 shadow-sm opacity focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="phoneNumber" className="block text-sm font-medium text-blue-gray-900">
                                                {t('phoneNumber')}
                                            </label>
                                            <input
                                                type="text"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                autoComplete="tel"
                                                value={values.phoneNumber ? values.phoneNumber : ''}
                                                onChange={handleChange}
                                                required
                                                disabled={isSubmitting}
                                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                                            />
                                        </div>
                                    </div>

                                    <div className="pt-8 flex justify-end">
                                        <button
                                            type="reset"
                                            onClick={handleCancel}
                                            className="inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-600 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {t('cancel')}
                                        </button>
                                        <button
                                            type="submit"
                                            className="ml-3 inline-flex justify-center py-2 px-4 text-blue-gray-900 bg-white hover:bg-gray-100  border border-blue-gray-300 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            {
                                                isSubmitting && (
                                                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="grey" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                )
                                            }
                                            {t('save')}
                                        </button>
                                    </div>
                                </form>)}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    </main>
  )
};

export default AccountSettings;
