import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useHistory } from "react-router-dom";
import ActionButton from '../../components/ActionButton';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const NotExistingCompanyUser = () => {
  const { logout } = useReactOidc();
  const history = useHistory();
  const { t } = useTranslation([resources.common]);

  return (
    <>
      <PageHeader title={`You have logged in as a user without company.`} />
                        
      <p className="-mt-4 text-base leading-7 text-gray-600">
        {t('descriptionCompanyrelated')}
      </p>
      <div className='mt-6 inline-flex'>
        <ActionButton 
          title={t('registerCompany')}
          onClick={() => history.push("/company/add-new")}
          image="/images/register_company.png"
        />
        <ActionButton 
          title={t('exitThisApplication')} 
          onClick={logout}
          image="/images/exit_application.png"
        />
      </div>
    </>
  );
};

export default NotExistingCompanyUser;
