import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useClassNames } from '../../hooks/useClassNames';

export const Button = ({
  onClick,
  text,
  icon,
  disabled = false,
  isActive = false,
  useNewLine = false,
  confirmAction = false,
  confirmationText = 'Are you sure?',
  confirmationPosition = 'top',
  className = '',
  theme = 'primary', // primary, white, text
  fontSize = 'sm' // https://tailwindcss.com/docs/font-size
}) => {
  const Icon = icon ? icon : null;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const classNames = useClassNames();

  const themeClasses = () => {
    switch (theme) {
      case 'text':
        return 'text-black underline hover:text-gray-700 font-semibold'; // font-semibold = 600 which is what links appear to be
      case 'white':
        return classNames.classNames(
          icon && !text ? 'h-10 px-2 py-1' : 'px-4 py-2',
          'inline-flex items-center border border-brand-green-dark shadow-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400'
        );
      case 'primary':
      default:
        return classNames.classNames(
          icon && !text ? 'h-10 px-2 py-1' : 'px-4 py-2',
          'inline-flex items-center border border-transparent font-medium rounded text-white bg-brand-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400'
        );
    }
  };

  const isActiveClasses = () => {
    if (!isActive) {
      return null;
    }

    switch (theme) {
      case 'white':
        return 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200';
      case 'primary':
      default:
        return '';
    }
  };

  const handleOnClick = (evt) => {
    if (confirmAction) {
      setIsPopoverOpen(true);
    } else {
      onClick(evt);
    }
  };

  const onYesClick = () => {
    setIsPopoverOpen(false);
    onClick();
  };

  const onNoClick = () => {
    setIsPopoverOpen(false);
  };

  const renderButton = () => {
    return (
      <button
        onClick={handleOnClick}
        disabled={disabled}
        className={`${themeClasses()} ${isActiveClasses()} ${fontSizeClass} ${className} ${
          useNewLine ? 'block' : ''
        }`}
      >
        {icon && text && <Icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />}
        {icon && !text && <Icon className="h-5 w-5" aria-hidden="true" />}
        {text}
      </button>
    );
  };

  const fontSizeClass = `text-${fontSize}`;

  return (
    <>
      {confirmAction && (
        <Popover
          isOpen={isPopoverOpen}
          position={[confirmationPosition]}
          reposition={true}
          padding={10}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={() => {
            return (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div>
                    <h3 className="text-sm font-medium text-red-800">{confirmationText}</h3>
                    <div className="mt-4 flex justify-center">
                      <div className="-mx-2 -my-1.5 flex">
                        <button
                          type="button"
                          className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100"
                          onClick={onYesClick}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="ml-3 bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none "
                          onClick={onNoClick}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        >
          {renderButton()}
        </Popover>
      )}
      {!confirmAction && <>{renderButton()}</>}
    </>
  );
};
