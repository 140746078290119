import { get, BaseUrl, put, post, download, destroy } from './api';

const controllerName = 'ProductModules';

export const getProducts = async (searchValue, cancelToken) => {
    return await post(`${controllerName}`, {searchValue}, {}, BaseUrl.Api, cancelToken);
}

export const createProductModule = async (values) => {
    return await post(`${controllerName}/create`, values);
}

export const getProductData = async (productId, cancelToken) => {
    return await get(`${controllerName}/${productId}?loadExternal=true`, BaseUrl.Api, {}, cancelToken);
}

export const getSelectDictionaries = async () => {
    return await get(`${controllerName}/dictionaries`);
}

export const getProductDocuments = async (productId, cancelToken) => {
    return await get(`${controllerName}/documents/${productId}`, BaseUrl.Api, {}, cancelToken);
}

export const downloadProductDocument = async (documentBlobId, filename) => {
    return await download(`${controllerName}/documents/download/${documentBlobId}`, filename);
}

export const previewProductDocument = async (documentBlobId) => {
    return await get(`${controllerName}/documents/preview/${documentBlobId}`);
}

export const removeProductDocument = async (productId, documentIds) => {
    const request = { ids: documentIds };
    return await post(`${controllerName}/documents/${productId}/remove`, request);
}

export const updateProductModuleDetails = async (values) => {
    return await put(`${controllerName}/${values.id}`, values);
}

export const uploadDocumentOnProduct = async(productId, documentFormData) => {
    return await post(`${controllerName}/documents/${productId}`, documentFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
    });
}

export const replaceDocumentOnProduct = async(productId, documentFormData) => {
    return await post(`${controllerName}/documents/${productId}/replace`, documentFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
    });
}

export const getProductConfigurationModules = async (searchValue, cancelToken) => {
    return await post(`Products/configurationmodules`, {searchValue}, {}, BaseUrl.Api, cancelToken);
}

export const getProductConfigurationModule = async (productConfigurationModuleId) => {
    return await get(`Products/configurationmodules/${productConfigurationModuleId}`);
}

export const updateProductConfigurationModule = async (productConfigurationModuleId, payload) => {
    return await put(`Products/configurationmodules/${productConfigurationModuleId}`, payload);
}

export const removeConfigModulePropertyOption = async (productConfigurationModuleId, productRefId) => {
    return await destroy(`Products/configurationmodules/${productConfigurationModuleId}/${productRefId}`);
}

export const getProductsByIds = async (ids) => {
    return await post(`${controllerName}/GetByIds`, {ids});
}