import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SceneLayout from '../../layouts/SceneLayout';
import CompanyRegistration from './CompanyRegistration';
import Summary from './Summary';

const steps = {
    CompanyRegistration: "CompanyRegistration",
    Summary: "Summary"
}

const CreateNewCustomer = () => {
    const [currentStep, setCurrentStep] = useState(steps.CompanyRegistration);

    const getCurrentStep = () => {
        switch(currentStep){
          case steps.Summary:
            return <Summary />;
          case steps.CompanyRegistration:
        default:
            return <CompanyRegistration onCompleted={() => setCurrentStep(steps.Summary)} />;
        }
      }

    return (
        <SceneLayout>
            {getCurrentStep()}
        </SceneLayout>
    )
};

export default CreateNewCustomer;
