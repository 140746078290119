import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { downloadEventLog } from '../../../api/audit';
import { Button } from '../../../components'
import { useToastAction } from '../../../hooks/useToastAction';
import { resources, translations } from '../../../translations/translationsConstants';
import { logLevels, logSources } from '../../../utils/constants'
import { valueIsDate } from '../../../utils/date';
import DateTimeParser from '../../../utils/DateTimeParser';

const EventLogField = ({ label, value }) => {
    return (
        <>
            <div className='col-span-3 mt-2'>
                <label className="block text-sm font-medium text-gray-700 mt-2 ">
                    {label}
                </label>
            </div>
            <div className="sm:col-span-9 mt-2">
                <input
                    type={'text'}
                    className={`shadow-sm block w-full sm:text-sm border-brand-green-dark rounded-md focus:ring-gray-400 focus:border-gray-400 bg-gray-100`}
                    disabled={true}
                    value={value}
                />
            </div>
        </>
    );
}

export default function EventLog({ eventLog, onClearSelected }) {
    const { t: translationAudit } = useTranslation([resources.audit])
    const { t: translationCommon } = useTranslation([resources.common])
    const [eventLogDescription, setEventLogDescription] = useState(null);
    const loadAction = useToastAction();
    const { classNames } = useClassNames();

    const load = () => {
        loadAction.execute(async () => {
            const eventLogData = await downloadEventLog(eventLog.id);
            setEventLogDescription(eventLogData);
        }, 'Failed to load eventlog desc')
    }

    useEffect(() => {
        if (eventLog) {
            load();
        }
    }, [eventLog])

    return (
        <div className="w-full h-full" >
            <h1 className="text-3xl font-bold mb-6">{translationAudit(translations.audit.details.title)}</h1>
            {/* <h3 className="mt-4 text-gray-600">{'Search and filter event logs'}</h3> */}
            <div className="md:grid grid-cols-2 md:space-x-4 mb-4">
                <div className='max-w-xl'>
                    <div className='sm:grid grid-cols-12'>

                        {/* Source */}
                        <EventLogField label={translationAudit(translations.audit.details.source)} value={logSources.find(x => x.value === eventLog?.source)?.name || eventLog?.source} />

                        {/* Event Name */}
                        <EventLogField label={translationAudit(translations.audit.details.eventName)} value={eventLog?.logName} />

                        {/* Event ID */}
                        <EventLogField label={translationAudit(translations.audit.details.eventId)} value={eventLog?.eventId} />

                        {/* Level */}
                        <EventLogField label={translationAudit(translations.audit.details.level)} value={logLevels.find(x => x.value === eventLog?.level)?.name || eventLog?.level} />

                        {/* User */}
                        <EventLogField label={translationAudit(translations.audit.details.user)} value={eventLog?.user} />

                        {/* Logged*/}
                        <EventLogField label={translationAudit(translations.audit.details.logged)} value={DateTimeParser.toLocaleDateTimeString(eventLog?.createdDate)} />

                        {/* OpCode*/}
                        <EventLogField label={translationAudit(translations.audit.details.opCode)} value={eventLog?.opCode} />

                        {/* Category */}
                        <EventLogField label={translationAudit(translations.audit.details.category)} value={eventLog?.category} />

                        {/* Keyword */}
                        <EventLogField label={translationAudit(translations.audit.details.keyword)} value={eventLog?.keywords} />

                        {/* Computer */}
                        <EventLogField label={translationAudit(translations.audit.details.computer)} value={eventLog?.computer} />

                        {/* GDPR */}
                        <EventLogField label={translationAudit(translations.audit.details.gdpr)} value={eventLog?.gdpr ? 'True' : 'False'} />

                    </div>
                </div>
                <div className='max-w-xl p-4 border rounded-md mt-2 shadow-sm border-brand-green-dark'>
                    {
                        eventLogDescription && (
                            <div className='ml-1'>
                                <SimpleJsonViewer data={eventLogDescription} />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='flex justify-end'>
                <Button variant={Button.variants.primary}
                    onClick={() => onClearSelected()}>
                    {translationCommon(translations.common.back)}
                </Button>
            </div>
        </div >
    )
}

const SimpleJsonViewer = ({ data, level = 0 }) => {
    return (
        <ul className='list-disc ml-4'>
            {
                Object.keys(data).map(k => {
                    const value = data[k];
                    if(value === null){
                        return <li key={k}><span className='font-semibold'>{k}: </span><span/></li>;
                    }

                    const type = typeof (value)
                    const isDate = type === 'string' && valueIsDate(value);
                    return (<li key={k}>
                        <span className='font-semibold'>{k}: </span>
                        {
                            type === 'object' && (
                                <SimpleJsonViewer data={value} level={level + 1} />
                            )
                        }
                        {
                            type !== 'object' && !isDate && (<span>{value}</span>)
                        }
                        {
                            type !== 'object' && isDate && (<span>{DateTimeParser.toLocaleDateTimeString(value)}</span>)
                        }
                    </li>)
                })
            }
        </ul>
    )
}
