import { translations } from '../../../translations/translationsConstants';

export const ProductState = {
    Active: 1,
    Disabled: 2,
    Draft: 3,
}

export const ProductStates = {
    [ProductState.Active]: translations.simaProducts.form.state.active,
    [ProductState.Disabled]: translations.simaProducts.form.state.disabled,
    [ProductState.Draft]: translations.simaProducts.form.state.draft
}