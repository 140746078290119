import React, { useEffect, useState } from 'react';
import ProductCard from '../../../components/ProductCard';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';
import { getShopItems } from '../../../api/rackbeat';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {useNavigation} from "../../../hooks/useNavigation";
import InputText from '../../../components/InputText';
import DropdownComponent from '../../../components/DropdownComponent';
import { productGroups } from '../../../utils/constants';
import { fi } from 'date-fns/locale';

const ProductShop = () => {
  const { t } = useTranslation([resources.glasswall]);
  const { productType } = useParams();
  const { goToProductDetail } = useNavigation();

  const [products, setProducts] = useState([]);
  const [productsToShow, setProductsToShow] = useState(null);
  const [search, setSearch] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const productTypes = [
    {display: t('carpet'), value: 'floor'},
    {display: t('lighting'), value: 'light'},
    // {display: 'Himlinger', value: 'ceiling'},
    {display: t('walls'), value: 'wall'},
    // {display: 'Akustikk', value: 'acoustics'},
    // {display: 'Dører', value: 'doors'},
    {display: t('inventory'), value: 'inventory'},
    {display: t('furniture'), value: 'furniture'},
  ]

  const [selectedProductType, setSelectedProductType] = useState();
  const [selectedProductGroup, setSelectedProductGroup] = useState();

  useEffect(() => {
    if (productType) {
      const productTypeObj = productTypes.find((type) => type.value === productType);      
      setSelectedProductType(productTypeObj);
    }  
  }, []);

  useEffect(() => {
    loadProducts();    

    setSelectedProductGroup({ display: 'All', value: 'all' });

  }, [selectedProductType]);

  useEffect(() => {
    handleSearch();
  }, [search, products, selectedProductGroup]);

  const loadProducts = async () => {
    setIsLoaded(false);
    
    if (selectedProductType) {
      const result = await getShopItems(selectedProductType?.value);
      setProducts(result);
      setIsLoaded(true);
    }
  }

  const productGroupOptions = () => {
    let options = productGroupFromType(selectedProductType?.value)?.map(group => {
      return { display: group.name, value: group.number }
    })
    options.sort((a, b) => a.value - b.value);
    options.unshift({ display: 'All', value: 'all' })
    // options.unshift({ display: 'Featured', value: 'featured' })
    return options;
  }

  const productGroupFromType = (type) => {
    return productGroups.filter(m => m.parent === type) || [];
  }

  const handleSearch = () => {
    console.log('products', products)
    let filteredProducts = products;

    if (search) {
      filteredProducts = products.filter((product) => {
        return  product.name.toLowerCase().includes(search?.toLowerCase());
      });     
    }

    if(selectedProductGroup && selectedProductGroup.value !== 'all') {
      filteredProducts = filteredProducts.filter(product => product.group?.number === selectedProductGroup?.value);
    }

    filteredProducts?.sort((a, b) => a.priority - b.priority);

    setProductsToShow(filteredProducts);
  }

  return (
    <>
    <div className={'w-full bg-brand-brown-medium px-6 border-brand-green-dark border-t border-b'}>
        <div className='bg-brand-brown-medium h-48 max-w-screen-3xl mx-auto flex flex-col justify-center'>
            <p className={'font-semibold text-lg'}>PRODUCTS</p>
            <div className='flex gap-4'>
              <InputText label="Search" placeholder="Search" className={'w-96'} value={search} setValue={setSearch} />
              <DropdownComponent className='w-72' label="Type" selectedOption={selectedProductType} setSelectedOption={setSelectedProductType} options={productTypes}/>
              <DropdownComponent className='w-72' label="Group" selectedOption={selectedProductGroup} setSelectedOption={setSelectedProductGroup} options={productGroupOptions()}/>
            </div>
        </div>
      </div>
      <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >            
        <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-3xl">
              <div className="w-full h-full">
                <div className="max-w-8xl mx-auto">
                  <div className="mt-5 flex flex-wrap flex-cols gap-5">

                    {
                      !isLoaded && (
                        // for loop 5 times to show 5 skeleton loaders
                        [...Array(5)].map((_, i) => (
                          <div className="animate-pulse h-96">
                            {/* Here you can customize your skeleton loader */}
                            <div className="skeleton-loader-item bg-gray-300 h-96 w-72"></div>
                          </div> 
                        )) 
                      )
                    }

                    {
                      isLoaded && productsToShow?.map((product) => (
                      <>
                        <ProductCard product={product} key={product.number} title={product.name} />
                      </>
                     ))
                    }
                  </div>
                </div>
              </div >
            </div>
          </div>
        </div>
      </main>
    </>
  )
};

export default ProductShop;
