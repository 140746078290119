import { get, BaseUrl, put, post } from './api';

const controllerName = 'teams';

export const getTeamUsers = async () => {
    return await get(controllerName + `/users`);
}
export const getTeamPendingInvites = async () => {
    return await get(controllerName + `/PendingInvites`);
}
export const getTeamPendingAndAcceptedInvites = async (daysBack) => {
    return await get(controllerName + `/PendingAndAcceptedInvites/${daysBack}`);
}
export const getUserRoles = async () => {
    return await get(controllerName + `/userRoles`);
}
export const getUserGroups = async () => {
    return await get(controllerName + `/userGroups`);
}
export const getUserStatuses = async () => {
    return await get(controllerName + `/userStatuses`);
}
export const getTeamUser = async (userId) => {
    return await get(controllerName + `/users/${userId}`);
}
export const updateTeamUser = async (userId, data) => {
    return await put(controllerName + `/users/${userId}`, data);
}
export const sendUserInvite = async (request) => {
    return await post(controllerName + `/InviteUser`, request);
}
export const removePendingInvites = async (request) => {
    return await post(controllerName + `/PendingInvites/RemoveMany`, request);
}


