import classNames from 'classnames';

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
};

const SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg'
};

const Button = ({
  children,
  className,
  variant,
  size,
  id,
  disabled,
  isUppercase = true,
  isSubmitting,
  onClick,
  type = 'button',
  width = '',
  customStyles
}) => {

  const styles = {
    // buttonWidth: '139px',
    // buttonHeight: '43px'
  }

  return (
    <>
      <button
        id={id}
        type={type}
        style={{width: width ? width : styles.buttonWidth, height: styles.buttonHeight, ...customStyles}}
        className={classNames(
          'inline-flex justify-center shadow-sm px-4 py-2 font-medium items-center cursor-pointer',
          className,
          variant === VARIANTS.primary &&
          'border border-brand-green-dark shadow-sm px-4 py-2 bg-brand-black text-white sm:mt-0 sm:text-sm',
          variant === VARIANTS.secondary &&
          'border border-brand-green-dark shadow-sm px-4 py-2 bg-white text-brand-black sm:mt-0 sm:text-sm',
          !size && 'text-base',
          size ? size : 'md',
          width ? '' : 'px-8'
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

Button.variants = VARIANTS;
Button.sizes = SIZES;

export default Button;
