import { Fragment, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import {
  AuthenticationProvider,
  oidcLog,
  OidcSecure,
  useReactOidc,
  WebStorageStateStore
} from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { config, oidcConfig } from './utils/config';
import BaseLayout from './layouts/BaseLayout';
import ErrorLayout from './layouts/ErrorLayout';
import Oidc from './screens/Oidc';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader } from './api/api';
import MissingLicense from './screens/Errors/MissingLicense';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo, getCustomerDetails } from './api/customer';
import { useToastAction } from './hooks/useToastAction';
import { Toaster } from './toaster';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import Dashboard from './screens/Dashboard';
import AuditLogList from './screens/AuditLogList';
import AccountSettings from './screens/AccountSettings';
import ManageTeamUser from './screens/ManageTeamUser';
import CreateNewCustomer from './screens/CreateNewCustomer';
import NoCompany from './screens/Errors/NoCompany';
import MarketplaceProductsModules from './screens/SimaProductsConfiguration/SimaProducts';
import ProductsConfigurationModuleConfiguration from './screens/SimaProductsConfiguration/SimaConfiguration';
import SimaCustomers from './screens/SimaAdmin/SimaCustomers';
import SimaProductAreas from './screens/SimaAdmin/SimaProductAreas';
import SimaSettings from './screens/SimaAdmin/SimaSettings';
import ManageTeam from './screens/Admin/ManageTeam';
import Invite from './screens/Admin/Invite';
import ConfigurationProduct from './screens/SimaProductsConfiguration/ConfigurationProduct';
import Products from './screens/Products';
import ProductShop from './screens/Products/Shop';
import LotShop from './screens/Products/Lot/Shop';
import Invoices from './screens/Invoices';
import Orders from './screens/Orders';
import OrderItem from './screens/OrderItem';
import {ProductDetail} from "./screens/ProductDetail";

function App() {

  return (
    <div className="app h-screen">
      <Router>
        <AuthenticationProvider
          configuration={oidcConfig}
          loggerLevel={oidcLog.ERROR}
          isEnabled={true}
          UserStore={WebStorageStateStore}
          callbackComponentOverride={Oidc}
          notAuthenticated={() => {
            return <h1>Not Authenticated.</h1>;
          }}
          notAuthorized={() => {
            return <h1>Not Authorized.</h1>;
          }}
          authenticating={Oidc}
        >
          <Switch>
            <Route exact={true} path="/unauthorized" element={<ErrorLayout><Unauthorized /></ErrorLayout>} />
            <Route exact={true} path="/not-found" element={<ErrorLayout><NotFound /></ErrorLayout>} />
            <Route exact={true} path="/missing-license" component={MissingLicense}/>

            <OidcSecure>
              <SetAuthToken>
                <SetOidcRoutesContext>
                  <I18nextProvider i18n={i18next}>
                    <BaseLayoutRoute exact={true} path="/dashboard" nav="dashboard" component={Dashboard}   />
                    <BaseLayoutRoute exact={true} path="/products" nav="products" component={Products}   />
                    <BaseLayoutRoute exact={true} path="/invoices" nav="invoices" component={Invoices}   />
                    <BaseLayoutRoute exact={true} path="/lots/shop" nav="content" component={LotShop}   />
                    
                    <BaseLayoutRoute exact={true} path="/products/shop/:productType" nav="content" component={ProductShop}   />
                    <BaseLayoutRoute exact={true} path="/products/:productId" nav="content" component={ProductDetail}   />

                    <BaseLayoutRoute exact={true} path="/" nav="dashboard" component={RedirectRootRoute}   />
                    
                    <BaseLayoutRoute exact={true} path="/auditlog" nav="auditlog" component={AuditLogList}   />
                    
                    <BaseLayoutRoute exact={true} path="/orders" nav="orders" component={Orders}   />
                    <BaseLayoutRoute exact={true} path="/orders/:orderNumber/:type" nav="orders" component={OrderItem}   />
                    
                    <BaseLayoutRoute exact={true} path="/admin" nav="admin/manageteam" component={ManageTeam}   />
                    <BaseLayoutRoute exact={true} path="/admin/manageteam" nav="admin/manageteam" component={ManageTeam}   />
                    <BaseLayoutRoute exact={true} path="/admin/invitations" nav="admin/invitations" component={Invite}   />
                    <BaseLayoutRoute exact={true} path="/admin/team/manage/:userId" nav="admin/manageteam" component={ManageTeamUser}   />                        
                    
                    <BaseLayoutRoute exact={true} path="/accountsettings" nav="accountSettings" component={AccountSettings}   />
                    
                    <BaseLayoutRoute exact={true} path="/internal/management/customers" nav="internal/management/customers" component={SimaCustomers}   />
                    <BaseLayoutRoute exact={true} path="/internal/management/productArea" nav="internal/management/productArea" component={SimaProductAreas}   />
                    <BaseLayoutRoute exact={true} path="/internal/management/settings" nav="internal/management/settings" component={SimaSettings}   />

                    <BaseLayoutRoute exact={true} path="/internal/products-configuration/products" nav="internal/products-configuration/products" component={MarketplaceProductsModules}   />
                    <BaseLayoutRoute exact={true} path="/internal/products-configuration/configuration" nav="internal/products-configuration/configuration" component={ProductsConfigurationModuleConfiguration}   />
                    <BaseLayoutRoute exact={true} path="/internal/products-configuration/productconfiguration" nav="internal/products-configuration/productconfiguration" component={ConfigurationProduct}   />
                    <BaseLayoutRoute exact={true} path="/company/add-new" nav="company" component={CreateNewCustomer}   byPassSimaCompany={true} />
                  </I18nextProvider>
                </SetOidcRoutesContext>
              </SetAuthToken>
            </OidcSecure>
          </Switch>
        </AuthenticationProvider>
      </Router>
      <Toaster />
    </div>
  );
}

function RedirectRootRoute() {
  const  { isSimaAdminUser } = useContext(OidcRoutesContext);

  if(isSimaAdminUser)
    return <Redirect to="/internal/products-configuration/products" />

  return <Redirect to="/dashboard" />
}

function BaseLayoutRoute({
  component: Component,

  nav,
  path,
  subMenuNav,
  requiredFeatureCode,
  byPassSimaCompany,
  ...rest
}) {
  const oicdContext = useContext(OidcRoutesContext);
  return (
    <>
    {      
      !byPassSimaCompany && oicdContext && !oicdContext.hasAccess ? 
        <Route
          path={path}
          {...rest}
          render={(props) => (
            <NoCompany /> 
          )}
        />
      :
      <ProtectedRoute
      >
        <Route
          path={path}
          {...rest}
          render={(props) => (
            <BaseLayout nav={nav} subMenuNav={subMenuNav} {...props}>
              <Component {...props} />
            </BaseLayout>
          )}
        />
      </ProtectedRoute>
    }
    </>
  );
}

const ProtectedRoute = ({ children }) => {
  return <>{children}</>;
};

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [simaCustomerInfo, setSimaCustomerInfo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfileData = await getUserProfile();
      if(userProfileData.customerIds.length > 0 )
      {
        const customer = await getCustomerInfo();
        const simaCustomer = await getCustomerDetails();
        setCustomerInfo(customer);
        setSimaCustomerInfo(simaCustomer);
      }
      i18next.changeLanguage(userProfileData.languageCode);
      setUserProfile(userProfileData);
      setIsLoaded(true)
    }, 'Failed to load user data');
  };

  const hasAccess = (simaCustomerInfo, userProfile) => {
    return userProfile.isSimaAdmin || simaCustomerInfo?.simaStatus === 2;
  }

  useEffect(() => {
    loadUserContextData();
  }, []);

  return (
    isLoaded && userProfile ? (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo,
          simaCustomerInfo,
          isSimaAdminUser: userProfile.isSimaAdmin,
          hasAccess: hasAccess(simaCustomerInfo, userProfile),
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    ):(<></>)
  );
};

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  return <>{children}</>;
};

export default App;
