import { get, post } from './api';

const controllerName = 'api/Audit';

export const searchAuditLogs = async (searchFilter) => {
    return await post(controllerName, searchFilter);
}

export const downloadEventLog = async (documentModelId) => {
    return await get(controllerName + "/" + documentModelId);
}