export const config = {
  apiBaseUrl: window._env_.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL, 
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL || process.env.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL || process.env.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL || process.env.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '611b8ac465064876fb35e1cf'
};

export const oidcConfig = {
  client_id: 'sima_web',
  redirect_uri: `${window._env_.REACT_APP_URL}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: `${window._env_.REACT_APP_URL}/`, 
  scope: 'openid profile email api.internal offline_access',
  authority: window._env_.REACT_APP_IDENTITY_BASE_URL,
  silent_redirect_uri: `${window._env_.REACT_APP_URL}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  extraQueryParams: {
    loginBrand: "sima"
  }
};


