import React, { useState } from "react";
import { Button, Modal } from "../../../../components";
import { Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../../components/Table";
import { EyeIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { Form, Formik } from "formik";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { ProductModuleType } from "../../ProductModuleTypes";
import { resources, translations } from "../../../../translations/translationsConstants";
import { useTranslation } from "react-i18next";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import FormikInput from "../../../../components/Form/Formik/FormikInput";
import { doorDirectionEnumNames, doorDirectionEnums, doorDirectionNames, doorDirections } from "../../../../utils/constants";

const Doors = ({ doors, avaialableProducts, onUpdateDoors, isSolidDoors }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [activeDoor, setActiveDoor] = useState();


    const handleConfirmDelete = () => {
        const idx = doors.findIndex(x => x.id == activeDoor?.id);
        if (idx === -1) return;
        doors.splice(idx, 1);
        onUpdateDoors(doors, () => {
            setShowConfirmModal(false)
            setActiveDoor(null);
        });
    }

    const handleUpdateDoor = (values, { setSubmitting }) => {
        const idx = doors.findIndex(x => x.id == values.id);
        if (idx === -1) return;
        doors[idx] = values;
        onUpdateDoors(doors, () => {
            setShowUpdateModal(false);
            setSubmitting(false);
        });
    }

    const handleAddNewDoor = (values, { setSubmitting }) => {
        doors.push(values);
        onUpdateDoors(doors, () => {
            setShowUpdateModal(false);
            setSubmitting(false);
        })
    }

    return (
        <>
            <div className="flex justify-end justify-between items-center">
                <h1 className="text-sm font-bold">Configured Doors</h1>
                <Button variant={Button.variants.secondary} onClick={() => {
                    setShowFormModal(true);
                }}>Add new</Button>
            </div>
            <Table>
                <TableHead>
                    <TableHeader>Door Name</TableHeader>
                    <TableHeader>Color</TableHeader>
                    <TableHeader>Door Direction</TableHeader>
                    {/* <TableHeader>Product Id</TableHeader> */}
                    <TableHeader textRight={true}>Actions</TableHeader>
                </TableHead>
                <TableBody>
                    {doors.map((door, index) => {
                        // var product = products.find(x => x.id === row.productModuleRef);
                        return (
                            <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                <TableColumn size="small">
                                    <span className="ml-2">{door?.name}</span>
                                </TableColumn>
                                <TableColumn size="small">
                                    <span className="ml-2">{door?.color}</span>
                                </TableColumn>
                                <TableColumn size="small">
                                    <span className="ml-2">{doorDirectionEnumNames[door?.doorDirection]}</span>
                                </TableColumn>
                                {/* <TableColumn size="small">
                                    {product?.productId}
                                </TableColumn> */}
                                <TableColumn size="small" textRight={true}>
                                    <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                        <PencilAltIcon aria-hidden="true" onClick={() => {
                                            setActiveDoor(door);
                                            setShowUpdateModal(true);
                                        }} />
                                    </button>
                                    <button className="hover:text-red-600 h-6 w-6" type="button">
                                        <TrashIcon aria-hidden="true" onClick={() => {
                                            setShowConfirmModal(true);
                                            setActiveDoor(door);
                                        }} />
                                    </button>
                                </TableColumn>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <ConfirmationModal
                open={showConfirmModal}
                setOpen={() => setShowConfirmModal(false)}
                type="error"
                handleOnConfirm={handleConfirmDelete}
                description="Do you want to remove selected door? This operation is irreversible."
                confirmButtonText="Remove"
                title="Are you sure?"
            />

            <Modal
                isOpen={showUpdateModal}
                onClose={() => { setShowUpdateModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <DoorsForm initialValues={activeDoor} onClose={() => setShowUpdateModal(false)} onSubmit={handleUpdateDoor} avaialableProducts={avaialableProducts} isSolidDoors={isSolidDoors} />
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showFormModal}
                onClose={() => { setShowFormModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <DoorsForm initialValues={{
                            name: '',
                            doorDirection: doorDirectionEnums.right,
                            topGlassModuleRef: null,
                            frameModuleRef: null,
                            lockboxModuleRef: null,
                            hingeModuleRef: null,
                            thresholdModuleRef: null,
                            doorBladeModuleRef: null,
                            cornerSetModuleRef: null,
                            jambModuleRef: null,
                            hingeCoverModuleRef: null,
                        }} onClose={() => setShowFormModal(false)} onSubmit={handleAddNewDoor} avaialableProducts={avaialableProducts} isSolidDoors={isSolidDoors} />
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default Doors;

const DoorsForm = ({ initialValues, onSubmit, onClose, isSolidDoors, avaialableProducts = [] }) => {

    const { t: translateCommon } = useTranslation([resources.common]);

    const doorProducts = avaialableProducts.filter(x => x.type === ProductModuleType.DoorElement || x.type === ProductModuleType.DoorProducts).map(x => {
        return { name: x.name, value: x.id }
    });

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {(props) => (
            <Form className='space-y-4'>
                <div className="grid grid-cols-4 mb-4 gap-4 pb-6 border-b ">
                    <div className="col-span-2">
                        <FormikInput
                            label={'Name'}
                            name="name"
                            formikProps={props}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikInput
                            label={'Width'}
                            name="width"
                            type={'number'}
                            formikProps={props}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Dør retning"}
                            name={`doorDirection`}
                            formikProps={props}
                            valueIsNumber={true}
                            options={[
                                ...Object.keys(doorDirectionEnumNames).map(k => ({ value: k, name: doorDirectionEnumNames[k] }))
                            ]}
                            showEmptyOption={!props.values.doorDirection}
                        />
                    </div>
                    <div className="col-span-2">
                        <div className="col-span-2">
                            <FormikSelectInput
                                label={"Ramme Farge"}
                                name={`color`}
                                formikProps={props}
                                options={[
                                    ...[{ value: 'black', name: 'Black' }, { value: 'aluminum', name: 'Aluminium' }]
                                ]}
                                showEmptyOption={!props.values.color}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4 mb-4 gap-4">
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Top glass"}
                            name={`topGlassModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.topGlassModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Ramme Profil"}
                            name={`frameModuleRef`}
                            formikProps={props}
                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.frameModuleRef === ''}
                        />
                    </div>

                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Hengsler"}
                            name={`hingeModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.hingeModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Decklokk"}
                            name={`hingeCoverModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.hingeCoverModuleRef === ''}
                        />
                    </div>
                    {
                        !isSolidDoors && (
                            <div className="col-span-2">
                                <FormikSelectInput
                                    label={"Låsekasse"}
                                    name={`lockboxModuleRef`}
                                    formikProps={props}

                                    options={[{ name: '', value: null },
                                    ...doorProducts
                                    ]}
                                    showEmptyOption={props.values.lockboxModuleRef === ''}
                                />
                            </div>
                        )
                    }
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Sluttstykke"}
                            name={`jambModuleRef`}
                            formikProps={props}
                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.jambModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Limeterskel"}
                            name={`thresholdModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.thresholdModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Hev/senk terskel"}
                            name={`dropdownThresholdModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.thresholdModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Dørblad"}
                            name={`doorBladeModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}

                            showEmptyOption={props.values.doorBladeModuleRef === ''}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Hjørnesett"}
                            name={`cornerSetModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.cornerSetModuleRef === ''}
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-3 space-x-2">
                    <Button variant={Button.variants.secondary} onClick={() => onClose()}>Close</Button>
                    <FormikSubmitButton
                        text={translateCommon(translations.common.save)}
                        formikProps={props}
                    />
                </div>
            </Form>
        )}
    </Formik >
}