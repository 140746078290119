import { useContext } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { OidcRoutesContext } from '../../contexts';
import ActionButton from '../../components/ActionButton';
import PageHeader from '../../components/PageHeader';

const NonSimaCompanyNormalUser = () => {
  const oicdContext = useContext(OidcRoutesContext);
  const { logout } = useReactOidc();

  return (
    <>
      <PageHeader title={`You have loggen in as customer: ${oicdContext.customerInfo?.name}`} />
                        
      <p className="-mt-4 text-base leading-7 text-gray-600">
        Your company is not registered with an account in this Marketplace.
        <br/>
        Please contact with your administrator and ask to activate your company account.
      </p>
      <div className='mt-6 inline-flex'>
        <ActionButton 
          title="Exit this application" 
          onClick={logout}
          image="/images/exit_application.png"
        />
      </div>
    </>
  );
};

export default NonSimaCompanyNormalUser;
