
import React, { useState } from "react";
import { Button, Modal } from "../../../../components";
import { Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../../components/Table";
import { EyeIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { Form, Formik } from "formik";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { ProductModuleType } from "../../ProductModuleTypes";
import { resources, translations } from "../../../../translations/translationsConstants";
import { useTranslation } from "react-i18next";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import FormikInput from "../../../../components/Form/Formik/FormikInput";
import Doors from "./doors";

export default function DoorFrames({ doorFrames, avaialableProducts, onUpdate, isSolidDoors }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [activeFrame, setActiveFrame] = useState()


    const handleConfirmDelete = () => {
        const idx = doorFrames.findIndex(x => x.id == activeFrame?.id);
        if (idx === -1) return;
        doorFrames.splice(idx, 1);
        onUpdate(doorFrames, () => {
            setShowConfirmModal(false)
            setActiveFrame(null);
        });
    }

    const handleUpdateDoor = (values, { setSubmitting }) => {
        const idx = doorFrames.findIndex(x => x.id == values.id);
        if (idx === -1) return;
        doorFrames[idx] = values;
        onUpdate(doorFrames, () => {
            setShowUpdateModal(false);
            setSubmitting(false);
        });
    }

    const handleAddNewDoor = (values, { setSubmitting }) => {
        doorFrames.push(values);
        onUpdate(doorFrames, () => {
            setShowUpdateModal(false);
            setSubmitting(false);
        })
    }

    return (
        <>
            <div className="flex justify-end justify-between items-center">
                <h1 className="text-sm font-bold">Configured Doorframes</h1>
                <Button variant={Button.variants.secondary} onClick={() => {
                    setShowFormModal(true);
                }}>Add new</Button>
            </div>
            <Table>
                <TableHead>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Door Direction</TableHeader>
                    {/* <TableHeader>Product Id</TableHeader> */}
                    <TableHeader textRight={true}>Actions</TableHeader>
                </TableHead>
                <TableBody>
                    {doorFrames.map((door, index) => {
                        // var product = products.find(x => x.id === row.productModuleRef);
                        return (
                            <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                <TableColumn size="small">
                                    <span className="ml-2">{door?.name}</span>
                                </TableColumn>
                                <TableColumn size="small">
                                    <span className="ml-2">{Doors.doorDirectionNames[door?.doorDirection]}</span>
                                </TableColumn>
                                {/* <TableColumn size="small">
                                    {product?.productId}
                                </TableColumn> */}
                                <TableColumn size="small" textRight={true}>
                                    <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                        <PencilAltIcon aria-hidden="true" onClick={() => {
                                            setActiveFrame(door);
                                            setShowUpdateModal(true);
                                        }} />
                                    </button>
                                    <button className="hover:text-red-600 h-6 w-6" type="button">
                                        <TrashIcon aria-hidden="true" onClick={() => {
                                            setShowConfirmModal(true);
                                            setActiveFrame(door);
                                        }} />
                                    </button>
                                </TableColumn>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <ConfirmationModal
                open={showConfirmModal}
                setOpen={() => setShowConfirmModal(false)}
                type="error"
                handleOnConfirm={handleConfirmDelete}
                description="Do you want to remove selected door? This operation is irreversible."
                confirmButtonText="Remove"
                title="Are you sure?"
            />

            <Modal
                isOpen={showUpdateModal}
                onClose={() => { setShowUpdateModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <DoorFramesForm initialValues={activeFrame} onClose={() => setShowUpdateModal(false)} onSubmit={handleUpdateDoor} avaialableProducts={avaialableProducts} isSolidDoors={isSolidDoors} />
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showFormModal}
                onClose={() => { setShowFormModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <DoorFramesForm initialValues={{
                            name: '',
                            doorDirection: Doors.doorDirections.right,
                            color: 'black',
                            width: 900,

                        }} onClose={() => setShowFormModal(false)} onSubmit={handleAddNewDoor} avaialableProducts={avaialableProducts} isSolidDoors={isSolidDoors} />
                    </div>
                </div>
            </Modal>

        </>
    );
};

const DoorFramesForm = ({ initialValues, onSubmit, onClose, isSolidDoors, avaialableProducts = [] }) => {

    const { t: translateCommon } = useTranslation([resources.common]);

    const doorProducts = avaialableProducts.filter(x => x.type === ProductModuleType.DoorElement || x.type === ProductModuleType.DoorProducts).map(x => {
        return { name: x.name, value: x.id }
    });

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {(props) => (
            <Form className='space-y-4'>
                <div className="grid grid-cols-4 mb-4 gap-4">
                    <div className="col-span-2">
                        <FormikInput
                            label={'Name'}
                            name="name"
                            formikProps={props}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Dør retning"}
                            name={`doorDirection`}
                            formikProps={props}
                            valueIsNumber={true}
                            options={[
                                ...Object.keys(Doors.doorDirectionNames).map(k => ({ value: k, name: Doors.doorDirectionNames[k] }))
                            ]}
                            showEmptyOption={!props.values.doorDirection}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikInput
                            label={'Door bredde'}
                            name="width"
                            formikProps={props}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Ramme Farge"}
                            name={`color`}
                            formikProps={props}
                            options={[
                                ...[{ value: 'black', name: 'Black' },{ value: 'aluminum', name: 'Aluminium' }]
                            ]}
                            showEmptyOption={!props.values.color}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Produkt Modul"}
                            name={`productModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.hingeModuleRef === ''}
                        />
                    </div>

                </div>
                <div className="flex justify-end mt-3 space-x-2">
                    <Button variant={Button.variants.secondary} onClick={() => onClose()}>Close</Button>
                    <FormikSubmitButton
                        text={translateCommon(translations.common.save)}
                        formikProps={props}
                    />
                </div>
            </Form>
        )}
    </Formik>
}