import React, {useState} from "react";

import Modal from "../Modal";
import InputText from "../InputText";
import {createOrder} from "../../api/rackbeat";
import ButtonComponent from "../ButtonComponent";

export const OrderNameModal = ({ isShowModal, setIsShowModal, product, quantity, loadActiveOffers }) => {
  const [header, setHeader] = useState('')

  const handleCreateNewOrder = async () => {
    await createOrder(header, product, quantity);
    loadActiveOffers();
    setHeader('');
    setIsShowModal(false);
  }

  return(
    <Modal isOpen={isShowModal} onClose={() => setIsShowModal(false)} size={'medium'} title={`Order Name`}>
      <InputText label={""} placeholder={"Type order name"} value={header} setValue={setHeader} fullWidth={true} className={'mb-8'} />
      <ButtonComponent onClick={handleCreateNewOrder} type={'button'} variant={'primary'} className={'justify-self-end w-full'} >
        <p>Add</p>
      </ButtonComponent>
    </Modal>
  )
}