import React, { useState, useEffect } from 'react';
import { getTeamPendingAndAcceptedInvites, getUserRoles, removePendingInvites, } from '../../../api/teams';
import toast from "react-hot-toast";
import { ManageTeamContext } from '../../../contexts';
import CreateInvite from './createInvite';
import InvitesTable from './pendingInvitesTable';
import PageHeader from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const Invite = () => {
    const [roles, setRoles] = useState([]);
    const [allInvites, setAllInvites] = useState([]);
    const [selectedInvites, setSelectedInvites] = useState([])
    const [initialValues, setInitialValues] = useState({ email: '', customerUserRoleId: '' });
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation([resources.admin]);

    const loadAllInvites = async () => {
        const allInvitesData = await getTeamPendingAndAcceptedInvites(1);
        setAllInvites(allInvitesData);
    }
    const loadData = async () => {
        try {
            await loadAllInvites();
            const userRoles = await getUserRoles();
            setRoles(userRoles);
            setInitialValues(prev => {
                return { ...prev, customerUserRoleId: userRoles[0].roleId };
            })
            setLoading(false);
        } catch (error) {
            toast.error(`${t('failedToLoad')}`);
        }
    };
    useEffect(() => {
        loadData();
    }, []);

    const toggleSelectInvite = (email) => {
        setSelectedInvites(prev => {
            const idx = selectedInvites.findIndex(x => x === email);
            if (idx === -1) {
                return [...prev, email];
            }
            return [...prev.filter(item => item !== email)];
        })
    }
    const toggleAllInvites = () => {
        const pendingInvites = allInvites.filter((f) => {return !f.acceptedDate });
        selectedInvites.length === pendingInvites.length ?
            setSelectedInvites([]) : setSelectedInvites(pendingInvites.map(x => x.email))

    }
    const removeSelectedInvites = async () => {
        const data = { emails: selectedInvites };
        await removePendingInvites(data);
        toast.success(`${t('invitesRemoved')}`);
        setSelectedInvites([]);
        loadAllInvites();
    }

    return (
        <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full">
                <div className="">
                    <div className="w-full" >
                        <PageHeader title={t('manageInvites')} />
                            <div className="grid grid-cols-6 gap-8">
                                <ManageTeamContext.Provider value={{ roles: roles, statuses: [] }}>
                                    <section
                                        className="col-span-6 xl:col-span-2">
                                        <h2 className="mt-2 text-md font-bold leading-7 text-gray-900 sm:text-xl sm:truncate mb-4 sm:mb-6 md:mb-8">
                                            {t('newInvite')}
                                        </h2>
                                        <CreateInvite
                                            loading={loading}
                                            roles={roles}
                                            initialValues={initialValues}
                                            onInviteCreated={() => loadAllInvites()}
                                        />
                                    </section>
                                    <section className="col-span-6 xl:col-span-4">
                                        <h2 className="mt-2 text-md font-bold leading-7 text-gray-900 sm:text-xl sm:truncate mb-4 sm:mb-6 md:mb-8">
                                            {t('pendingInvites')}
                                        </h2>
                                        <InvitesTable invites={allInvites} selectedInvites={selectedInvites}
                                            onToggleInvite={toggleSelectInvite} onToggleAllInvites={() => toggleAllInvites()} />
                                        <div className="flex justify-end py-4">
                                            {
                                                selectedInvites.length > 0 && (
                                                    <button type="submit"
                                                        disabled={loading}
                                                        onClick={e => removeSelectedInvites()}
                                                        className="inline-flex w-full sm:w-auto justify-center items-center px-8 py-2 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                        {t('removeSelected')}
                                                    </button>)
                                            }
                                        </div>
                                    </section>
                                </ManageTeamContext.Provider>
                            </div>
                         </div >
                    </div>
                </div>
            </div>
        </div>
    </div>
    </main>
    );
};

export default Invite;
