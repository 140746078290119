import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Alert, { AlertType } from '../../../components/Alert/Alert';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import FormikTextArea from '../../../components/Form/Formik/FormikTextArea';
import { SimaSelectedProductContext } from '../../../contexts';
import { resources, translations } from '../../../translations/translationsConstants';
import { initialInventoryProduct } from './InitialInventoryProduct';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { ProductStates } from './ProductStates';
import { ProductModuleTypes } from '../ProductModuleTypes';

const ProductForm = () => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const productContext = useContext(SimaSelectedProductContext);

    return (
        <>
            {productContext.selectedProduct && <div>
                <Formik initialValues={productContext.selectedProduct} onSubmit={productContext.handleOnSubmit} enableReinitialize={true}>
                    {(props) => (
                        <Form className='space-y-4'>
                            <div className="grid grid-cols-3 gap-x-3">
                                <FormikInput
                                    label={translateProducts(translations.simaProducts.form.productNumber)}
                                    name="productId"
                                    formikProps={props}
                                />
                                <FormikSelectInput
                                    label={translateProducts(translations.simaProducts.form.type)}
                                    name="type"
                                    valueIsNumber={true}
                                    options={Object.getOwnPropertyNames(ProductModuleTypes).map(x => {
                                        return {
                                            name: translateProducts(ProductModuleTypes[x]),
                                            value: x
                                        }
                                    })}
                                    formikProps={props}
                                />
                                <FormikSelectInput
                                    label={translateProducts(translations.simaProducts.form.stateInMarketplace)}
                                    name="state"
                                    valueIsNumber={true}
                                    options={Object.getOwnPropertyNames(ProductStates).map(x => {
                                        return {
                                            name: translateProducts(ProductStates[x]),
                                            value: x
                                        }
                                    })}
                                    formikProps={props}
                                />
                            </div>
                            <div>
                                <div className="flex mt-4 justify-end">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                </div>
                            </div>
                            <div className="space-y-3 border-t border-brand-green-dark">
                                <h1 className="text-sm font-bold mt-3">{translateProducts(translations.simaProducts.form.headerIntro)} </h1>
                                <div className="mt-5 space-y-3 md:col-span-4">
                                    {productContext.selectedProduct.inventoryManagementProduct === initialInventoryProduct
                                        && <Alert type={AlertType.Warning} title="Warning" msg="Product is missing in RackBeat" />
                                    }
                                    <div className="grid grid-cols-3 gap-x-3">
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.name)}
                                            name="inventoryManagementProduct.name"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.productNumber)}
                                            name="inventoryManagementProduct.number"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.barCode)}
                                            name="inventoryManagementProduct.barcode"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-3">
                                        <FormikTextArea
                                            type="textarea"
                                            label={translateProducts(translations.simaProducts.form.description)}
                                            name="inventoryManagementProduct.description"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-3">
                                        <FormikInput
                                            label={"Høyde"}
                                            name="inventoryManagementProduct.physical.height"
                                            showEmptyOption={true}
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={"Bredde"}
                                            name="inventoryManagementProduct.physical.width"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={"Dybde"}
                                            name="inventoryManagementProduct.physical.depth"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={"Str. Enhet"}
                                            name="inventoryManagementProduct.physical.sizeUnit"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-3">
                                        <FormikInput
                                            label={"Vekt"}
                                            name="inventoryManagementProduct.physical.weight"
                                            showEmptyOption={true}
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={"Vekt Enhet"}
                                            name="inventoryManagementProduct.physical.weightUnit"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>

                                    <div className="grid grid-cols-3 gap-x-3">
                                        <FormikSelectInput
                                            label={translateProducts(translations.simaProducts.form.group)}
                                            name="inventoryManagementProduct.group"
                                            options={productContext.dictionaries.groups}
                                            showEmptyOption={true}
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikSelectInput
                                            label={translateProducts(translations.simaProducts.form.primarySupplier)}
                                            name="inventoryManagementProduct.defaultSupplierId"
                                            options={productContext.dictionaries.primarySuppliers}
                                            showEmptyOption={true}
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.isDisabled)}
                                            name="inventoryManagementProduct.isBarred"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>
                                    <div className="grid grid-cols-3 gap-x-3">
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.price)}
                                            name="inventoryManagementProduct.salesPrice"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                        <FormikInput
                                            label={translateProducts(translations.simaProducts.form.unit)}
                                            name="inventoryManagementProduct.unit"
                                            disabled={true}
                                            formikProps={props}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>}
        </>
    );
};

export default ProductForm;