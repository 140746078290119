import { get, post } from './api'

/**
 *  @returns {Promise.<{userId:string,userName:string,email:string,firstName:string,lastName:string,phoneNumber:string,fullName:string,customerIds:Array.<string>,isSppUser:boolean,userProfileImage:string,customerUserRole:number}>}
 */
export const getUserProfile = async () => {
    return await get(`MyUser`);
}

export const validateOrgNumber = async (orgNumber) => {
    return await get(`Onboarding/ValidateOrgNumber/${orgNumber}`);
}

export const getCountries = async () => {
    return await get(`Onboarding/GetCountries`);
};

export const createNewCompany = async (companyData) => {
    return await post(`Onboarding/AddCustomer`, companyData);
}
