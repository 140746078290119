import {useParams} from "react-router-dom";
import Alert, {AlertType} from "../../components/Alert/Alert";
import ButtonComponent from "../../components/ButtonComponent";
import DropdownComponent from "../../components/DropdownComponent";
import InputText from "../../components/InputText";
import React, {useEffect, useState} from "react";
import {addItemToOffer, getActiveOffers, getProduct, getProductLineable} from "../../api/rackbeat";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../utils/currency";
import { OrderNameModal } from "../../components/OrderNameModal";

export const ProductDetail = () => {
  const { productId } = useParams();

  const [activeOffers, setActiveOffers] = useState([]);
  const [offerNumber, setOfferNumber] = useState(0)
  const [showInput, setShowInput] = useState(false)
  const [header, setHeader] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const [product, setProduct] = useState([])
  const [currentOffer, setCurrentOffer] = useState(null)
  const [selectedActiveOrder, setSelectedActiveOrder] = useState({ value: '', display: ''})
  const [showAdded, setShowAdded] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [linable, setLinable] = useState([])

  const history = useHistory();

  const loadProduct = async () => {
    const response = await getProduct(productId);
    setProduct(response || {});
  }

  const isValidHeading = (offer) => {
    return offer.heading && offer.heading.length > 0 && offer.heading !== '``';
  }

  const displayOnOffer = (offer) => {
    return isValidHeading(offer) ? `${offer.heading ? `${offer.heading}` : offer.number}` : offer.number;
  }

  const getPicture = () => {
    return product?.pictures?.display || product?.picture_url || 'https://fakeimg.pl/600x400/cccccc/cccccc';
  }

  const productTypes = [ { value: '0', display: 'Pick draft order' }, { value: '1', display: 'Add To New Order', highlighted: true } ]
  productTypes.push(...activeOffers.map((offer, index) => {
    return { value: offer.number, display: displayOnOffer(offer) }
  }));

  const activeOrdersData = []
  activeOrdersData.push(...activeOffers.map((offer, index) => {
    return { value: offer.number, display: `${offer.heading ? `${offer.heading}` : offer.number }` }
  }));

  useEffect(() => {
    loadProduct();
    loadActiveOffers();
    loadLinable();
  }, []);

  const handleAddItemToOffer = async () => {
    if(currentOffer) {
      await addItemToOffer(currentOffer.number, product, quantity);
      setShowAdded(true)
    } 
  }

  const loadActiveOffers = async () => {
    const response = await getActiveOffers();
    setActiveOffers(response || []);
    setCurrentOffer(response?.[0]);
  }

  const handleSelectChange = (e) => {
    const {display, value} = e;
    setSelectedActiveOrder({ value, display })
    setCurrentOffer(activeOffers.find(offer => offer.number === value))
  }

  const loadLinable = async () => {
    const response = await getProductLineable(productId);
    setLinable(response || []);
  }

  const handleCancel = () => {
    history.goBack();
  }

  const getTotalItemsCurrentOffer = () => {
    let totalItems = 0;

    if(currentOffer) {
      totalItems = currentOffer.lines.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.quantity;
      }, 0);
    }

    return totalItems;
  }

  return(
    <>
      {
        showAlert && <div className={'mb-2'}><Alert type={AlertType.Warning} title="Warning" msg={warningMessage} /></div>
      }
      <main className="main-container bg-brand-gray-light focus:outline-none h-full relative">
      <div style={{ backgroundColor: '#AA9C89' }}  class='h-36 w-full'>
        <div className='max-w-screen-3xl mx-auto inset-0 pt-9 px-4 sm:px-6 lg:px-8'>
          <div className='space-y-3'>
            <h1 className='uppercase text-lg'>{ product?.name } </h1>            
            <h2 className='uppercase text'> {product?.number}</h2>
            {/* <div className='flex justify-between pt-6'>
              <div className='flex gap-4'>
                <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>Send on email</Button>
                <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>View Pdf</Button>
              </div>
              
              <div className='flex gap-4'>
                <Button variant={'secondary'} onClick={() => handleRejectOffer(order?.number)}>Reject</Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full">
              <div className="max-w-8xl mx-auto relative">
              <div className="my-10 flex justify-between gap-10">
                <div className="w-3/4">
                  <div className="flex bg-white border w-full p-8">
                    <div className="mr-10 w-2/6">
                      <img
                        src={getPicture()}
                        className="w-72"
                        alt={'test'}
                      />
                    </div>
                    <div className='justify-end w-4/6 relative'>
                      <div className="mb-10">
                        <div className="flex justify-between">
                          <p className="text-lg font-semibold">{product?.name}</p>
                          <p className="text-xl font-semibold">{ formatCurrency(linable?.price_with_discount)} NOK</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="font-normal text-sm text-brand-gray-text mt-3">
                            Product Number: {product?.number}
                          </p>
                          {
                            linable?.discount_percentage > 0 &&
                            <p className="text-base mt-2 font-semibold text-red-600">{linable?.discount_percentage} % discount</p>
                          }
                        </div>
                      </div>
                      {
                        showAdded && (
                          <div className={'bg-brand-green-lighter absolute -top-6 right-1 px-6 py-2'}>
                            <p className={'text-white text-sm'}>Added >></p>
                          </div>
                        )
                      }
                      {
                        product?.description &&                        
                        <div className="mb-10">
                          <p className="text-base font-normal mb-3 border-b-2 mb-4">Description</p>
                          <p className="text-base font-normal">
                            {
                              product?.description
                            }
                          </p>
                        </div>
                      }
                      <div className="mb-10">
                        <div>
                          <p className="text-base font-normal mb-3 border-b-2 mb-4">Details</p>
                          <div className={'flex'}>
                            <div className={'mr-44'}>
                              <p className={'mb-2'}>Weight: {product?.physical?.weight}</p>
                              <p>Width: {product?.physical?.width}</p>
                            </div>
                            <div>
                              <p className={'mb-2'}>Height: {product?.physical?.height}</p>
                              <p>Depth: {product?.physical?.depth}</p>
                            </div>
                          </div>
                        </div>
                        {
                          product?.pdf_url && (
                            <div>
                              <p className="text-base font-normal mb-3 border-b-2 mb-4 mt-10">Documentation</p>
                              <div className={'flex'}>
                                <a className="flex items-center cursor-pointer" onClick={() => {window.open(product?.pdf_url);}}>
                                  <img src={'/images/pdf_icon.png'} className="mr-3" alt={'test'} />
                                  <p className="underline text-brand-gray-text">Link to product documentation</p>
                                </a>
                              </div>
                            </div>
                          )
                        }
                      </div>
                      <div className="flex justify-between">
                        <div>

                        </div>
                        <div className="text-right space-y-4">
                          {/* <DropdownComponent label={''} selectedOption={productTypes[selectedIndex]} setSelectedOption={handleSelectChange} options={productTypes}/> */}
                          <InputText label={"Quantity"} placeholder={"Quantity"} value={quantity} setValue={setQuantity} className={'w-16'} />
                          <div className="flex gap-4">
                            <ButtonComponent onClick={handleCancel} type={'button'} variant={'secondary'} >Cancel</ButtonComponent>
                            <ButtonComponent onClick={handleAddItemToOffer} type={'button'} variant={'primary'} >
                              <p className="mr-2">Add to order :  </p> {currentOffer?.number}
                            </ButtonComponent>                        
                          </div>
                        </div>
                        
                        {
                          showInput && (
                            <InputText label={""} placeholder={"Type order name"} value={header} setValue={setHeader} fullWidth={true} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div >
                <div className="w-1/4">
                  <div className="bg-white border p-8">
                    <p className="text-base font-normal mb-3 border-b-2 mb-4">Orders</p>

                      {
                        activeOffers?.length <= 0 && (
                          <div className="mt-4">
                            <p className="text- font-normal">There are no active offers. Please create a new one to start.</p>
                            
                            <div className="mt-8 space-y-4 grid">
                              <ButtonComponent onClick={() => setIsShowModal(true)} type={'button'} variant={'primary'} className={'justify-self-end'}>
                                <p>Create new order</p>
                              </ButtonComponent>
                            </div>
                          </div>
                        )
                      }

                      {
                        activeOffers?.length > 0 && (
                          <div className="mt-4">
                            <p className="text-sm mb-1">Current order that you are working on</p>
                            <div className="border py-2 px-3 bg-brand-gray-light">
                              {currentOffer?.heading ? currentOffer?.heading : currentOffer?.number}
                            </div>

                            <div className="grid grid-cols-2 gap-1 mt-5">
                              <p className="text-sm mb-1">Number of items:</p>
                              <p className="text-sm">{getTotalItemsCurrentOffer()}</p>
                              <p className="text-sm mb-1">Total:</p>
                              <p className="text-sm">{formatCurrency(currentOffer?.total_total)} {currentOffer?.currency}</p>
                            </div>

                            <p className="text-sm border-b-2 pb-1 mt-10 mb-4">Change current order or create new one</p>
                            <div className="">
                              <div className="space-y-4">
                                <DropdownComponent label={'Active orders'} selectedOption={selectedActiveOrder} setSelectedOption={handleSelectChange} options={activeOrdersData} isOptionFullWidth />
                              </div>
                            </div>

                            <div className="mt-6 space-y-4 grid">
                              <ButtonComponent onClick={() => setIsShowModal(true)} type={'button'} variant={'primary'} className={'justify-self-end'}>
                                <p>Create new order</p>
                              </ButtonComponent>
                            </div>
                          </div>
                        )
                      }
                  </div>
                </div>
              </div>
              </div>
            </div >
          </div>
        </div>
      </div>
    </main>
    <OrderNameModal isShowModal={isShowModal} loadActiveOffers={loadActiveOffers} setIsShowModal={setIsShowModal} product={product} quantity={quantity} />
    </>
  )
}