
import React, { useState, useEffect, Fragment, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Badge, Button } from '../../../components';
import { getCustomers } from '../../../api/simaAdmin';
import { Table as TableCore, useTableActions } from '@metaforcelabs/metaforce-core';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useTranslation } from 'react-i18next';
import { useSearchQuery } from '../../../hooks/useSearchQuery';
import { resources, translations } from '../../../translations/translationsConstants';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikCheckbox } from '../../../components/Form/Formik/FormikCheckbox';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import RadioGroup from '../../../components/Form/RadioGroup';
import { useToastAction } from '../../../hooks/useToastAction';
import debounce from 'lodash.debounce';
import axios from 'axios';

const dummyLoadData = [{}];

const activationStatus = {
  Pending: '1',
  Active: '2',
  Rejected: '3'
};

const formInitialValues = {
  searchQuery: '',
  simaStatus: {
    [activationStatus.Pending]: true,
    [activationStatus.Active]: false,
    [activationStatus.Rejected]: false
  }
};

const CustomersList = (props) => {
  const { t:translationSima } = useTranslation([resources.simaAdmin]);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [recordsToShow, setRecordsToShow] = useState(dummyLoadData);
  const [cancelToken, setCancelToken] = useState(undefined);
  const { t } = useTranslation([resources.internal]);

  let { hasParam, getParam } = useSearchQuery();
  const customersAction = useToastAction();
  const tableActions = useTableActions(recordsToShow, 8);

  const fetchCustomers = (values) => {
    const request = {
      searchName: values ? values.searchQuery : formInitialValues.searchQuery,
      simaStatus: values ? (Object.keys(values.simaStatus).filter(x => values.simaStatus[x]).map(x => parseInt(x))) 
      : (Object.keys(formInitialValues.simaStatus).filter(x => formInitialValues.simaStatus[x]).map(x => parseInt(x))) 
    }
    if(cancelToken !== undefined && customersAction.isExecuting)
    {
      cancelToken.cancel();
      setCancelToken(undefined);
    }
    const tokenSource = axios.CancelToken.source();
    setCancelToken(tokenSource);
    customersAction.execute(async () => {
      const getCustomersResult = await getCustomers(request, tokenSource.token);
      setRecordsToShow(getCustomersResult.sort((a, b) => a.name.localeCompare(b.name)));
    }, `${t(('failedToLoadCustomers'))}`, undefined, undefined, () => { setCancelToken(undefined) });
  };

  const approveStatusBagde = (status) => {
    switch (status) {
      case 2:
        return <Badge type="success" text={t('approved')} />;
      case 3:
        return <Badge type="error" text={t('unapproved')} />;
      case 1:
      default:
        return <Badge type="info" text={t('waitingForApprovals')} />;
    }
  };

  const handleOnResetFilter = (resetAction) => {
    resetAction();
    fetchCustomers();
  };

  const debouncedOnQueryKeyDown = useMemo(
    () => debounce((props) => {
        props.submitForm();
      }, 1000),
    [formValues]
  );

  const handleOnSubmit = async (values) => {
    await fetchCustomers(values);
  };

  useEffect(() => {
    let values = { ...formValues };
    const hasStatusParam = hasParam('status');
    if (hasStatusParam) {
      const statusParam = getParam('status');
      if (statusParam === 'pending') {
        values = { ...values, activationStatus: activationStatus.Pending };
        setFormValues(values);
      }
    }
    fetchCustomers(values);
  }, []);

  return (
    <>
        <div className="flex flex-col pb-5 mb-10 border-b border-brand-green-dark">
          <h1 className="text-3xl font-bold">{translationSima(translations.simaAdmin.customers.title)}</h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
          {translationSima(translations.simaAdmin.customers.subtitle)}
          </p>
        </div>

        <div>
          <Formik initialValues={formValues} enableReinitialize={true} onSubmit={handleOnSubmit}>
            {(props) => (
              <Form>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <FormikInput label="Search" name="searchQuery" formikProps={props} onKeyDown={() => debouncedOnQueryKeyDown(props)} />
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <RadioGroup label="Activation status">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        <FormikCheckbox
                          label={translationSima(translations.simaAdmin.customers.form.pendingStatus)}
                          name={`simaStatus[${activationStatus.Pending}]`}
                          formikProps={props}
                          onClick={props.submitForm}
                        />
                        <FormikCheckbox
                          label={translationSima(translations.simaAdmin.customers.form.activeStatus)}
                          name={`simaStatus[${activationStatus.Active}]`}
                          formikProps={props}
                          onClick={props.submitForm}
                        />
                        <FormikCheckbox
                          label={translationSima(translations.simaAdmin.customers.form.rejectedStatus)}
                          name={`simaStatus[${activationStatus.Rejected}]`}
                          formikProps={props}
                          onClick={props.submitForm}
                        />
                      </div>
                    </RadioGroup>
                  </div>

                  {/* <div className="flex items-end ml-auto col-span-3 sm:col-span-2">
                    <Button
                      variant={Button.variants.secondary}
                      onClick={() => handleOnResetFilter(props.resetForm)}
                    >
                      Reset
                    </Button>

                    <FormikSubmitButton
                      id="filter"
                      text="Filter"
                      className="ml-2 i"
                      formikProps={props}
                    />
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="relative mt-8">
          <Table>
            <TableHead>
              <TableHeader>{translationSima(translations.simaAdmin.customers.list.header.name)}</TableHeader>
              <TableHeader>{translationSima(translations.simaAdmin.customers.list.header.status)}</TableHeader>
              <TableHeader>{translationSima(translations.simaAdmin.customers.list.header.created)}</TableHeader>
              <TableHeader>{translationSima(translations.simaAdmin.customers.list.header.updated)}</TableHeader>
            </TableHead>
            <TableBody>
              {tableActions.pageData?.map((row, index) => (
                <Fragment key={index}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <TableColumn>
                      <a
                        onClick={() => props.onSelectCustomer(row)}
                        className="cursor-pointer font-medium text-sm text-brand-black hover:text-indigo-500 border-0 shadow-none"
                      >
                        {row.name}
                      </a>
                    </TableColumn>
                    <TableColumn>{approveStatusBagde(row.simaStatus)}</TableColumn>
                    <TableColumn>{stringToLocaleDateTimeString(row.createdTime)}</TableColumn>
                    <TableColumn>
                      {stringToLocaleDateTimeString(row.updatedTime || row.createdTime)}
                    </TableColumn>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <TableCore.Pagination tableActions={tableActions} />
        </div> 
    </>
  );
};

export default CustomersList;