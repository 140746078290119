import { translations } from '../../../translations/translationsConstants';
import { productModuleTypes } from '../../../utils/constants';

export const ModuleTypesNames = {
    [productModuleTypes.standardWidthGlassWall]: translations.simaProducts.configModuleTypes.standardWidthGlassWall,
    [productModuleTypes.customWidthGlassWall]: translations.simaProducts.configModuleTypes.customWidthGlassWall,
    [productModuleTypes.glassDoor]: translations.simaProducts.configModuleTypes.glassDoor,
    [productModuleTypes.solidDoor]: translations.simaProducts.configModuleTypes.solidDoor,
    [productModuleTypes.glassTape]: translations.simaProducts.configModuleTypes.glassTape,
    [productModuleTypes.glassWallFrame]: translations.simaProducts.configModuleTypes.glassFrame,
    [productModuleTypes.dryWallPartitionMount]: translations.simaProducts.configModuleTypes.dryWallPartitionMount,
}

export const ModifyElementType = {
    SelectInput: 1,
    InputNumber: 2,
    InputText: 3,
    ToggleSwitch: 4
}

export const ModifyElementTypeNames = {
    [ModifyElementType.SelectInput]: translations.simaProducts.configModulesForm.selectInput,
    [ModifyElementType.InputNumber]: translations.simaProducts.configModulesForm.inputNumber,
    [ModifyElementType.InputText]: translations.simaProducts.configModulesForm.inputText,
    [ModifyElementType.ToggleSwitch]: translations.simaProducts.configModulesForm.toggleSwitch,
}