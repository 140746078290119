import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useNavigation = () => {
    const history = useHistory();

    const navigateTo = (path) => {
        history.push(`${path}`);
    }

    return {
        goToOrder: (id) => navigateTo(`/orders/${id}`),
        goToProject: (id) => navigateTo(`/projects/${id}`),
        goToGlassWall: (id) => navigateTo(`/glasswall/${id}`),
        goToProductDetail: (id) => navigateTo(`/productDetail/${id}`),
    }
}