import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import { useTranslation } from 'react-i18next';
import { getProductAreaDetails, updateProductAreaDetails } from '../../../api/simaAdmin';
import { resources, translations } from '../../../translations/translationsConstants';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import toast from 'react-hot-toast';
import { useToastAction } from '../../../hooks/useToastAction';

const SimaProductAreaManagement = ({ onClearSelected, selectedProductArea }) => {
  const { t: translationCommon } = useTranslation([resources.common]);
  const { t: translationSima } = useTranslation([resources.simaAdmin]);
  const { t } = useTranslation([resources.internal]);

  const [formValues, setFormValues] = useState({});

  const productAreasAction = useToastAction();
  const updateProductAreasAction = useToastAction();

  const fetchProductArea = async () => {
    productAreasAction.execute(async () => {
      const productAreaData = await getProductAreaDetails(selectedProductArea.id);
      setFormValues({ ...productAreaData });
    }, `${t('failedMessage')}`);
  };

  const handleOnSubmit = (values, actions) => {
    updateProductAreasAction.execute(
      async () => {
        await updateProductAreaDetails(values);
        actions.setSubmitting(false);
      },
      `${t('failedToSaveData')}`,
      `${t('saveSuccessful')}`
    );
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file.size > 100000) {
      toast.error(`${t('maximumFileSize')}`);
      return;
    }
    var reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      const imageData = `data:${file.type};base64,${base64String}`;
      setFieldValue(event.target.name, imageData);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchProductArea();
  }, []);

  return (
    <div>
      <div className="flex justify-start">
        <Button variant={Button.variants.primary} onClick={onClearSelected}>
          {translationCommon(translations.common.back)}
        </Button>
      </div>
      <div className="flex flex-col mt-5 pb-5 mb-10 border-b border-brand-green-dark">
        <h1 className="text-3xl font-bold">
          {translationSima(translations.simaAdmin.productAreas.edit.title)} {selectedProductArea.name}
        </h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          {translationSima(translations.simaAdmin.productAreas.edit.subtitle)}
        </p>
        {formValues.id && (
          <div className="bg-white mt-4 py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
            <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
              {(props) => (
                <Form className="space-y-8 divide-y divide-gray-200 mt-4">
                  <div className="sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-7">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          {translationSima(translations.simaAdmin.productAreas.edit.form.name)}
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <FormikInput
                            className="block w-full max-w-lg rounded-md border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            name="name"
                            formikProps={props}
                            disabled={true}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-brand-green-dark sm:pt-5">
                        <label
                          htmlFor="centerPointApiUrl"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          {translationSima(
                            translations.simaAdmin.productAreas.edit.form.description
                          )}
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <FormikInput
                            className="block w-full max-w-lg rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            name="description"
                            formikProps={props}
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-brand-green-dark sm:pt-5">
                        <label
                          htmlFor="centerPointApiUrl"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          {translationSima(translations.simaAdmin.productAreas.edit.form.logo)}
                        </label>
                        <div className="mt-1 flex items-center">
                          {!props.values.logo && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="46"
                              height="46"
                              fill="#374151"
                              viewBox="0 0 16 16"
                            >
                              {' '}
                              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />{' '}
                              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />{' '}
                            </svg>
                          )}
                          {props.values.logo && (
                            <img
                              className="inline-block h-12 w-12"
                              src={props.values.logo}
                              alt=""
                            />
                          )}
                          <div className="ml-10 flex">
                            <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                              <label
                                htmlFor="user-photo"
                                className="cursor-pointer relative text-sm font-medium text-blue-gray-900"
                              >
                                <span className="cursor-pointer">
                                  {props.values.logo
                                    ? translationSima(
                                        translations.simaAdmin.productAreas.edit.form.change
                                      )
                                    : translationSima(
                                        translations.simaAdmin.productAreas.edit.form.add
                                      )}
                                </span>
                              </label>
                              <input
                                id="logo"
                                name="logo"
                                type="file"
                                onChange={(e) => {
                                  handleImageChange(e, props.setFieldValue);
                                }}
                                disabled={props.isSubmitting}
                                className="cursor-pointer absolute inset-0 w-full h-full opacity-0 border-brand-green-dark rounded-md"
                              />
                            </div>
                            {props.values.logo && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  props.setFieldValue('logo', null);
                                }}
                                disabled={props.isSubmitting}
                                className="disabled:opacity-50 ml-5 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500"
                              >
                                {t('remove')}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <Button
                        className="rounded-md border border-brand-green-dark bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        variant={Button.variants.secondary}
                        onClick={() => onClearSelected()}
                      >
                        {t('cancel')}
                      </Button>
                      <FormikSubmitButton
                        id="save"
                        text={t('save')}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        formikProps={props}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimaProductAreaManagement;
