import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useClassNames } from '../../../../hooks/useClassNames';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { getProducts, getProductsByIds } from '../../../../api/simaProductsConfiguration';
import { resources, translations } from '../../../../translations/translationsConstants';
import { useToastAction } from '../../../../hooks/useToastAction';
import { FormProductModuleConfigContext } from '../../../../contexts';
import ProductConfigurationModuleName from './productConfigurationModuleName';
import ProductModulePropertyForm from './CommonForm/ProductModulePropertyForm';
import FormikErrorMessage from '../../../../components/Form/Formik/FormikErrorMessage';

const WallForm = ({ configModule, onUpdate, onNameChange, fields, avaialableProducts, validationSchema = Yup.object().shape({}) }) => {

  const wall = configModule?.wall;
  const { classNames } = useClassNames();
  const { t: translateCommon } = useTranslation([resources.common]);
  const { t: translateProducts } = useTranslation([resources.simaProducts]);
  const [propertyOptionsProducts, setPropertyOptionsProducts] = useState([]);
  const [currentField, setCurrentField] = useState(fields[0]);
  const getAction = useToastAction();

  const getPropertyOptionsProducts = () => {
    const productsIds = wall.width.propertyOptions.map(x => x.productModuleRef);

    getAction.execute(async () => {
      const result = await getProductsByIds(productsIds);
      setPropertyOptionsProducts(result);
    }, 'Failed to load config module');
  };

  // const validationSchema = Yup.object().shape(
  //   fields.reduce((prev, curr) => {
  //     return { ...prev, [curr.fieldName]: Yup.string().required("Field is required") }
  //   }, {}));

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();
    setCurrentField({ ...tab });
  };

  const isCurrentField = (field) => {
    return field.fieldName === currentField.fieldName;
  }

  useEffect(() => {
    getPropertyOptionsProducts();
  }, [configModule])

  return (
    <>
      {wall &&
        <FormProductModuleConfigContext.Provider value={{
          data: wall,
          propertyOptionsProducts: propertyOptionsProducts,
          getPropertyOptionsProducts: getPropertyOptionsProducts,
          onUpdate: onUpdate,
          avaialableProducts: avaialableProducts
        }}>
          <ProductConfigurationModuleName name={configModule.name} onUpdate={name => onNameChange(name)} />
          <nav className="flex justify-between border-b border-brand-green-dark pb-2 mb-2">
            <div className="block">
              <ol className="flex items-center space-x-4">
                {fields.map((field, index) => (
                  <li key={index}>
                    <div className=" flex items-center">
                      <button
                        className={classNames(
                          isCurrentField(field)
                            ? 'bg-gray-300 text-gray-900'
                            : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900 ',
                          'block rounded-md py-1 px-3 text-sm font-medium'
                        )}
                        onClick={(e) => handleOnTabItemClick(field, e)}>
                        {translateProducts(field.name)}
                      </button>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </nav>
          <Formik initialValues={wall} onSubmit={(values, actions) => {
            onUpdate(values, actions)
          }} enableReinitialize={true} validationSchema={validationSchema}>
            {(props) => (
              <Form className='space-y-4 divide-y divide-gray-300'>
                <div className="">
                  <ProductModulePropertyForm props={props} fieldName={currentField.fieldName} useProductModuleRef={currentField.useProductModuleRef}
                    fieldConfig={currentField}
                  />
                  <div>
                    {
                      Object.keys(props.errors).map(k => (<FormikErrorMessage name={k} />))
                    }
                  </div>
                  <div className="flex mt-4 justify-end">
                    <FormikSubmitButton
                      text={translateCommon(translations.common.save)}
                      formikProps={props}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </FormProductModuleConfigContext.Provider>}
    </>
  );
};

export default WallForm;