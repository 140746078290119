import './styles.scss';
import React, { useEffect, useState } from 'react';
import { formatShortDate } from '../../hooks/useDateFormat';
import { getOrders  } from '../../api/rackbeat';
import InputText from '../../components/InputText';
import {formatCurrency} from "../../utils/currency";
import { useHistory } from "react-router-dom";

const Orders = () => {
  const [offers, setOrders] = useState([]);
  const [offersToShow, setOffersToShow] = useState(null);
  const [search, setSearch] = useState('');

  const [total, setTotal] = useState(0);

  const history = useHistory();

  useEffect(() => {
    loadOrders();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [offers, search]);

  
  const handleSearch = () => {
    let filteredOffers = offers;

    if(search && search !== '') {
      filteredOffers = offers?.filter((offer) => {
        return offer?.heading?.toLowerCase().includes(search.toLowerCase()) ||
          offer?.number?.toString().toLowerCase().includes(search.toLowerCase()) ||
          offer?.status?.toLowerCase().includes(search.toLowerCase()) || 
          offer?.id?.toString().toLowerCase().includes(search.toLowerCase()) || 
          offer?.type?.toLowerCase().includes(search.toLowerCase())

      });
    }

    setOffersToShow(filteredOffers);

    setTotal(filteredOffers.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_total;
    }, 0))
  }

  const loadOrders = async () => {
    const response = await getOrders();

    const ordersFromResponse = response?.filter(x => !x.is_accepted).sort((a, b) => b.id - a.id) || [];
    

    // loop through offers and set status
    ordersFromResponse.forEach((order) => {
      if(order.is_rejected) {
        order.status = 'Rejected';
      } else if(order.is_accepted) {
        order.status = 'Accepted';
      } else if(order.is_sent) {
        order.status = 'Sent';
      } else if(order.is_shipped) {
        order.status = 'Shipped';
      } else if(order.is_booked) {
        order.status = 'In Progress';
      } else if(order.is_offer) {
        order.status = 'Draft';
      } else {
        order.status = 'Created';
      }
    });

    setOrders(ordersFromResponse);
  }

  const styles = {
  }
  
  const handleGoToOrder = (order) => {
    // history.push(`/offers/${offerNumber}`);

    history.push(`/orders/${order.number}/${order.is_offer ? 'offer' : 'order'}`);
    // console.log('order', order)
    // history.push(`/orders/${orderNumber}`);
  }

  return (
    <>
      <div className={'w-full bg-brand-brown-medium px-6 border-brand-green-dark border-t border-b'}>
        <div style={{paddingLeft: styles.paddingLeft}}
             className='bg-brand-brown-medium h-48 max-w-screen-3xl mx-auto flex flex-col justify-center '>
            <p className={'font-semibold text-lg'}>ORDERS</p>
            <div className='flex gap-10'>
              <InputText label="Search" placeholder="Search" className={'w-96'} value={search} setValue={setSearch} />
            </div>
        </div>
      </div>
    <main className="main-container bg-brand-gray-light focus:outline-none h-full relative ">
      <div className={'max-w-screen-3xl mx-auto px-6'}>
        <div style={{paddingLeft: styles.paddingLeft}} className="w-full mt-2 inset-0 py-6">
          <div className="flex flex-col pb-10 h-full">
            <div className="w-full">
              <div className="w-full h-full">
                <div className="w-full relative">
                  <div className="mt-5 flex flex-cols gap-5 justify-center">
                    {
                      offersToShow?.length > 0 &&
                      <table className="min-w-full divide-y divide-brand-green-dark border-brand-green-dark border table-auto">
                        <thead className="border-brand-green-dark">
                        <tr className=''>
                          <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
                            #
                          </th>
                          <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
                            Order Number
                          </th>
                          <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
                            Type
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left w-96 text-sm font-semibold text-gray-900 text-base font-semibold">
                            Heading
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
                            Issue date
                          </th>
                          
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
                            Status
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right pr-5 text-sm font-semibold text-gray-900 text-base font-semibold">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-brand-green-dark bg-white">
                      {
                        offersToShow.map((order, index) => {
                          return (
                            <tr className={`cursor-pointer ${order?.is_offer ? order?.is_rejected ? 'bg-gray-200' : 'bg-yellow-50' : ''}`} key={index}  onClick={() => handleGoToOrder(order)}>
                              <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                                {order.id}
                              </td>
                              <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                                {order?.is_order ? order.number : ''}
                              </td>
                              <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                                {order?.type}
                              </td>
                              
                              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                <p className='truncate w-96'>
                                  {order.heading}
                                </p>
                              </td>
                              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                {formatShortDate(order.offer_date)}
                              </td>
                              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                {order.status}
                              </td>
                              <td className="px-3 py-3.5 text-right pr-5 text-sm font-semibold text-gray-900">
                                {order.total_total ? `${formatCurrency(order.total_total)} ${order.currency}` : '-'}
                              </td>
                            </tr>
                          )
                        })
                      }
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      </th>
                      <th scope="col" className="px-3 py-3.5 h-20 text-left text-sm font-semibold text-gray-900 font-semibold">
                        Total
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 font-semibold">
                        {formatCurrency(total)} NOK
                      </th>
                    </tbody>
                  </table>
                }

                {
                  offersToShow?.length <= 0 &&
                  <div className="animate-pulse w-full h-96">
                    {/* Here you can customize your skeleton loader */}
                    <div className="skeleton-loader-item bg-gray-300 h-24 w-full"></div> 
                    <div className="skeleton-loader-item bg-gray-300 h-96 w-full mt-4"></div> 
                  </div> 
                }
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
      </div>
    </main>
    </>
  )
};

export default Orders;
