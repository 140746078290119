import React, { useEffect, useState } from "react";
import { TextInput } from "../../../../components/Form/TextInput";
import { Button } from "../../../../components/Form/Button";

export default function ProductConfigurationModuleName({ name, onUpdate }) {
    const [nameValue, setNameValue] = useState('')

    useEffect(() => {
        setNameValue(name);
    }, [name])

    const handleUpdateName = () => {
        onUpdate(nameValue);
    }

    return (
        <section className='mb-4 pb-4'>
            <label className='block text-gray-900 rounded-md py-1 text-sm font-medium'>Name</label>
            <div className='flex justify-between space-x-4'>
                <TextInput type="text" value={nameValue}
                    width='w-full'
                    onChange={e => {
                        setNameValue(e);
                    }} />
                <Button text="Save" onClick={e => {
                    handleUpdateName();
                }} />
            </div>
        </section>
    )
}