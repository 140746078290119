import { useContext } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { ExclamationIcon } from '@heroicons/react/outline';
import { OidcRoutesContext } from '../../contexts';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import ActionButton from '../../components/ActionButton';
import PageHeader from '../../components/PageHeader';

const NotValidSimaLicenseCompany = () => {
  const oicdContext = useContext(OidcRoutesContext);
  const { logout } = useReactOidc();

  return (
    <>
      <PageHeader title={`You have loggen in as customer: ${oicdContext.customerInfo?.name}`} />
                        
      <p className="-mt-4 text-base leading-7 text-gray-600">
      Your registration is in process of approval. You will be notified when you can access your account.
      </p>
      <div className='mt-6 inline-flex'>
        <ActionButton 
          title="Exit this application" 
          onClick={logout}
          image="/images/exit_application.png"
        />
      </div>
    </>
  );
};

export default NotValidSimaLicenseCompany;
