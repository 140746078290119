import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DropdownComponent = ({ selectedOption, setSelectedOption, options, label, className = 'w-full', isOptionFullWidth }) => {
  const styles = {
    // dropdownWidth: '194.5px',
    // dropdownHeight: '38px'
  }

  return (
    <div className={''}>
      {
        label && 
        <p className={'text-sm font-normal mb-1 mt-3'}>{label}</p>
      }
      
      <Combobox as="div" value={selectedOption} onChange={setSelectedOption} >
        <div className={`${className} relative`}>
        <Combobox.Button className="relative w-full text-left cursor-pointer focus:outline-none">
          <Combobox.Input
            style={{height: styles.dropdownHeight}}
            className="w-full cursor-pointer focus:border-brand-green-dark text-sm border-brand-green-dark px-4 py-2"
            displayValue={(option) => option?.display}
          />
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Combobox.Button>

          {options.length > 0 && (
            <Combobox.Options style={{width: isOptionFullWidth ? '100%' : '500px'}} className="absolute z-10 mt-1 overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <Combobox.Option
                  key={option.value}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-brand-black text-white' : option.highlighted ? 'font-bold' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{option.display}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
};
export default DropdownComponent;
