import './styles.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import ActionButton from '../../components/ActionButton';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {
  const { t } = useTranslation([resources.dashboard]);

  return (
    <main
        className="main-container focus:outline-none h-full"
        // style={{backgroundImage: 'url(/images/dashboard_background.png)'}}
    >
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 sm:px-6 flex justify-between flex-wrap ">
        <NavLink to={'/products'} className={'w-full'}>
          <img src={'/images/homepage.png'} width={'100%'} alt={""} />
        </NavLink>
      </div>
        <div className="max-w-screen-3xl mx-auto inset-0 py-6 sm:px-6 flex justify-between flex-wrap">
          <ActionButton to="/products/shop/furniture" image={'/images/furniture.png'} isDashboard />
          <ActionButton to="/products/shop/inventory" image={'/images/inventory.png'} isDashboard />
          <ActionButton to="/products/shop/light" image={'/images/light.png'} isDashboard />
          <ActionButton to="/products/shop/floor" image={'/images/floor.png'} isDashboard />
          <ActionButton to="/products/shop/wall" image={'/images/wall.png'} isDashboard />
        </div>
    </main>
    
  )
};

export default Dashboard;
