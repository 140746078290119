import { useDebounce } from '@react-hook/debounce'
import { useEffect, useState } from 'react'
import { useErrorHandler } from './useErrorHandler';

export const useAutoSave = (onSave, failureMessage = null, debouncetime = 2000) => {
  const [autoSave, setAutoSave] = useDebounce(null, debouncetime);
  const [isSaving, setIsSaving] = useState(null);

  const errorHandler = useErrorHandler();

  useEffect(() => {
    if (autoSave) {
      handleAutoSave(autoSave.saveOptions);
    }
  }, [autoSave])

  const handleAutoSave = async (saveOptions) => {
    try {
      setIsSaving(true);
      await onSave(saveOptions);
    }
    catch (error) {
      if (failureMessage || saveOptions?.showValidationErrors) {
        errorHandler.handleApiError(error, failureMessage, saveOptions?.showValidationErrors);
      }
    }
    finally {
      setIsSaving(false)
    }
  }

  return {
    save: (saveOptions) => {
      setAutoSave({ someDate: new Date(), saveOptions });
    }, // set an arbitrary changed value to trigger debounce effect
    isSaving,
  }
}