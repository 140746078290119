import React, { useState, Fragment, useEffect, useContext } from 'react';
import { resources, translations } from '../../../translations/translationsConstants';
import { useTranslation } from 'react-i18next';
import { useToastAction } from '../../../hooks/useToastAction';
import { getProductConfigurationModule, getProducts, updateProductConfigurationModule } from '../../../api/simaProductsConfiguration';
import { SimaProductModuleConfigContext } from '../../../contexts';
import WallForm from './Forms/WallForm';
import DoorForm from './Forms/DoorForm';
import { doorDirectionNames, doorThresholdTypes, productModuleTypes } from '../../../utils/constants';
import CommonForm from './Forms/CommonForm';
import ProductModulePropertyForm from './Forms/CommonForm/ProductModulePropertyForm';
import ProductPropertyOptions from './Forms/CommonForm/ProductPropertyOptions';

const ConfigModuleDetails = () => {
  const { t: translateProducts } = useTranslation([resources.simaProducts]);
  const { t: translateCommon } = useTranslation([resources.common]);
  const [avaialableProducts, setAvaialableProducts] = useState([]);
  const getAction = useToastAction();
  const updateAction = useToastAction();
  const getProductsAction = useToastAction();
  const { selectedConfigModule, onModuleNameChanged } = useContext(SimaProductModuleConfigContext);
  const { t } = useTranslation([resources.internal]);


  const updateSelectedModule = (configModule, actions) => {
    updateAction.execute(async () => {
      await updateProductConfigurationModule(selectedConfigModule.id, configModule);
      onModuleNameChanged(selectedConfigModule.id, configModule.name);
      if (actions) {
        actions.setSubmitting(false);
      }
    }, `${t('failedToUpdateConfigModule')}`);
  }

  const handleNameChange = (name) => {
    updateSelectedModule({ ...selectedConfigModule, name: name })
  }

  const loadAvailableProducts = () => {
    getProductsAction.execute(async () => {
      var products = await getProducts("");
      setAvaialableProducts(products);
    }, `${t('failedtoLoadProducts')}`);
  }
  useEffect(() => {
    loadAvailableProducts();
  }, [])


  if (!selectedConfigModule) {
    return <div />;
  }

  switch (selectedConfigModule.type) {
    case productModuleTypes.standardWidthGlassWall:
      return <WallForm configModule={selectedConfigModule}
        avaialableProducts={avaialableProducts}
        onNameChange={handleNameChange}
        onUpdate={(wall, actions) => { updateSelectedModule({ ...selectedConfigModule, wall: wall }, actions) }}
        fields={[
          {
            name: translations.simaProducts.configModulesForm.height,
            current: true,
            fieldName: 'height',
            useProductModuleRef: false,
          },
          {
            name: translations.simaProducts.configModulesForm.width,
            current: false,
            fieldName: 'width',
            useProductModuleRef: false,
          }
        ]}

      />;
    case productModuleTypes.customWidthGlassWall:
      return <WallForm configModule={selectedConfigModule}
        avaialableProducts={avaialableProducts}
        onNameChange={handleNameChange}
        onUpdate={(wall, actions) => { updateSelectedModule({ ...selectedConfigModule, wall: wall }, actions) }}
        fields={[
          {
            name: translations.simaProducts.configModulesForm.height,
            current: true,
            fieldName: 'height',
            useProductModuleRef: false,
          },
          {
            name: translations.simaProducts.configModulesForm.width,
            current: false,
            fieldName: 'width',
            useProductModuleRef: true,
          }
        ]}

      />;
    case productModuleTypes.glassDoor:
      return <DoorForm configModule={selectedConfigModule}
        onUpdate={(door, actions) => {
          updateSelectedModule({ ...selectedConfigModule, door: door }, actions)
        }}
        onNameChange={handleNameChange}
        avaialableProducts={avaialableProducts}
        fields={
          [{
            name: translations.simaProducts.configModulesForm.height,
            fieldName: 'height',
          },
          {
            name: translations.simaProducts.configModulesForm.width,
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.widthNoRef,
            fieldName: 'width',
          },
          {
            name: translations.simaProducts.configModulesForm.door.hingeSide,
            fieldName: 'doorDirection',
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.config,
            values: [...Object.keys(doorDirectionNames).map(k => ({ name: doorDirectionNames[k], value: k }))
            ]
          },
          {
            name: translations.simaProducts.configModulesForm.door.doorThreshold,
            fieldName: 'doorThreshold',
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.config,
            values: [
              {
                name: "Ingen",
                value: doorThresholdTypes.none
              },
              {
                name: "Limeterskel",
                value: doorThresholdTypes.normal
              },
              {
                name: "Hev/senk",
                value: doorThresholdTypes.dropdown
              }
            ]
          },
          {
            name: 'Door variants',
            fieldName: 'doors',
            configurator: ProductModulePropertyForm.configurators.doors
          }
          ]
        }
      />;
    case productModuleTypes.solidDoor:
      return <DoorForm configModule={selectedConfigModule}
        onUpdate={(door, actions) => {
          updateSelectedModule({ ...selectedConfigModule, door: door }, actions)
        }}
        onNameChange={handleNameChange}
        avaialableProducts={avaialableProducts}
        fields={
          [{
            name: translations.simaProducts.configModulesForm.height,
            fieldName: 'height',
          },
          {
            name: translations.simaProducts.configModulesForm.width,
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.widthNoRef,
            fieldName: 'width',
          },
          {
            name: translations.simaProducts.configModulesForm.door.hingeSide,
            fieldName: 'doorDirection',
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.config,
            values: [...Object.keys(doorDirectionNames).map(k => ({ name: doorDirectionNames[k], value: k }))
            ]
          },
          {
            name: translations.simaProducts.configModulesForm.door.doorThreshold,
            fieldName: 'doorThreshold',
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.config,
            values: [
              {
                name: "Ingen",
                value: doorThresholdTypes.none
              },
              {
                name: "Limeterskel",
                value: doorThresholdTypes.normal
              },
              {
                name: "Hev/senk",
                value: doorThresholdTypes.dropdown
              }
            ]
          },
          {
            name: 'Door variants',
            fieldName: 'doors',
            configurator: ProductModulePropertyForm.configurators.solidDoors,
          }
          ]
        }
      />;
    case productModuleTypes.glassTape:
      return <CommonForm configModule={selectedConfigModule} propName={"glassTape"}
        onUpdate={(glassTape, actions) => { updateSelectedModule({ ...selectedConfigModule, glassTape: glassTape }, actions) }}
        avaialableProducts={avaialableProducts}
        onNameChange={handleNameChange}
        fields={
          [{
            name: translations.simaProducts.configModulesForm.color,
            current: true,
            fieldName: 'color',
          }]
        }
      />;
    case productModuleTypes.glassWallFrame:
      return <CommonForm configModule={selectedConfigModule}
        propName={"frame"}
        avaialableProducts={avaialableProducts}
        onUpdate={(frame, actions) => { updateSelectedModule({ ...selectedConfigModule, frame: frame }, actions) }}
        onNameChange={handleNameChange}
        fields={
          [{
            name: translations.simaProducts.configModulesForm.color,
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.frameColor,
            fieldName: 'color',
          },
          {
            name: translations.simaProducts.configModulesForm.frame.rubberStrip,
            fieldName: 'rubberStrip',
          },
          {
            name: translations.simaProducts.configModulesForm.frame.frameProfiles,
            fieldName: 'frameProfiles',
            configurator: ProductModulePropertyForm.configurators.frameProfiles
          },
          ]
        }
      />
    case productModuleTypes.dryWallPartitionMount:
      return <CommonForm configModule={selectedConfigModule}
        propName={"drywallPartitionMount"}
        avaialableProducts={avaialableProducts}
        onUpdate={(mount, actions) => { updateSelectedModule({ ...selectedConfigModule, drywallPartitionMount: mount }, actions) }}
        onNameChange={handleNameChange}

        fields={
          [{
            name: translations.simaProducts.configModulesForm.color,
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.frameColor,
            fieldName: 'color',
          },
          {
            name: translations.simaProducts.configModulesForm.width,
            propertyOptionsType: ProductPropertyOptions.propertyOptionTypes.widthNoRef,
            fieldName: 'width',
            includeMetaData: true,
          },
          {
            name: translations.simaProducts.configModulesForm.mounts,
            configurator: ProductModulePropertyForm.configurators.dryWallMounts,
            useAsWidthMap: true,
            fieldName: 'mounts',
            metaDataMap: (mount) => {
              return { walloverlap: '' + mount.wallOverlap };
            }
          },
          ]
        } />
    default:
      return <div>Configuration Module missing</div>
  }
};

export default ConfigModuleDetails;