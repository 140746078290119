import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const PageHeader = ({title, actions, children}) => {
  const { t } = useTranslation([resources.pageHeader]);

  const buttonClassFromAction = (action) => {
    switch(action.type) {
      case 'secondary':
        return 'mt-3 rounded bg-white py-2 px-4 text-sm font-semibold text-brand-black border-brand-green-dark border';
      default:
      case 'primary':
        return 'mt-3 rounded shadow-sm px-4 py-2 bg-brand-green-light font-medium text-sm text-brand-black border-brand-green-dark border';
    }
  }

  return (
    <>
      <div className="border-b border-brand-green-dark pb-5 mt-4 mb-16">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <div className="sm:w-0 sm:flex-1 flex items-center gap-8">
            <h1 id="message-heading" className="text-3xl font-semibold leading-6 text-gray-900">
              {title}
            </h1>
            {children}  
          </div>

          <div className='flex gap-2'>
          {
            actions?.filter(m => !m.disabled).map((action, index) => (
              action.onClick ?
                <button
                  type="button"
                  className={buttonClassFromAction(action)}
                  onClick={action.onClick}
                >
                  {action.label}
                </button>
              :
                <NavLink
                  to={action.to || '/'}
                  className={buttonClassFromAction(action)}
                >
                  {action.label}
                </NavLink>
            ))
          }
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
