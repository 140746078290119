import React, {useState} from 'react';
// import { SimaAdminContext } from '../../../contexts';
import CustomersList from './CustomersList';
import CustomerManagement from './CustomerManagement';

const SimaCustomers = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
      <main
        className="main-container focus:outline-none h-full mt-5"
        >
          <div className="max-w-screen-3xl mx-auto px-2 sm:px-4 lg:px-8">
          {
            selectedCustomer ? (
              <CustomerManagement onClearSelected={() => { setSelectedCustomer(null) }} selectedCustomer={selectedCustomer} />
            ) : (
              <CustomersList onSelectCustomer={(customer) => setSelectedCustomer(customer)}/>
            )
          }
        </div>
      </main>
  );
};

export default SimaCustomers;