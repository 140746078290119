import { useClassNames } from '../../hooks/useClassNames';

const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';

export default function FileInput({
  name,
  label,
  onChange,
  placeholder = null,
  required = false,
  disabled = false,
  readOnly = false,
  accept
}) {
  const classNames = useClassNames();

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}
      <div className={label ? 'mt-1' : ''}>
        <input
          type="file"
          className={classNames.classNames(
            disabled || readOnly ? 'bg-gray-100 cursor-not-allowed' : '',
            'block w-full sm:text-sm',
          )}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.files[0])
          }}
          accept={accept}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
