import React from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchQuery = () => {
  const { search } = useLocation();

  const hasParam = (name) => {
    return new URLSearchParams(search).has(name);
  };

  const getParam = (name) => {
    return new URLSearchParams(search).get(name);
  };

  return {
    hasParam,
    getParam
  };
};
