import React, { useState, Fragment, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { resources, translations } from '../../../translations/translationsConstants';
import { useTranslation } from 'react-i18next';
import { useToastAction } from '../../../hooks/useToastAction';
import { useAutoSave } from '../../../hooks/useAutoSave';
import { getProducts } from '../../../api/simaProductsConfiguration';
import { TextInput } from '../../../components/Form/TextInput';
import axios from 'axios';
import { getProductConfigurationModules as getConfigModules } from '../../../api/simaProductsConfiguration';
import { Table as TableCore, useTableActions } from '@metaforcelabs/metaforce-core';
import { ModuleTypesNames } from './ModuleTypes';
import { SimaProductModuleConfigContext } from '../../../contexts';
import { useClassNames } from '../../../hooks/useClassNames';
import { productModuleTypes } from '../../../utils/constants';

const ConfigModulesList = () => {
  const { t: translateProducts } = useTranslation([resources.simaProducts]);
  const { t: translateCommon } = useTranslation([resources.common]);
  const [searchValue, setSearchValue] = useState('')
  const { selectedConfigModuleId, onSelectedConfigModuleId, configModulesToShow,onSearch } = useContext(SimaProductModuleConfigContext);
  const [cancelToken, setCancelToken] = useState(undefined);
  const { t } = useTranslation([resources.internal]);

  const tableActions = useTableActions(configModulesToShow, 10);
  const { classNames } = useClassNames();

  const filterModules = () => {
    onSearch(searchValue);
  };

  const debouncedOnQueryKeyDown = useAutoSave(filterModules, `${t('failedToFilterConfigModules')}`,500);
  const executeFilter = (value) => {
    setSearchValue(value);
    debouncedOnQueryKeyDown.save();
  }

  useEffect(() => {
    filterModules();
  }, []);

  return (
    <>
      <TextInput
        name={"name"}
        horizontal={true}
        value={searchValue}
        placeholder={translateCommon(translations.common.search)}
        type={'text'}
        onChange={executeFilter}
      />
      <Table>
        <TableHead>
          <TableHeader>{translateProducts(translations.simaProducts.name)}</TableHeader>
          <TableHeader>{translateProducts(translations.simaProducts.type)}</TableHeader>
        </TableHead>
        <TableBody>
          {tableActions.pageData?.map((row, index) => (
            <Fragment key={index}>
              <TableRow className={classNames(
                'cursor-pointer',
                index % 2 === 0 ? undefined : 'bg-gray-50',
                row.id === selectedConfigModuleId ? ' bg-indigo-50' : undefined
              )}
                onClick={() => onSelectedConfigModuleId(row.id)}
              >
                <TableColumn>
                  <button
                    className="font-medium text-sm text-brand-black hover:text-indigo-500 border-0 shadow-none"
                  >
                    {row.name}
                  </button>
                </TableColumn>
                <TableColumn>
                  {translateProducts(ModuleTypesNames[row.type])}
                </TableColumn>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TableCore.Pagination tableActions={tableActions} />
    </>
  );
};

export default ConfigModulesList;