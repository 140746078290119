import { useClassNames } from "@metaforcelabs/metaforce-core";

export const Table = ({ children, pagination: Pagination, ...props }) => {
  return (
    <div className="flex flex-col" {...props}>
      <div className="py-2 overflow-x-auto align-middle inline-block min-w-full">
        <div className="shadow-sm border border-brand-green-dark sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">{children}</table>
          {
            Pagination && <Pagination />
          }
        </div>
      </div>
    </div>
  );
};

export const TableHead = ({ children, ...props }) => {
  return (
    <thead className="bg-gray-50" {...props}>
      <tr>{children}</tr>
    </thead>
  );
};

export const TableHeader = ({ children, sortBy, tableSort, additionalClass, size = 'default', textRight = false, ...props }) => {
  const { classNames } = useClassNames();
  const sortProps = {};
  const sortEnabled = sortBy && tableSort;
  if (sortEnabled) {
    sortProps.onClick = () => tableSort.orderBy(sortBy);
  }
  const sizeVariant = {
    "default": 'px-6 py-3',
    "small": 'px-4 py-3'
  }
  return (
    <th
      scope="col"
      className={classNames(
        `${sizeVariant[size]} text-xs font-medium text-gray-500 uppercase tracking-wider ${sortEnabled ? 'cursor-pointer' : ''
        } ${additionalClass}`,
        textRight ? 'text-right' : 'text-left'
      )}
      {...sortProps}
      {...props}
    >
      {children}
      {sortEnabled && tableSort.getSortIcon(sortBy)}
    </th>
  );
};

export const TableBody = ({ children, ...props }) => {
  return (
    <tbody className="bg-white divide-y divide-gray-200" {...props}>
      {children}
    </tbody>
  );
};

export const TableRow = ({ children, ...props }) => {
  return <tr {...props}>{children}</tr>;
};

export const TableColumn = ({ children, size = 'default', textRight = false, ...props }) => {
  const { classNames } = useClassNames();
  const sizeVariant = {
    "default": `px-6 py-4`,
    "small": 'px-4 py-2'
  }
  return (
    <td className={classNames(
      `${sizeVariant[size]} whitespace-nowrap text-sm text-gray-500`,
      textRight ? 'text-right' : 'text-left'
    )} {...props}>
      {children}
    </td>
  );
};

export const TableActions = ({ buttons = [] }) => {
  return (
    <div className="mt-5 pb-5">
      {buttons?.map((b, index) => {
        return (
          <button
            key={`table-action-${index}`}
            type="button"
            className="mt-3 mr-2 w-auto inline-flex justify-center rounded-md border border-brand-green-dark shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => b.onClick()}
          >
            {b.text}
          </button>
        );
      })}
    </div>
  );
};
