import { ArrowRightIcon, PlusIcon } from "@heroicons/react/solid";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { NavLink } from "react-router-dom";

const SIZES = {
  default: 'default',
  small: 'small'
}

const ActionButton = ({ title, iconBackground, iconForeground, to, description, image, onClick, maxWidth = '260px', size = 'default', buttonIcon: ButtonIcon = null , disabled = false, isDashboard = false}) => {
  const { classNames } = useClassNames();


  const styles = {
    maxWidth: size === SIZES.default ? maxWidth : size === SIZES.small ? '180px' : maxWidth,
    imgHeightWidth: size === SIZES.default ? 200 : size === SIZES.small ? 120 : 200,
    titleHeight: '43px',
    containerWidth: '260px',
    containerHeight: '282.06px',
    height:'220px'
  }

  if (isDashboard) {
    return (
      <NavLink to={to}>
        <img src={image} className="h-full" width={'280px'} alt={title} />
      </NavLink>

    )
  }

  return (
    <div
      key={title}
      style={{ maxWidth: styles.maxWidth }}
      className={classNames(
          'max-w-xs flex flex-col justify-between rounded border border-brand-green-dark relative bg-white p-6 hover:shadow-sm',
          disabled ? 'opacity-50 cursor-not-allowed' : 'transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-103'
        )
      }
    >
      <div>
        <span
          className={classNames(
            iconBackground,
            iconForeground,
            'rounded-lg inline-flex p-3 ring-4 ring-white'
          )}
        >
          <img src={image} className="p-6" height={styles.imgHeightWidth} width={styles.imgHeightWidth} alt={title} />
        </span>
      </div>
      <div className={classNames(size === SIZES.default ? "mt-8" : size === SIZES.small ? "mt-3" : "mt-8")}>
        <h3 className="text-md">
          {
            onClick && !disabled ?
              <button
                type="button"
                className="focus:outline-none cursor-pointer"
                onClick={onClick}
              >
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {title}
              </button>
              :
              to && !disabled ?
                <NavLink
                  to={to}
                  className="focus:outline-none cursor-pointer"
                >
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {title}
                </NavLink>
                :
                <>
                  <span className="absolute inset-0" aria-hidden="true" />
                  {title}
                </>

          }
        </h3>
        <p className="mt-2 text-sm text-gray-500">{description}</p>
      </div>
      <div>
        <button
          type="button"
          className={
            classNames(
              size === SIZES.default ? "mt-4" : size === SIZES.small ? "mt-2" : "mt-4", 
              ButtonIcon ? "bg-black" : "",
              "text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-black"
            )}
        >
          {
            ButtonIcon ? <ButtonIcon className="h-4 w-4" aria-hidden="true" /> : <img className="h-7 w-7" src="/images/action_button_arrow.png" alt="arrow-right" />
            // <ArrowRightIcon className="h-4 w-4 " aria-hidden="true" />
          }
        </button>
      </div>
    </div>
  );
};

export default ActionButton;