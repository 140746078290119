import { isDate } from "moment";

export const stringToLocaleDateTimeString = (val) => {
  if (!val) {
    return;
  }

  return new Date(val).toLocaleString();
}

export const valueIsDate = (val) => {

  return isDate(val);
}