import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getTeamUser,
  getUserGroups,
  getUserRoles,
  getUserStatuses,
  updateTeamUser
} from '../../api/teams';
import toast from "react-hot-toast";
import { useToastAction } from '../../hooks/useToastAction';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const actionResultType = {
  success: 0,
  failure: 1,
  warning: 2
};

const userStatusOptions = [
  { id: 10, name: 'Active' },
  { id: 90, name: 'Suspended' },
]

const ManageTeamUser = () => {
  const returnPath = '/admin';
  const history = useHistory();
  const { userId } = useParams();
  const [userRoles, setUserRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [user, setUser] = useState({});
  const [userStatuses, setUserStatuses] = useState([]);
  const { t } = useTranslation([resources.admin]);

  const loadDataAction = useToastAction();

  const loadData = async () => {
    loadDataAction.execute(async () => {
      const [user, roles, groups] = await Promise.all([
        getTeamUser(userId), getUserRoles(), getUserGroups(), getUserStatuses()
      ]);
      
      setUser(user);
      setUserRoles(roles);
      setUserGroups(groups);
      // setUserStatuses(statuses);
    }, `${t('failedToLoadUser')}`)
  };
  useEffect(() => {
    loadData();
  }, [userId]);

  const handleChange = ({ name, value }) => {
    setUser((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const saveUser = async () => {
    try {
      const resultSave = await updateTeamUser(userId, {
        roleId: user.roleId,
        statusId: user.statusId
      });

      if (resultSave.type === actionResultType.success) {
        history.push(returnPath);
      } else {
        toast.error(
          `${t('licenseLimited')}`
        );
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error(`${t('failedToEditUser')}`);
      }
    }
  };
  return (
    <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full">
            <PageHeader title={t('editUser')} />
              
              <div className="grid grid-cols-1 gap-y-4">
                {user && (
                  <>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {t('name')}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="name"
                          value={user.name}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {t('role')}
                      </label>
                      <div className="mt-2">
                        <select
                          name="roleId"
                          value={user.roleId}
                          onChange={(e) => handleChange(e.target)}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                        >
                          {userRoles.map((r) => {
                            return <option value={r.roleId}>{r.roleName}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {t('status')}
                      </label>
                      <div className="mt-2">
                        <select
                          name="statusId"
                          value={user.statusId}
                          onChange={(e) => handleChange(e.target)}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                        >
                          {userStatusOptions.map((s) => {
                            return <option value={s.id}>{s.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8">
                      <button
                        type="button"
                        onClick={(e) => {
                          history.push(returnPath);
                        }}
                        className="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-600 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                      >
                        {t('cancel')}
                      </button>
                      <button
                        type="button"
                        onClick={saveUser}
                        className="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent rounded-md border border-transparent bg-brand-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span>{t('save')}</span>
                      </button>
                    </div>
                  </>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ManageTeamUser;