export default function InputText({ label = "", placeholder = "", value = "", setValue, disabled = false, fullWidth = false, className, onChange = null, rows = 1}) {
  const handleOnChange = (e) => {
    if(onChange) 
      onChange(e);
    else 
      setValue(e.target.value);
  }

  return (
    <div>
      <p className="text-sm mb-1 mt-3">{label}</p>
      {
        rows <= 1 ? (
          <input className={`${disabled && 'bg-brand-input-disabled'} text-sm ${fullWidth ? 'w-full' : ''} ${className}`} type="text" placeholder={placeholder} value={value} onChange={handleOnChange} disabled={disabled} />
        ) : (
          <textarea className={`${disabled && 'bg-brand-input-disabled'} text-sm ${fullWidth ? 'w-full' : ''} ${className}`} placeholder={placeholder} value={value} onChange={handleOnChange} disabled={disabled} />
        )
      }
      
    </div>
  );
}
