import React, { useContext, useState } from 'react';
import { ManageTeamContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InvitesTable({ invites, onToggleInvite, selectedInvites,onToggleAllInvites }) {
    const manageTeamContext = useContext(ManageTeamContext);
    const { t } = useTranslation([resources.admin]);

    const InviteRow = ({ invite }) => {
        return (
            <tr className={classNames(
                invite.acceptedDate === null ? ' hover:bg-gray-200 cursor-pointer' : '',
                'odd:bg-white even:bg-gray-100')}
                onClick={e => invite.acceptedDate === null && onToggleInvite(invite.email)}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {
                        invite.acceptedDate === null && (
                            <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            checked={selectedInvites.findIndex(i => i === invite.email) !== -1}
                            className="focus:ring-indigo-500 h-4 w-4 text-brand-pink border-brand-green-dark rounded cursor-pointer"
                        />
                        )
                    }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {
                        invite.acceptedDate === null ? (
                            <p>{t('pending')}</p>
                        ) : 
                        (
                            <p>{t('user')}</p>
                        )
                    }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {invite.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {
                        new Intl.DateTimeFormat('en-GB', {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date(invite.invitedDate))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {
                     invite.acceptedDate !== null && (
                        new Intl.DateTimeFormat('en-GB', {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date(invite.acceptedDate))
                        )
                }
                </td>                
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {manageTeamContext?.roles.find(r => r.roleId === invite.customerUserRoleId)?.roleName}
                </td>
            </tr>)
    }
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-brand-green-dark sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <input
                                            id="comments"
                                            aria-describedby="comments-description"
                                            name="comments"
                                            type="checkbox"
                                            onClick={e=> onToggleAllInvites()}
                                            checked={invites.filter((f) => {return !f.acceptedDate }).length > 0 && selectedInvites.length === invites.filter((f) => {return !f.acceptedDate }).length }
                                            className="focus:ring-indigo-500 h-4 w-4 text-brand-pink border-brand-green-dark rounded cursor-pointer"
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('status')}
                                    </th>                                    
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('Email')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('invited')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('accepted')}
                                    </th>                                    
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('role')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invites.map(invite => {
                                        return (
                                            <InviteRow invite={invite} key={invite.id} />
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
