import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslationCommon from './en/common.json';
import enAudit from './en/audit.json';
import enSimaAdmin from './en/simaAdmin.json';
import enSimaProducts from './en/simaProducts.json';
import enCart from './en/cart.json';
import enOrders from './en/orders.json';
import enGlasswall from './en/glasswall.json';
import enDashboard from './en/dashboard.json';
import enProjects from './en/projects.json';
import enProjectItem from './en/projectItem.json';
import enPageHeader from './en/pageHeader.json';
import enAccountSettings from './en/accountSettings.json';
import enAdmin from './en/admin.json';
import enInternal from './en/internal.json';
import enCompany from './en/company.json';

import noTranslationCommon from './no/common.json';
import noAudit from './no/audit.json';
import noSimaAdmin from './no/simaAdmin.json';
import noCart from './no/cart.json';
import noOrders from './no/orders.json'
import noGlasswall from './no/glasswall.json';
import noDashboard from './no/dashboard.json';
import noProjects from './no/projects.json';
import noProjectItem from './no/projectItem.json';
import noSimaProducts from './no/simaProducts.json';
import noPageHeader from './no/pageHeader.json';
import noAccountSettings from './no/accountSettings.json';
import noAdmin from './no/admin.json';
import noInternal from './no/internal.json';
import noCompany from './no/company.json';

import plTranslationCommon from './pl/common.json';
import plAudit from './pl/audit.json';
import plSimaAdmin from './pl/simaAdmin.json';
import plCart from './pl/cart.json';
import plOrders from './pl/orders.json';
import plGlasswall from './pl/glasswall.json';
import plDashboard from './pl/dashboard.json';
import plProjects from './pl/projects.json';
import plProjectItem from './pl/projectItem.json';
import plSimaProducts from './pl/simaProducts.json';
import plPageHeader from './pl/pageHeader.json';
import plAccountSettings from './pl/accountSettings.json';
import plAdmin from './pl/admin.json';
import plInternal from './pl/internal.json';
import plCompany from './pl/company.json'

import { resources as keyNames } from './translationsConstants';

const resources = {
    en: {
        [keyNames.common]: enTranslationCommon,
        [keyNames.audit]: enAudit,
        [keyNames.simaAdmin]: enSimaAdmin,
        [keyNames.cart]: enCart,
        [keyNames.orders]: enOrders,
        [keyNames.glasswall]: enGlasswall,
        [keyNames.dashboard]: enDashboard,
        [keyNames.projects]: enProjects,
        [keyNames.projectItem]: enProjectItem,
        [keyNames.simaProducts]: enSimaProducts,
        [keyNames.pageHeader]: enPageHeader,
        [keyNames.accountSettings]: enAccountSettings,
        [keyNames.admin]: enAdmin,
        [keyNames.internal]: enInternal,
        [keyNames.company]: enCompany
    },
    nb: {
        [keyNames.common]: noTranslationCommon,
        [keyNames.audit]: noAudit,
        [keyNames.simaAdmin]: noSimaAdmin,
        [keyNames.cart]: noCart,
        [keyNames.orders]: noOrders,
        [keyNames.glasswall]: noGlasswall,
        [keyNames.dashboard]: noDashboard,
        [keyNames.projects]: noProjects,
        [keyNames.projectItem]: noProjectItem,
        [keyNames.simaProducts]: noSimaProducts,
        [keyNames.pageHeader]: noPageHeader,
        [keyNames.accountSettings]: noAccountSettings,
        [keyNames.admin]: noAdmin,
        [keyNames.internal]: noInternal,
        [keyNames.company]: noCompany
    },
    sv: {
        [keyNames.common]: plTranslationCommon,
        [keyNames.audit]: plAudit,
        [keyNames.simaAdmin]: plSimaAdmin,
        [keyNames.cart]: plCart,
        [keyNames.orders]: plOrders,
        [keyNames.glasswall]: plGlasswall,
        [keyNames.dashboard]: plDashboard,
        [keyNames.projects]: plProjects,
        [keyNames.projectItem]: plProjectItem,
        [keyNames.simaProducts]: plSimaProducts,
        [keyNames.pageHeader]: plPageHeader,
        [keyNames.accountSettings]: plAccountSettings,
        [keyNames.admin]: plAdmin,
        [keyNames.internal]: plInternal,
        [keyNames.company]: plCompany
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        lng: 'en'
    })

export default i18n;