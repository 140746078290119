
import React, { useState } from "react";
import { Button, Modal } from "../../../../components";
import { Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../../components/Table";
import { EyeIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { Form, Formik } from "formik";
import { FormikSelectInput } from "../../../../components/Form/Formik/FormikSelect";
import { ProductModuleType } from "../../ProductModuleTypes";
import { resources, translations } from "../../../../translations/translationsConstants";
import { useTranslation } from "react-i18next";
import { FormikSubmitButton } from "../../../../components/Form/Formik/FormikSubmit";
import FormikInput from "../../../../components/Form/Formik/FormikInput";
import Doors from "./doors";
import { frameColorNames, frameProfileTypeNames } from "../../../../utils/constants";
import { Badge } from "@metaforcelabs/metaforce-core";
import { v4 as uuidv4 } from 'uuid';

export default function FrameProfiles({ frameProfiles, avaialableProducts, onUpdate, isSolidDoors }) {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [activeFrame, setActiveFrame] = useState()


    const handleConfirmDelete = () => {
        const idx = frameProfiles.findIndex(x => x.id == activeFrame?.id);
        if (idx === -1) return;
        frameProfiles.splice(idx, 1);
        onUpdate(frameProfiles, () => {
            setShowConfirmModal(false)
            setActiveFrame(null);
        });
    }

    const handleUpdate = (values, { setSubmitting }) => {
        const product = avaialableProducts.find(x => x.id === values.productModuleRef)
        values = { ...values, length: product?.metaData?.length || 2900 };
        const idx = frameProfiles.findIndex(x => x.id == values.id);
        if (idx === -1) return;
        frameProfiles[idx] = values;
        onUpdate(frameProfiles, () => {
            setShowUpdateModal(false);
            setSubmitting(false);
        });
    }

    const handleAddNew = (values, { setSubmitting }) => {
        const product = avaialableProducts.find(x => x.id === values.productModuleRef)
        frameProfiles.push({ ...values, id: uuidv4(), length: product?.metaData?.length || 2900 });
        onUpdate(frameProfiles, () => {
            setShowFormModal(false);
            setSubmitting(false);
        })
    }

    return (
        <>
            <div className="flex justify-end justify-between items-center">
                <h1 className="text-sm font-bold">Configured Frameprofiles</h1>
                <Button variant={Button.variants.secondary} onClick={() => {
                    setShowFormModal(true);
                }}>Add new</Button>
            </div>
            <Table>
                <TableHead>
                    <TableHeader>Profil</TableHeader>
                    <TableHeader>Farges</TableHeader>
                    <TableHeader>Produkt</TableHeader>
                    {/* <TableHeader>Product Id</TableHeader> */}
                    <TableHeader textRight={true}>Actions</TableHeader>
                </TableHead>
                <TableBody>
                    {frameProfiles.map((profile, index) => {
                        const product = avaialableProducts.find(x => x.id === profile.productModuleRef);
                        return (
                            <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                <TableColumn size="small">
                                    <span className="ml-2">{[frameProfileTypeNames[profile?.frameProfileType]]}</span>
                                </TableColumn>
                                <TableColumn size="small">
                                    <span className="ml-2">{frameColorNames[profile?.colorValue]}</span>
                                </TableColumn>
                                <TableColumn size="small">
                                    <span className="ml-2">{product ? product?.name : <Badge type="warn" text={"Product missing"} />}</span>
                                </TableColumn>
                                {/* <TableColumn size="small">
                                    {product?.productId}
                                </TableColumn> */}
                                <TableColumn size="small" textRight={true}>
                                    <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                        <PencilAltIcon aria-hidden="true" onClick={() => {
                                            setActiveFrame(profile);
                                            setShowUpdateModal(true);
                                        }} />
                                    </button>
                                    <button className="hover:text-red-600 h-6 w-6" type="button">
                                        <TrashIcon aria-hidden="true" onClick={() => {
                                            setShowConfirmModal(true);
                                            setActiveFrame(profile);
                                        }} />
                                    </button>
                                </TableColumn>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <ConfirmationModal
                open={showConfirmModal}
                setOpen={() => setShowConfirmModal(false)}
                type="error"
                handleOnConfirm={handleConfirmDelete}
                description="Do you want to remove selected frame? This operation is irreversible."
                confirmButtonText="Remove"
                title="Are you sure?"
            />

            <Modal
                isOpen={showUpdateModal}
                onClose={() => { setShowUpdateModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <FrameProfilesForm initialValues={activeFrame} onClose={() => setShowUpdateModal(false)} onSubmit={handleUpdate} avaialableProducts={avaialableProducts} isSolidDoors={isSolidDoors} />
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showFormModal}
                onClose={() => { setShowFormModal(false); }}
                size={'large'}
                title={`Property option details`}
            >
                <div className='space-y-4'>
                    <div className='grid grid-cols-1 gap-x-2'>
                        <FrameProfilesForm initialValues={{
                            name: '',
                            doorDirection: Doors.doorDirections.right,
                            color: 'black',
                            width: 900,

                        }} onClose={() => setShowFormModal(false)} onSubmit={handleAddNew} avaialableProducts={avaialableProducts} />
                    </div>
                </div>
            </Modal>

        </>
    );
};

const FrameProfilesForm = ({ initialValues, onSubmit, onClose, avaialableProducts = [] }) => {

    const { t: translateCommon } = useTranslation([resources.common]);

    const doorProducts = avaialableProducts.filter(x => true).map(x => {
        return { name: x.name, value: x.id }
    });

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {(props) => (
            <Form className='space-y-4'>
                <div className="grid grid-cols-4 mb-4 gap-4">
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Color"}
                            name={`colorValue`}
                            formikProps={props}
                            options={[
                                ...Object.keys(frameColorNames).map(k => ({ value: k, name: frameColorNames[k] }))
                            ]}
                            showEmptyOption={!props.values.colorValue}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Frame Profile Type"}
                            name={`frameProfileType`}
                            formikProps={props}
                            valueIsNumber={true}
                            options={[
                                ...Object.keys(frameProfileTypeNames).map(k => ({ value: k, name: frameProfileTypeNames[k] }))
                            ]}
                            showEmptyOption={!props.values.frameProfileType}
                        />
                    </div>
                    <div className="col-span-2">
                        <FormikSelectInput
                            label={"Produkt Modul"}
                            name={`productModuleRef`}
                            formikProps={props}

                            options={[{ name: '', value: null },
                            ...doorProducts
                            ]}
                            showEmptyOption={props.values.hingeModuleRef === ''}
                        />
                    </div>

                </div>
                <div className="flex justify-end mt-3 space-x-2">
                    <Button variant={Button.variants.secondary} onClick={() => onClose()}>Close</Button>
                    <FormikSubmitButton
                        text={translateCommon(translations.common.save)}
                        formikProps={props}
                    />
                </div>
            </Form>
        )}
    </Formik>
}