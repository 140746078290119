import { ArrowRightIcon, PlusIcon } from "@heroicons/react/solid";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { NavLink } from "react-router-dom";

const SIZES = {
  default: 'default',
  small: 'small'
}

const ContentCategoryCard = ({ title, image, to}) => {
  const { classNames } = useClassNames();



  return (
    <NavLink to={to} className="focus:outline-none cursor-pointer">
      <div key={title} className="bg-white relative border border-brand-green-dark">
        <img src={image || 'https://fakeimg.pl/600x400/cccccc/cccccc' } className="w-72 h-72" alt={title} />
        <div className="bg-brand-gray-light text-center py-8 px-10">
          {title}
        </div>
      </div>
    </NavLink>
  );
};

export default ContentCategoryCard;