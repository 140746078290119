import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components'
import { OidcRoutesContext } from '../../contexts'
import { changeCompany } from '../../utils/oidcUtilities';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const Summary = () => {
    const { t } = useTranslation([resources.company]);
    
    const goToHomepage = () => {
        changeCompany();
    }

    return (
        <>
            <div>
                <h1 className="text-3xl text-gray-900 font-bold mb-3">Success!</h1>
                <h3 className="mt-6 text-xl text-gray-900">
                    {t('descriptionCreatedCompany')}
                </h3>
                <div className='flex mt-8 justify-start'>
                    <Button variant={Button.variants.primary}
                        onClick={goToHomepage}>
                        {t('gotoHomepage')}
                    </Button>
                </div>
            </div>
        </>
    )
};

export default Summary;
