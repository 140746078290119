import { useClassNames } from '@metaforcelabs/metaforce-core'
import React from "react"
import { useState } from "react"

export const SelectInput = ({
  name,
  label = null,
  value,
  onChange,
  type = null,
  options = [],
  disabled = false,
  required = false,
  onKeyPress = null,
  horizontal = false,
  widthClass = 'w-full',
  labelWidth = null,
  labelColor = null,
}) => {
  const [blurred, setBlurred] = useState(false)
  const classes = useClassNames()

  let hasValidationError = false

  const classNames = classes.classNames(
    `shadow-sm block ${widthClass} sm:text-sm border-brand-green-dark rounded`,
    hasValidationError
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-gray-400 focus:border-gray-400',
      disabled ? 'bg-gray-100' : '',
  )

  return (
    <>
      {
        label &&
        <div className={classes.classNames(horizontal
          ? 'flex items-center mt-1 gap-2'
          : '',
        )}>
          <label htmlFor={name} className={classes.classNames(
            horizontal ? (labelWidth ? labelWidth : 'flex-1') : 'mt-1',
            labelColor ? '' : 'text-brand-black',
            disabled ? 'opacity-50' : '',
            'block text-sm font-medium text-brand-black'
          )}>
            {label}
            {required === true && <span className="text-red-400"> *</span>}
          </label>
          <div className={classes.classNames(horizontal
            ? 'flex-1'
            : 'mt-1'
          )}>
            <select name={name}
              value={value || ''}
              className={classNames}
              onBlur={() => setBlurred(true)}
              disabled={disabled}
              onChange={evt => onChange(evt.target.value, name)}
            >
              {
                options?.map(o => {
                  return <option value={o.value}>{o.name}</option>
                })
              }
            </select>

          </div>
        </div>
      }
      {
        !label &&
        <select name={name}
          value={value || ''}
          className={classNames}
          onBlur={() => setBlurred(true)}
          disabled={disabled}
          onChange={evt => onChange(evt.target.value, name)}
        >
          {
            options?.map(o => {
              return <option value={o.value}>{o.name}</option>
            })
          }
        </select>
      }
    </>)
}