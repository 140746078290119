import { get, BaseUrl, post } from './api';

const controllerName = 'SimaAdmin';

export const getCustomers = async (filterRequest, cancelToken) => {
  return await post(`${controllerName}/customers`, filterRequest, null, BaseUrl.Api, cancelToken);
}

export const getCustomer = async (customerId) => {
  return await get(`${controllerName}/GetCustomer/${customerId}`);
};
  
export const getCountries = async () => {
  return await get(`${controllerName}/GetCountries`);
};

export const saveCustomer = async (data) => {
  return await post(`${controllerName}/SaveCustomer`, data);
};

export const getProductAreas = async (filterRequest) => {
    return await get(controllerName + `/productareas`);
}

export const getProductAreaDetails = async (productAreaId) => {
    return await get(`${controllerName}/ProductAreaDetails/${productAreaId}`);
}

export const updateProductAreaDetails = async (data) => {
  return await post(`${controllerName}/UpdateProductAreaDetails`, data);
};

export const getCompaniesSettingsApi = async () => {
  return await get(`${controllerName}/GetSettings`);
};

export const updateSettingsCompanyApprovals = async (data) => {
  return await post(`${controllerName}/UpdateSettings/CompanyApprovals`, data);
};

export const getPendingCustomersCount = async () => {
  return await get(`${controllerName}/GetPendingCustomersCount`);
}