import { get, BaseUrl } from './api'

/**
 * @returns {{
 *  activeProducts: {id: string, name: string, productCode: string}[]
 * }}
 */
export const getCustomerDetails = async () => {
  return await get(`Customer/GetCustomerDetails`);
}
/**
 * @returns {Promise.<{customerId:string,name:string,orgNumber: string,country: {id: string,code: string,name: string,currency: string,languageCode: string }}>}
 */
export const getCustomerInfo = async () => {
  return await get(`api/Customer/GetCustomer`, BaseUrl.Centerpoint);
}

export const assignCustomerToSimaPlatform = async (customerId) => {
  return await get(`Customers/AssignCustomerToSimaPlatform/${customerId}`);
}