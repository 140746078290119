import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import { getInvoices, downloadInvoice  } from '../../api/rackbeat';
import InputText from "../../components/InputText";
import {formatCurrency} from "../../utils/currency";
import { formatShortDate } from '../../hooks/useDateFormat';

const Invoices = () => {
  const { t } = useTranslation([resources.glasswall]);
  const [invoices, setInvoices] = useState([]);
  const [invoicesToShow, setInvoicesToShow] = useState([]);

  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0)

  useEffect(() => {
    loadInvoices();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [invoices, search]);

  const handleSearch = () => {
    let filteredInvoices = invoices;

    if(search && search !== '') {
      filteredInvoices = invoices?.filter((invoice) => {
        return invoice?.number?.toString().toLowerCase().includes(search.toLowerCase()) ||
          invoice?.heading?.toString().toLowerCase().includes(search.toLowerCase()) ||
          invoice?.orders?.toString().toLowerCase().includes(search.toLowerCase())
      });
    }

    setInvoicesToShow(filteredInvoices);

    setTotal(filteredInvoices.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_total;
    }, 0))
  }

  const loadInvoices = async () => {
    const response = await getInvoices();
    setInvoices(response || []);
  }

  const handleDownloadInvoice = async (invoiceNumber) => {
    try {
        await downloadInvoice(invoiceNumber);
    } catch (error) {
        console.error('Error downloading invoice:', error);
    }
};

  const styles = {
  }

  return (
    <>
    <div className={'w-full bg-brand-brown-medium px-6 border-brand-green-dark border-t border-b'}>
      <div style={{paddingLeft: styles.paddingLeft}}
           className='bg-brand-brown-medium h-48 max-w-screen-3xl mx-auto flex flex-col justify-center'>
        <p className={'font-semibold text-lg'}>INVOICES</p>
        <div className={'flex'}>
          <InputText label="Search" placeholder="Search" className={'w-96'} value={search} setValue={setSearch} />
        </div>
      </div>
    </div>
    <main className="main-container focus:outline-none h-full relative bg-brand-gray-light">
      <div className={'max-w-screen-3xl mx-auto px-6'}>
      <div style={{paddingLeft: styles.paddingLeft}} className="w-full mt-2 inset-0 py-6">
        <div className="flex flex-col pb-10 h-full">
          <div className="w-full">
            <div className="w-full h-full">
              <div className="w-full relative">
                <div className="mt-5 flex flex-cols gap-5 justify-center">
                <table className="min-w-full divide-y divide-brand-green-dark border-brand-green-dark border">
                <thead className="border-brand-green-dark">
                  <tr className=''>
                    <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
                      {t('hashtag')}
                    </th>
                    <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
                      {t('order')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold flex items-center">
                      {t('heading')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
                      {t('invoiceStatus')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
                      {t('dueDate')}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 text-base font-semibold flex items-center">
                      {t('total')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-brand-green-dark bg-white">
                  {
                    invoicesToShow && invoicesToShow.map((invoice, index) => {
                      const { number, orders, heading, due_date, total_total, currency, billing_address} = invoice
                      return (
                        <tr key={index}>
                          <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                            {number}
                          </td>
                          <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                            {orders}
                          </td>
                          <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text w-72">
                            {heading}
                          </td>
                          <td className="px-3 py-3.5 text-left text-sm font-semibold font-normal text-sm text-brand-gray-text">
                            {number}
                          </td>
                          <td className="px-3 py-3.5 text-left text-sm font-semibold font-normal text-sm text-brand-gray-text">
                            {formatShortDate(due_date)}
                          </td>
                          <td className="px-3 py-3.5 text-right text-sm font-semibold font-normal text-sm text-brand-gray-text flex items-center relative">
                            <div>
                              {total_total === 0 ? "0,00" : formatCurrency(total_total)} {currency}
                            </div>
                            <button onClick={() => handleDownloadInvoice(number)}>
                              <p className={"absolute left-44 top-3"}>{t('view')}</p>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 w-72">
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  </th>
                  <th scope="col" className="px-3 py-3.5 h-20 text-left text-sm font-semibold text-gray-900 font-semibold">
                    Total
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 font-semibold">
                    {formatCurrency(total)} NOK
                  </th>
                </tbody>
              </table>
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
      </div>
    </main>
    </>

  )
};

export default Invoices;
