import { useContext } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import { OidcRoutesContext } from '../../contexts';
import { assignCustomerToSimaPlatform } from '../../api/customer';
import ActionButton from '../../components/ActionButton';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';

const NonSimaCompanyAdminUser = () => {
  const oicdContext = useContext(OidcRoutesContext);
  const { logout } = useReactOidc();
  const registerCompanyAction = useToastAction();
  const { t } = useTranslation([resources.common]);


  const registerCompany = () => {
    registerCompanyAction.execute(async () => {
      await assignCustomerToSimaPlatform(oicdContext.customerInfo.customerId);
      window.location.reload();
    }, 'Failed to update', 'Request sent');
  }

  return (
    <>
      <PageHeader title={`You have loggen in as customer: ${oicdContext.customerInfo?.name}`} />
                        
      <p className="-mt-4 text-base leading-7 text-gray-600">
        {t('descriptionCompanyNotRegistered')}
      </p>
      <div className='mt-6 inline-flex'>
        <ActionButton 
          title={t('registerCompany')}
          onClick={registerCompany}
          image="/images/register_company.png"
        />
        <ActionButton 
          title={t('exitThisApplication')} 
          onClick={logout}
          image="/images/exit_application.png"
        />
      </div>
    </>
  );
};

export default NonSimaCompanyAdminUser;
