import React, { Fragment, useEffect, useState } from "react";
import { ConfigurationProductContext } from "../../../contexts";
import { Table as TableCore, useClassNames, useTableActions, useToastAction } from '@metaforcelabs/metaforce-core';
import { useTranslation } from 'react-i18next';
import { resources, translations } from "../../../translations/translationsConstants";
import ConfigurationProductList from "./configurationProductList";
import { createProductConfiguration, getEmptyProduct, getProductConfigurations, updateProductConfiguration } from "../../../api/product";
import ConfigurationProductModal from "./configurationProductModal";
import { getProductConfigurationModules } from "../../../api/simaProductsConfiguration";
import axios from "axios";
import Button from "../../../components/Button";

const ConfigurationProduct = () => {
  const [activeProduct, setActiveProduct] = useState()
  const [products, setProducts] = useState([])
  const [showProductModal, setShowProductModal] = useState(false)
  const [configurationModules, setConfigurationModules] = useState()
  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const { t } = useTranslation([resources.internal]);

  const loadData = () => {
    loadAction.execute(async () => {
      const tokenSource = axios.CancelToken.source();
      const [productsData, configModules] = await Promise.all([getProductConfigurations(), getProductConfigurationModules('', tokenSource.token)]);
      setProducts(productsData);
      setConfigurationModules(configModules)
      console.log(configModules)

    }, `${t('failedMessage')}`)
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleStartCreateProduct = () => {
    loadAction.execute(async () => {
      const defaultProduct = await getEmptyProduct();
      handleProductSelect(defaultProduct);
    }, `${t('failedToInitProduct')}`)
  }

  const handleProductSelect = (product) => {
    setActiveProduct(product);
    setShowProductModal(true);
  }

  const handleConfigProductSave = (updatedProduct, onComplete) => {
    saveAction.execute(async () => {
      console.log(updatedProduct);
      if (updatedProduct.id) {
        const updated = await updateProductConfiguration(updatedProduct);
        setActiveProduct(prev => updated);

        const idx = products.findIndex(x => x.id === updatedProduct.id);
        if (idx === -1) return;

        const updatedProducts = [...products]
        updatedProducts[idx] = updatedProduct;
        setProducts(prev => updatedProducts);

      } else {
        debugger;
        const created = await createProductConfiguration(updatedProduct);
        setActiveProduct(prev=> created);
        const updatedProducts = [...products,created];
        setProducts(prev => updatedProducts);
      }
      onComplete();

    }, `${t('savefailed')}`);
  }

  return (
    <ConfigurationProductContext.Provider
      value={{
        test: 'test',
        configurationModules: configurationModules,
        onProductSelect: (product) => {
          handleProductSelect(product);
        }
      }}
    >
      <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
        <div className="max-w-screen-3xl mx-auto mt-5 px-2 sm:px-4 lg:px-8">
          <div className="flex justify-end max-w-screen-md">
            <Button onClick={e => {
              handleStartCreateProduct();
            }} variant={'primary'} size={'md'} >{t('new')}</Button>
          </div>
          <div className="max-w-screen-md">
            <ConfigurationProductList products={products} />
          </div>

        </div>
      </main>

      <ConfigurationProductModal product={activeProduct} open={showProductModal} setOpen={setShowProductModal}
        onSave={(e, onComplete) => {
          handleConfigProductSave(e, onComplete);
          setShowProductModal(false)
        }}
        title={t('configureProduct')}
      />

    </ConfigurationProductContext.Provider>
  )
}

export default ConfigurationProduct;