import React, { useEffect, useState } from 'react';
import { getTeamUsers, getUserRoles, getUserStatuses } from '../../../api/teams';
import { ManageTeamContext } from '../../../contexts';
import { useToastAction } from '../../../hooks/useToastAction';
import FilterPanel from './filterPanel';
import UserTable from './userTable';
import PageHeader from '../../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const ManageTeam = () => {
  const [teamUsers, setTeamUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userStatuses, setUserStatuses] = useState([]);
  const { t } = useTranslation([resources.admin]);

  const loadDataAction = useToastAction();

  const loadData = async () => {
    loadDataAction.execute(async () => {
      const users = await getTeamUsers();
      setTeamUsers(users);
      setFilteredUsers(users);
      const roleList = await getUserRoles();
      setRoles(roleList);
      const statuses = await getUserStatuses();
      setUserStatuses(statuses);
    }, 'Failed to load')
  };

  const filterUsers = ({ name, email, role }) => {
    let filterResult = teamUsers.filter((x) => {
      return namePredicate(x, name) && emailPredicate(x, email) && rolePredicate(x, role);
    });
    setFilteredUsers(filterResult);
  };
  const namePredicate = (user, name) => {
    return !name || user.name.toLowerCase().includes(name.toLowerCase());
  };
  const emailPredicate = (user, email) => {
    return !email || user.email.toLowerCase().includes(email.toLowerCase());
  };
  const rolePredicate = (user, role) => {
    if (!role) return true;

    const roleObj = roles.find((x) => x.roleId === role);
    if (!roleObj) return false;

    return user.roleId === roleObj.roleId;
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <PageHeader title={t('manageTeam')} />
            <div className="mt-4">
              <ManageTeamContext.Provider value={{ roles: roles, statuses: userStatuses }}>
                <section>
                  <FilterPanel onSearchUsers={(e) => filterUsers(e)} />
                </section>
                <section className="mt-4">
                  <UserTable users={filteredUsers} />
                </section>
              </ManageTeamContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ManageTeam;