import React, { useEffect, useState } from 'react';
import ConfigModuleDetails from './ConfigModuleDetails';
import ConfigModulesList from './ConfigModulesList';
import { SimaProductModuleConfigContext } from '../../../contexts';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import axios from 'axios';
import { getProductConfigurationModule, getProductConfigurationModules } from '../../../api/simaProductsConfiguration';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const ProductsConfigurationModuleConfiguration = () => {
  const [selectedConfigModuleId, setSelectedConfigModuleId] = useState(undefined);
  const [configModules, setConfigModules] = useState([]);
  const [configModulesToShow, setConfigModulesToShow] = useState([]);
  const [selectedConfigModule, setSelectedConfigModule] = useState()
  const [filter, setFilter] = useState('')
  const [cancelToken, setCancelToken] = useState(undefined);
  const loadAction = useToastAction();
  const { t } = useTranslation([resources.internal]);

  const loadConfigurationModules = () => {
    loadAction.execute(async () => {
      const tokenSource = axios.CancelToken.source();
      setCancelToken(tokenSource);
      const resultItems = await getProductConfigurationModules('', tokenSource.token);

      setConfigModules(resultItems);
      filterConfigurationModules(resultItems);
    }, `${t('failedMessage')}`)
  }

  const filterConfigurationModules = (data, filter = '') => {
    const toShow = data.filter(x => x.name?.toLowerCase().includes(filter.toLowerCase()))
    setConfigModulesToShow(toShow)
  }

  useEffect(() => {
    loadConfigurationModules();
  }, [])

  const handleModuleNameChanged = (id, name) => {

    const idx = configModules.findIndex(x => x.id === id);

    if (idx === -1) return;

    const updated = [...configModules];
    updated[idx] = { ...updated[idx], name: name }

    setConfigModules(prev => updated);
    filterConfigurationModules(updated, filter);
  }

  const handleSearch = (searchValue) => {
    setFilter(searchValue)
    filterConfigurationModules(configModules, searchValue);
  }

  const handleSelectedConfigModule = (selectedId) => {
    setSelectedConfigModule(prev => null)
    loadAction.execute(async () => {
      const result = await getProductConfigurationModule(selectedId);
      setSelectedConfigModule(prev => result);
    }, `${t('failedtoLoadConfigModule')}`);
  }

  return (
    <SimaProductModuleConfigContext.Provider value={{
      selectedConfigModule: selectedConfigModule,
      onSelectedConfigModuleId: handleSelectedConfigModule,
      configModulesToShow: configModulesToShow,
      onSearch: (searchValue) => handleSearch(searchValue),
      onModuleNameChanged: (id, name) => {
        handleModuleNameChanged(id, name);
      }
    }}>
      <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
        <div className="max-w-screen-3xl mx-auto mt-5 px-2 sm:px-4 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-3xl">
              <div className="w-full h-full">
                <div className='flex justify-between gap-8'>
                  <div className='w-1/2'>
                    <ConfigModulesList />
                  </div>
                  <div className='w-1/2'>
                    <ConfigModuleDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </SimaProductModuleConfigContext.Provider >
  );
};

export default ProductsConfigurationModuleConfiguration;
