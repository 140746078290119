import React, {useState} from 'react';
import SimaProductAreaList from './SimaProductAreaList';
import SimaProductAreaManagement from './SimaProductAreaManagement';

const SimaProductAreas = () => {
  const [selectedProductArea, setSelectedProductArea] = useState(null);

  return (
      <main
        className="main-container focus:outline-none h-full mt-5"
        >
        <div className="max-w-screen-3xl mx-auto px-2 sm:px-4 lg:px-8">
          {
            selectedProductArea ? (
              <SimaProductAreaManagement onClearSelected={() => { setSelectedProductArea(null) }} selectedProductArea={selectedProductArea} />
            ) : (
              <SimaProductAreaList onSelectProductArea={(productArea) => setSelectedProductArea(productArea)}/>
            )
          }
        </div>
      </main>
  );
};

export default SimaProductAreas;