import { Form, Formik } from 'formik';
import React, { useState, useContext, useEffect } from 'react';
import { removeConfigModulePropertyOption, getProducts, updateProductConfigurationModule } from '../../../../../api/simaProductsConfiguration';
import { useToastAction } from '../../../../../hooks/useToastAction';
import { resources, translations } from '../../../../../translations/translationsConstants';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { TrashIcon, EyeIcon, PencilAltIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { FormikSubmitButton } from '../../../../../components/Form/Formik/FormikSubmit';
import FormikInput from '../../../../../components/Form/Formik/FormikInput';
import { FormikSelectInput } from '../../../../../components/Form/Formik/FormikSelect';
import Modal from '../../../../../components/Modal/index';
import Badge from '../../../../../components/Badge';
import {
    Table,
    TableBody,
    TableColumn,
    TableHead,
    TableHeader,
    TableRow
} from '../../../../../components/Table';
import Button from '../../../../../components/Button';
import { FormProductModuleConfigContext } from '../../../../../contexts';
import Doors from '../doors';
import { frameColorNames, frameColors, frameColorsHex, productModuleTypes } from '../../../../../utils/constants';
import { v4 as uuidv4 } from 'uuid';

const initialFormValues = {
    id: '',
    name: '',
    value: '',
    productModuleRef: '',
    metaData: {}
};

const propertyOptionTypes = {
    default: 'default',
    widthNoRef: 'width-no-ref',
    frameColor: 'frame-color',
    config: 'config'
}

const ProductPropertyOptions = ({ metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, fieldConfig }) => {

    const propertyOptionsType = fieldConfig.propertyOptionsType || propertyOptionTypes.default;
    console.log(fieldConfig)
    return propertyOptionsType === propertyOptionTypes.default ? (
        <DefaultPropertyOptions metaDataPropertyName={metaDataPropertyName} propertyOptions={propertyOptions} products={products} onUpdatePropertyOptions={onUpdatePropertyOptions} />
    ) : propertyOptionsType === propertyOptionTypes.frameColor ? (
        <FrameColorPropertyOptions metaDataPropertyName={metaDataPropertyName} propertyOptions={propertyOptions} products={products} onUpdatePropertyOptions={onUpdatePropertyOptions} />
    ) : propertyOptionsType === propertyOptionTypes.widthNoRef ? (
        <WidthNoRefPropertyOptions fieldConfig={fieldConfig} metaDataPropertyName={metaDataPropertyName} propertyOptions={propertyOptions} products={products} onUpdatePropertyOptions={onUpdatePropertyOptions} />
    ) : propertyOptionsType === propertyOptionTypes.config ? (
        <ConfigurablePropertyOptions fieldConfig={fieldConfig} metaDataPropertyName={metaDataPropertyName} propertyOptions={propertyOptions} products={products} onUpdatePropertyOptions={onUpdatePropertyOptions} />
    ) : (<></>)


};

ProductPropertyOptions.propertyOptionTypes = propertyOptionTypes;
export default ProductPropertyOptions;

const DrywallPartitionMountWidthPropertyOptions = ({ metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts, doorWidths } = useContext(FormProductModuleConfigContext);

    const partitionMountProducts = (avaialableProducts || []).filter(x => x.type === productModuleTypes.dryWallPartitionMount);

    const handleConfirmRemove = () => {
        const idx = propertyOptions.findIndex(x => x.id === selectedId);
        if (idx === -1) return;

        propertyOptions.splice(idx, 1);
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowConfirmModal(false);
    }

    const handleSave = (values, { setSubmitting }) => {
        values = {
            ...values, name: frameColorNames[values.value], metaData: {
                hexColor: frameColorsHex[values.value]
            }
        };
        console.log(values);
        const idx = propertyOptions.findIndex(x => x.id === formValues.id);
        if (idx === -1) {
            propertyOptions.push({ ...values, id: uuidv4() })

        } else {
            propertyOptions[idx] = values
        }


        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        setSubmitting(false)
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Color</TableHeader>
                <TableHeader>Product Id</TableHeader>
                <TableHeader>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                <span className="ml-2">{frameColorNames[row.value]}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                <span className="ml-2">{product?.productId}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={handleConfirmRemove}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={handleSave} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Width"
                                        name="value"
                                        options={
                                            Object.keys(frameColorNames).map(k => ({ name: frameColorNames[k], value: k }))
                                        }
                                        showEmptyOption={!props.values.color}
                                        formikProps={props}
                                    />
                                    <FormikInput
                                        label="Wall overlap"
                                        name="metadata.walloverlap"
                                        type={'number'}
                                        formikProps={props}
                                    />

                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)

}

const DrywallPartitionMountPropertyOptions = ({ metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts, doorWidths } = useContext(FormProductModuleConfigContext);

    const partitionMountProducts = (avaialableProducts || []).filter(x => x.type === productModuleTypes.dryWallPartitionMount);

    const handleConfirmRemove = () => {
        const idx = propertyOptions.findIndex(x => x.id === selectedId);
        if (idx === -1) return;

        propertyOptions.splice(idx, 1);
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowConfirmModal(false);
    }

    const handleSave = (values, { setSubmitting }) => {
        values = {
            ...values, name: frameColorNames[values.value], metaData: {
                hexColor: frameColorsHex[values.value]
            }
        };
        console.log(values);
        const idx = propertyOptions.findIndex(x => x.id === formValues.id);
        if (idx === -1) {
            propertyOptions.push({ ...values, id: uuidv4() })

        } else {
            propertyOptions[idx] = values
        }


        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        setSubmitting(false)
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Color</TableHeader>
                <TableHeader>Product Id</TableHeader>
                <TableHeader>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                <span className="ml-2">{frameColorNames[row.value]}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                <span className="ml-2">{product?.productId}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={handleConfirmRemove}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={handleSave} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Color"
                                        name="value"
                                        options={
                                            Object.keys(frameColorNames).map(k => ({ name: frameColorNames[k], value: k }))
                                        }
                                        showEmptyOption={!props.values.color}
                                        formikProps={props}
                                    />
                                    <FormikSelectInput
                                        label="Product"
                                        name="productModuleRef"
                                        options={partitionMountProducts.map(x => {
                                            return { name: x.name, value: x.id }
                                        })}
                                        showEmptyOption={props.values.productModuleRef === ''}
                                        formikProps={props}
                                    />

                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)

}



const ConfigurablePropertyOptions = ({ fieldConfig, metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts, doorWidths } = useContext(FormProductModuleConfigContext);

    const handleConfirmRemove = () => {
        const idx = propertyOptions.findIndex(x => x.id === selectedId);
        if (idx === -1) return;

        propertyOptions.splice(idx, 1);
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowConfirmModal(false);
    }

    const handleSave = (values, { setSubmitting }) => {
        values = { ...values, name: fieldConfig.values.find(x => x.value === values.value).name };

        const idx = propertyOptions.findIndex(x => x.id === formValues.id);
        if (idx === -1) {
            propertyOptions.push({ ...values, id: uuidv4() })

        } else {
            propertyOptions[idx] = values
        }

        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        setSubmitting(false)
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Name</TableHeader>
                <TableHeader>Value</TableHeader>
                <TableHeader textRight={true}>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    // var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                <span className="ml-2">{row.name}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                <span className="ml-2">{row?.value}</span>
                            </TableColumn>
                            <TableColumn size="small" textRight={true}>
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={handleConfirmRemove}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={handleSave} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Options"
                                        name="value"
                                        options={
                                            [...fieldConfig.values]
                                        }
                                        showEmptyOption={!props.values.color}
                                        formikProps={props}
                                    />

                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)

}

const WidthNoRefPropertyOptions = ({ fieldConfig, metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts, availableWidths } = useContext(FormProductModuleConfigContext);

    const handleConfirmRemove = () => {
        const idx = propertyOptions.findIndex(x => x.id === selectedId);
        if (idx === -1) return;

        propertyOptions.splice(idx, 1);
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowConfirmModal(false);
    }

    const handleSave = (values, { setSubmitting }) => {
        values = { ...values, name: values.value + 'mm' };

        if (fieldConfig.includeMetaData) {
            const widthMetaData = availableWidths.find(x => x.value == values.value).metaData;

            const metaData = { ...widthMetaData };
            values = { ...values, metaData: metaData }
        }

        const idx = propertyOptions.findIndex(x => x.id === formValues.id);
        if (idx === -1) {
            propertyOptions.push({ ...values, id: uuidv4() })

        } else {
            propertyOptions[idx] = values
        }


        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        setSubmitting(false)
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Width</TableHeader>
                {/* <TableHeader>Value</TableHeader> */}
                <TableHeader textRight={true}>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    // var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                <span className="ml-2">{row.name}</span>
                            </TableColumn>
                            {/* <TableColumn size="small">
                                {row?.value}
                            </TableColumn> */}
                            <TableColumn size="small" textRight={true}>
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={handleConfirmRemove}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={handleSave} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Width"
                                        name="value"
                                        options={
                                            availableWidths
                                        }
                                        showEmptyOption={!props.values.color}
                                        formikProps={props}
                                    />

                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)

}



const FrameColorPropertyOptions = ({ metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts } = useContext(FormProductModuleConfigContext);

    const handleConfirmRemove = () => {
        const idx = propertyOptions.findIndex(x => x.id === selectedId);
        if (idx === -1) return;

        propertyOptions.splice(idx, 1);
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowConfirmModal(false);
    }

    const handleSave = (values, { setSubmitting }) => {
        values = { ...values, name: frameColorNames[values.value], metaData: { ...values.metaData, hexcolor: frameColorsHex[values.value] } }

        const idx = propertyOptions.findIndex(x => x.id === formValues.id);
        if (idx === -1) {
            propertyOptions.push({ ...values, id: uuidv4() })

        } else {
            propertyOptions[idx] = values
        }


        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        setSubmitting(false)
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Name</TableHeader>
                <TableHeader>Value</TableHeader>
                <TableHeader textRight={true}>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    // var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                {row.name}
                            </TableColumn>
                            <TableColumn size="small">
                                <span className="ml-2">{row?.value}</span>
                            </TableColumn>
                            <TableColumn size="small" textRight={true}>
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={handleConfirmRemove}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={handleSave} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Ramme farge"
                                        name="value"
                                        options={
                                            Object.keys(frameColorNames).map(k => ({ name: frameColorNames[k], value: k }))
                                        }
                                        showEmptyOption={!props.values.color}
                                        formikProps={props}
                                    />

                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)

}

const DefaultPropertyOptions = ({ metaDataPropertyName, propertyOptions, products, onUpdatePropertyOptions, }) => {
    const { t: translateProducts } = useTranslation([resources.simaProducts]);
    const { t: translateCommon } = useTranslation([resources.common]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formValues, setFormValues] = useState({ ...initialFormValues });
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const { avaialableProducts } = useContext(FormProductModuleConfigContext);

    const removePropertyOptionReference = () => {
        const index = propertyOptions.findIndex(x => x.id === selectedId);
        propertyOptions.splice(index, 1);
        onUpdatePropertyOptions(propertyOptions);
        setShowConfirmModal(false);
        setSelectedId(null);
    }

    const savePropertyOption = (values, actions) => {
        values = defineFormValues(values);
        // we adding new one
        if (selectedId === null) {
            propertyOptions.push(values);
        } else {
            const oryginalPosition = propertyOptions.findIndex(x => x.id === selectedId);
            propertyOptions[oryginalPosition].productModuleRef = values.productModuleRef;
        }
        onUpdatePropertyOptions(propertyOptions);
        setSelectedId(null);
        setShowFormModal(false);
        actions.setSubmitting(false);
        actions.resetForm();
    }

    const defineFormValues = (values) => {
        const proudct = avaialableProducts.find(x => x.id === values.productModuleRef);
        return {
            name: `${parseInt(proudct.metaData[metaDataPropertyName])}mm`,
            value: proudct.metaData[metaDataPropertyName],
            productModuleRef: values.productModuleRef,
            metaData: {}
        };
    }

    return (<>
        <div className="flex justify-end justify-between">
            <h1 className="text-sm font-bold">Select input options</h1>
            <Button variant={Button.variants.secondary} onClick={() => { setShowFormModal(true); setSelectedId(null); setFormValues({ ...initialFormValues }) }}>Add new</Button>
        </div>
        <Table>
            <TableHead>
                <TableHeader>Value</TableHeader>
                <TableHeader>Product name</TableHeader>
                <TableHeader>Product Id</TableHeader>
                <TableHeader>Actions</TableHeader>
            </TableHead>
            <TableBody>
                {propertyOptions.map((row, index) => {
                    var product = products.find(x => x.id === row.productModuleRef);
                    return (
                        <TableRow key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                            <TableColumn size="small">
                                <span className="ml-2">{row.value}</span>
                            </TableColumn>
                            <TableColumn size="small">
                                {product ? product?.name : <Badge type="warn" text={"Product missing"} />}
                            </TableColumn>
                            <TableColumn size="small">
                                {product?.productId}
                            </TableColumn>
                            <TableColumn size="small">
                                <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                                    <PencilAltIcon aria-hidden="true" onClick={() => { setShowFormModal(true); setSelectedId(row.id); setFormValues(row); }} />
                                </button>
                                <button className="hover:text-red-600 h-6 w-6" type="button">
                                    <TrashIcon aria-hidden="true" onClick={() => { setShowConfirmModal(true); setSelectedId(row.id); }} />
                                </button>
                            </TableColumn>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        <ConfirmationModal
            open={showConfirmModal}
            setOpen={() => setShowConfirmModal(false)}
            type="error"
            handleOnConfirm={removePropertyOptionReference}
            description="Do you want to remove selected reference from property options? This operation is irreversible."
            confirmButtonText="Remove"
            title="Are you sure?"
        />

        <Modal
            isOpen={showFormModal}
            onClose={() => { setShowFormModal(false); }}
            size={'large'}
            title={`Property option details`}
        >
            <div className='space-y-4'>
                <div className='grid grid-cols-1 gap-x-2'>
                    <Formik initialValues={formValues} onSubmit={savePropertyOption} enableReinitialize={true}>
                        {(props) => (
                            <Form className='space-y-4'>
                                <div className="grid grid-cols-3 gap-x-3">
                                    <FormikSelectInput
                                        label="Product"
                                        name="productModuleRef"
                                        options={avaialableProducts.map(x => {
                                            return { name: x.name, value: x.id }
                                        })}
                                        showEmptyOption={props.values.productModuleRef === ''}
                                        formikProps={props}
                                    />
                                </div>
                                <div className="flex justify-end mt-3">
                                    <FormikSubmitButton
                                        text={translateCommon(translations.common.save)}
                                        formikProps={props}
                                    />
                                    <button
                                        type='button'
                                        onClick={() => { setShowFormModal(false); }}
                                        className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modal>

    </>)
}