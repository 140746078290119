import { ExclamationIcon } from '@heroicons/react/outline';

export const AlertType = {
  Warning: 'warning',
  Error: 'error',
  Success: 'Success'
};

const Alert = ({ type, title, msg, className = '' }) => {
  const color =
    (type === AlertType.Warning && 'yellow') ||
    (type === AlertType.Error && 'red') ||
    (type === AlertType.Success && 'green');

  const icon = () => {
    switch (type) {
      case AlertType.Warning:
        return <ExclamationIcon className={`h-5 w-5 text-yellow-400`} aria-hidden="true" />;
      case AlertType.Error:
        return <ExclamationIcon className={`h-5 w-5 text-red-400`} aria-hidden="true" />;
      default:
        return <ExclamationIcon className={`h-5 w-5 text-green-400`} aria-hidden="true" />;
    }
  };

  return (
    <div className={`${className} rounded-md bg-${color}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">{icon()}</div>
        <div className="ml-3">
          {title && <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>}
          <div className={`${title && 'mt-2'} text-sm text-${color}-700`}>
            <p>{msg}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
