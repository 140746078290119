import React, { useState, useEffect, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { useTranslation } from 'react-i18next';
import { getProductAreas } from '../../../api/simaAdmin';
import { resources, translations } from '../../../translations/translationsConstants';
import { Table as TableCore, useTableActions, useToastAction } from '@metaforcelabs/metaforce-core';
import { stringToLocaleDateTimeString } from '../../../utils/date';

const SimaProductAreaList = (props) => {
  const { t:translationSima } = useTranslation([resources.simaAdmin]);
  const customersAction = useToastAction();
  const [recordsToShow, setRecordsToShow] = useState([]);
  const tableActions = useTableActions(recordsToShow, 8);
  const { t } = useTranslation([resources.internal]);

  const fetchProductAreas = (values) => {
    customersAction.execute(async () => {
      const getProductAreasResult = await getProductAreas();
      setRecordsToShow(getProductAreasResult);
    }, `${t('failedMessageProductAreas')}`);
  };

  useEffect(() => {
    fetchProductAreas();
  }, []);

  return (
    <main
      className="main-container focus:outline-none h-full">
      <div className="flex flex-col pb-5 mb-10 border-b border-brand-green-dark">
        <h1 className="text-3xl font-bold">
          {translationSima(translations.simaAdmin.productAreas.list.title)}
        </h1>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          {translationSima(translations.simaAdmin.productAreas.list.subtitle)}
        </p>
      </div>

      <div className="relative mt-8">
        <Table>
          <TableHead>
            <TableHeader>{translationSima(translations.simaAdmin.productAreas.list.header.name)}</TableHeader>
            <TableHeader>{translationSima(translations.simaAdmin.productAreas.list.header.createdBy)}</TableHeader>
            <TableHeader>{translationSima(translations.simaAdmin.productAreas.list.header.createdDate)}</TableHeader>
            <TableHeader>{translationSima(translations.simaAdmin.productAreas.list.header.updatedDate)}</TableHeader>
          </TableHead>
          <TableBody>
            {tableActions.pageData?.map((row, index) => (
              <Fragment key={index}>
                <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                  <TableColumn>
                    <a 
                      onClick={() => props.onSelectProductArea(row)}
                      className="cursor-pointer font-medium text-sm text-brand-black hover:text-indigo-500 border-0 shadow-none"
                    >
                      {row.name}
                    </a>
                  </TableColumn>
                  <TableColumn>
                      {row.CreatedBy}
                  </TableColumn>
                  <TableColumn>{stringToLocaleDateTimeString(row.createdDate)}</TableColumn>
                  <TableColumn>
                    {stringToLocaleDateTimeString(row.createdDate || row.updatedDate)}
                  </TableColumn>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
        <TableCore.Pagination tableActions={tableActions} />
      </div> 
    </main>
  )
};

export default SimaProductAreaList;