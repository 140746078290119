import React, { useEffect, useState } from 'react';
import ProductCard from '../../../../components/ProductCard';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../../translations/translationsConstants';
import PageHeader from '../../../../components/PageHeader';
import Search from '../../../../components/Search';
import { getLots, getProducts } from '../../../../api/rackbeat';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Dropdown from '../../../../components/Dropdown';
import { set } from 'lodash';
import LotCard from '../../../../components/LotCard';

const productTypes = [
  {display: 'Tepper', value: 'floor'},
  {display: 'Belysning', value: 'light'},
  {display: 'Himlinger', value: 'ceiling'},
]

const LotShop = () => {
  const { t } = useTranslation([resources.glasswall]);
  const { productType } = useParams();

  const [lots, setLots] = useState([]);
  const [lotsToShow, setLotsToShow] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState();

  useEffect(() => {
    if (productType) {
      const productTypeObj = productTypes.find((type) => type.value === productType);
      setSelectedProductType(productTypeObj);
    }
  }, []);

  useEffect(() => {
    loadProducts();
  }, [selectedProductType]);

  const loadProducts = async () => {
    const result = await getLots();
    setLots(result);
    setLotsToShow(result);
  }

  const handleSearch = (event) => {
    const value = event.target.value;

    if (value) {
      const filteredProducts = lots.filter((product) => {
        return product.name.toLowerCase().includes(value.toLowerCase());
      });
      setLotsToShow(filteredProducts);
    } else {
      setLotsToShow(lots);
    }
  }

  return (
    <main
        className="main-container bg-brand-gray-light focus:outline-none h-full"
      >
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full">
              <PageHeader title={t('Velg produkter')} />
              <div className='flex gap-4'>                
                {/* <Dropdown 
                  selectedOption={selectedProductType}
                  setSelectedOption={setSelectedProductType}
                  options={productTypes}
                 /> */}
                <Search onSearch={handleSearch} 
                  actions={[
                    {}
                  ]}                  
                />
              </div>
              <div className="max-w-8xl mx-auto">
                <div className="mt-5 flex flex-wrap flex-cols gap-5">
                  {lotsToShow.map((lot) => (
                    <LotCard lot={lot} key={lot.id} title={lot.name} to={`/products/lots/shop/${lot.id}`} />
                  ))}
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
    </main>
  )
};

export default LotShop;
