
import React, { useState, useEffect } from 'react';
import { Button } from '../../../components'
import { resources, translations } from '../../../translations/translationsConstants';
import { useTranslation } from 'react-i18next';
import { getCountries, getCustomer, getProductAreas, saveCustomer } from '../../../api/simaAdmin';
import { Form, Formik } from 'formik';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikCheckbox } from '../../../components/Form/Formik/FormikCheckbox';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import { useToastAction } from '../../../hooks/useToastAction';

const CustomerManagement = ({onClearSelected, selectedCustomer}) => {
    const { t:translationCommon } = useTranslation([resources.common])
    const { t:translationSima } = useTranslation([resources.simaAdmin]);
    const { t } = useTranslation([resources.internal]);

    
    const [countries, setCountries] = useState([]);
    const [productAreas, setProductAreas] = useState([]);
    const [formValues, setFormValues] = useState({});
    
    const countriesAction = useToastAction();
    const productAreastAction = useToastAction();
    const customerAction = useToastAction();
    const updateCustomerAction = useToastAction();

    const fetchCustomer = () => {
        customerAction.execute(async () => {
            const customerData = await getCustomer(selectedCustomer.customerId);
            setFormValues({
            ...customerData, 
            country: customerData.country.code,
            allowedProductAreas: mapperToFormData(customerData)
            });
        }, `${t('failedMessage')}`);
    };

    const fetchCountries = async () => {
        countriesAction.execute(async () => {
          const response = await getCountries();
          setCountries(response);
        }, `${t('failedMessage')}`);
    };

    const fetchProductAreas = async () => {
        productAreastAction.execute(async () => {
          const response = await getProductAreas();
          setProductAreas(response);
        }, `${t('failedMessageProductAreas')}`);
    };

    const mapperToFormData = (data) => {
        let returnArray = [];
        if(data.allowedProductAreas) data.allowedProductAreas.map((area) => {
            returnArray[area] = true;
        });
        return returnArray;
    };

    const mapToRequestProductAreaData = (productAreas, formValues) => {
        const allowedProductAreas = productAreas.map(area => {
            if(formValues[area.id] === true) return area.id;
        })
        return allowedProductAreas;
    };

    const handleOnCheckboxChange = () => {

    }

    const handleOnSubmit = (values, actions) => {
        updateCustomerAction.execute(
          async () => {
            const countryObject = countries.find((c) => c.code === values.country);
            await saveCustomer({...values,
              country: countryObject,
              simaStatus: parseInt(values.simaStatus),
              allowedProductAreas: mapToRequestProductAreaData(productAreas, values.allowedProductAreas)
            });
            actions.setSubmitting(false);
          },
          `${t('failedToSaveData')}`,
          `${t('saveSuccessful')}`
        );
      };

    useEffect(async () => {
        await fetchCountries();
        await fetchProductAreas();
        fetchCustomer();
    }, []);

    return (
        <div>
            <div className='flex justify-start'>
                <Button variant={Button.variants.primary}
                    onClick={onClearSelected}>
                    {translationCommon(translations.common.back)}
                </Button>
            </div>
            <div className="flex flex-col mt-5 pb-5 mb-10 border-b border-brand-green-dark">
                <h1 className="text-3xl font-bold">{translationSima(translations.simaAdmin.customers.edit.title)} {selectedCustomer.name}</h1>
                <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    {translationSima(translations.simaAdmin.customers.edit.subtitle)}
                </p>
                {formValues && countries.length > 0 && (
                    <div className="bg-white mt-4 py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
                        <Formik initialValues={formValues} onSubmit={handleOnSubmit} enableReinitialize={true}>
                        {(props) => (
                            <Form>
                                <div className="space-y-8 divide-y divide-gray-200">
                                    <div className="md:grid md:grid-cols-4 md:gap-6">
                                        <div className="md:col-span-1">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{translationSima(translations.simaAdmin.customers.edit.form.company)}</h3>
                                        </div>

                                        <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                                            <div className="sm:col-span-2">
                                                <FormikInput label={translationSima(translations.simaAdmin.customers.edit.form.companyName)} name="name" formikProps={props} />
                                            </div>

                                            <div className="sm:col-span-2">
                                                <FormikInput
                                                    label={translationSima(translations.simaAdmin.customers.edit.form.organizationNumber)}
                                                    name="orgNumber"
                                                    formikProps={props}
                                                />
                                            </div>

                                            <div className="sm:col-span-3"></div>

                                            <div className="sm:col-span-2">
                                                <FormikSelectInput
                                                label={translationSima(translations.simaAdmin.customers.edit.form.state)}
                                                name="simaStatus"
                                                options={[
                                                    {
                                                    name: translationSima(translations.simaAdmin.customers.form.pendingStatus),
                                                    value: '1'
                                                    },
                                                    {
                                                    name: translationSima(translations.simaAdmin.customers.form.activeStatus),
                                                    value: '2'
                                                    },
                                                    {
                                                    name: translationSima(translations.simaAdmin.customers.form.rejectedStatus),
                                                    value: '3'
                                                    }
                                                ]}
                                                formikProps={props}
                                                />
                                            </div>

                                            <div className="sm:col-span-1">
                                                <FormikSelectInput
                                                label={translationSima(translations.simaAdmin.customers.edit.form.country)}
                                                name="country"
                                                options={countries.map((c) => ({
                                                    name: c.name,
                                                    value: c.code
                                                }))}
                                                formikProps={props}
                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="md:grid md:grid-cols-4 md:gap-6 pt-5">
                                        <div className="md:col-span-1">
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{translationSima(translations.simaAdmin.customers.edit.form.productAreas)}</h3>
                                        </div>

                                        <div className="mt-5 space-y-6 md:col-span-3 md:mt-0">
                                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-7">
                                                
                                                <div className="sm:col-span-4">
                                                <legend className="text-sm font-medium text-gray-900">{translationSima(translations.simaAdmin.customers.edit.form.assignAccessFor)}</legend>
                                                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                                                        {productAreas &&
                                                    productAreas
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((p) => (
                                                        <div key={p.id}>
                                                            <FormikCheckbox
                                                            label={p.name}
                                                            name={`allowedProductAreas.${p.id}`}
                                                            formikProps={props}
                                                            value={p.id}
                                                            onClick={handleOnCheckboxChange}
                                                            />
                                                        </div>
                                                        ))}
                                                    </div>
                                                
                                                </div>

                                               
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <Button
                                                variant={Button.variants.secondary}
                                                onClick={() => onClearSelected()}
                                            >
                                                {translationCommon(translations.common.cancel)}
                                            </Button>

                                            <FormikSubmitButton
                                                id="save"
                                                text="Save"
                                                className="ml-2 i"
                                                formikProps={props}
                                            />
                                        </div>
                                    </div>
                                </div> 
                            </Form>
                        )}
                        </Formik>
                    </div>
                    )}
            </div>
        </div>
    );
};

export default CustomerManagement;