const FormDataMapper = {
  toFormData(requestObj) {
    const formData = new FormData();
    Object.getOwnPropertyNames(requestObj).forEach(propertyName => {
      formData.append(propertyName, requestObj[propertyName]);
    })

    return formData;
  },
};
export default FormDataMapper;
