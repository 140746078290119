import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import ContentCategoryCard from '../../components/ContentCategoryCard';

const Products = () => {
  const { t } = useTranslation([resources.glasswall]);

  useEffect(() => {
  }, []);

  return (
    <main className="main-container bg-white focus:outline-none h-full relative">
      <div className='h-48 absolute w-full bg-brand-brown-medium border-brand-green-dark border-t border-b' />
      <div className="max-w-screen-3xl mx-auto mt-10 inset-0 py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col pb-10 h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full">
              <div className="max-w-8xl mx-auto">
                <div className="mt-5 flex flex-cols flex-wrap gap-5">
                  <ContentCategoryCard title={t('carpet')} to="/products/shop/floor" image={'/images/tepper.png'}/>
                  <ContentCategoryCard title={t('lighting')} to="/products/shop/light"  image={'/images/belysning.png'}/>
                  {/* <ContentCategoryCard title={t('Himlinger')} to="/products/shop/ceiling" image={'/images/category_ceiling.png'} /> */}
                  <ContentCategoryCard title={t('walls')} to="/products/shop/wall" image={'/images/vegger.png'}/>
                  {/* <ContentCategoryCard title={t('Akustikk')} to="/products/shop/acoustics" />
                  <ContentCategoryCard title={t('Dører')} to="/products/shop/doors"  /> */}
                  <ContentCategoryCard title={t('inventory')} to="/products/shop/inventory" image={'/images/inventar.png'}/>
                  <ContentCategoryCard title={t('furniture')} to="/products/shop/furniture"  image={'/images/mobler.png'}/>

                  {/* <ActionButton title={t('Detaljer til kontor')} to="/products/shop" image={'/images/open_project.png'} /> */}
                  {/* <ActionButton title={t('Glassvegger')} to="/products/shop" image={'/images/open_project.png'} /> */}
                  {/* <ActionButton title={t('Interiør')} to="/products/shop" image={'/images/open_project.png'} /> */}
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
    </main>
  )
};

export default Products;
