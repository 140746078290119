import { BaseUrl, destroy, get, post, download } from './api';
import axios from 'axios';
const controllerName = 'rackbeat';

export const getShopItems = async (productType) => {
    const items = await get(controllerName + `/shop/${productType}`);
    return [...items.lots, ...items.products];    
}

export const getProducts = async (productType) => {
    return await get(controllerName + `/products/${productType}`);
}

export const getProduct = async (number) => {
    return await get(controllerName + `/products/${number}`);
}

export const getProductLineable = async (number) => {
    return await get(controllerName + `/products/${number}/lineable`);
}

export const getLots = async () => {
    return await get(controllerName + `/lots`);
}

export const getInvoicesFilter = async (dateFrom, dateTo) => {

    const axiosParams = {
        params: { 
            offerDateFrom: dateFrom,
            offerDateTo: dateTo,
        },
    };
    return await get(`${controllerName}/invoices`, BaseUrl.Api, { axiosParams });

}

export const getInvoices = async () => {

    return await get(`${controllerName}/invoices`, BaseUrl.Api);

}

export const getOffers = async (dateFrom, dateTo) => {

    const axiosParams = {
        params: { 
            offerDateFrom: dateFrom,
            offerDateTo: dateTo,
        },
    };
    return await get(`${controllerName}/offers`, BaseUrl.Api, { axiosParams });
};

export const getOrders = async (dateFrom, dateTo, searchTerm, status) => {
    const axiosParams = {
        params: { 
            offerDateFrom: dateFrom,
            offerDateTo: dateTo,
            searchTerm, 
            status, 
        },
    };
    let result = await get(`${controllerName}/orders`, BaseUrl.Api, {axiosParams});
    
    if (result && Array.isArray(result.offers) && Array.isArray(result.orders)) {
        return [...result.offers, ...result.orders];
    } else {
        return [];
    }
}

export const saveAddress = async (offerNumber, address) => {
    return await post(`${controllerName}/offers/${offerNumber}/address`, address);
}

export const updateDetails = async (offerNumber, details) => {
    return await post(`${controllerName}/offers/${offerNumber}/details`, details);
}

export const getActiveOffers = async () => {
    return await get(controllerName + `/offers/active`);
}

export const getOffer = async (offerNumber) => {
    return await get(controllerName + `/offers/${offerNumber}`);
}

export const getOrder = async (orderNumber, type) => {
    return await get(controllerName + `/orders/${orderNumber}${type ? `/${type}` : ''}`);
}

export const acceptOffer = async (offerNumber) => {
    return await post(controllerName + `/offers/${offerNumber}/accept`);
}

export const convertOffer = async (offerNumber) => {
    return await post(controllerName + `/offers/${offerNumber}/convert`);
}

export const reopenOffer = async (offerNumber) => {
    return await post(controllerName + `/offers/${offerNumber}/reopen`);
}

export const updateItemQuantity = async (offerNumber, orderLine, quantity) => {
    return await post(controllerName + `/offers/${offerNumber}/items/${orderLine}/quantity/${quantity}`);
}

export const createOfferForCustomer = async (offerNumber) => {
    return await post(controllerName + `/offers/${offerNumber}/create`);
}

export const rejectOffer = async (offerNumber) => {
    return await post(controllerName + `/offers/${offerNumber}/reject`);
}

export const addItemToOffer = async (offerNumber, product, quantity) => {
    return await post(controllerName + `/offers/${offerNumber}/items`, {product, quantity});
}

export const deleteItemFromOffer = async (offerNumber, itemId) => {
    return await destroy(controllerName + `/offers/${offerNumber}/items/${itemId}`);
}
export const createOrder = async (header, product, quantity) => {
  return await post(controllerName + '/offers/create', { header, product, quantity})
}

export const downloadInvoice = async (invoiceNumber) => {
    try {
        const path = `${controllerName}/download-invoice/${invoiceNumber}`;
        const filename = `invoice-${invoiceNumber}.pdf`;
        await download(path, filename);
    } catch (error) {
        console.error('Error downloading invoice:', error);
        throw error;
    }
};

export const downloadOffer = async (offerNumber) => {
    try {
        const path = `${controllerName}/download-offer/${offerNumber}`;
        const filename = `offer-${offerNumber}.pdf`;
        await download(path, filename);
    } catch (error) {
        console.error('Error downloading offer:', error);
        throw error;
    }
};
export const downloadOrder = async (orderNumber) => {
    try {
        const path = `${controllerName}/download-order/${orderNumber}`;
        const filename = `order-${orderNumber}.pdf`;
        await download(path, filename);
    } catch (error) {
        console.error('Error downloading order:', error);
        throw error;
    }
};

export const sendEmailOffer = async (offerNumber, emailData) => {
    
    const path = `${controllerName}/offers/${offerNumber}/send`; 
    try {
      const result = await post(path, emailData);
      return {
        status: 200,
        data: result
      };
    } catch (error) {
      console.error('Error sending email:', error);
      return {
        status: error.response?.status || 500,
        error: error.message
      };
    }
  };

  export const sendEmailOrder = async (orderNumber, emailData) => {
    
    const path = `${controllerName}/orders/${orderNumber}/send`; 
    try {
      const result = await post(path, emailData);
      return {
        status: 200,
        data: result
      };
    } catch (error) {
      console.error('Error sending email:', error);
      return {
        status: error.response?.status || 500,
        error: error.message
      };
    }
  };

  export const updateOffer = async (offerNumber, offerData) => {
    
    const path = `${controllerName}/offers/${offerNumber}/updateOffer`; 
    try {
      const result = await post(path, offerData);
      return {
        status: 200,
        data: result
      };
    } catch (error) {
      console.error('Error update offer:', error);
      return {
        status: error.response?.status || 500,
        error: error.message
      };
    }
  };

