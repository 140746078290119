import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { MenuIcon, UserIcon, XIcon } from '@heroicons/react/outline';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import { OidcRoutesContext } from '../../contexts';
import { useClassNames } from '../../hooks/useClassNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changeCompany } from '../../utils/oidcUtilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import { getPendingCustomersCount } from '../../api/simaAdmin';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons';

const BaseLayout = ({ nav, children }) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const { logout } = useReactOidc();
  const { classNames } = useClassNames();
  const { t } = useTranslation([resources.common]);
  const [userNavigation, setUserNavigation] = useState([]);
  const [navigation, setNavigation] = useState([]);
  const [pendingCustomersCount, setPendingCustomersCount] = useState(0);
  const [currentNav, setCurrentNav] = useState();

  const buildSubmenuOptionSelected = navigation.find((navig) => navig.current)?.children;

  useEffect(() => {
    loadPendingCustomersCount();
    loadUserNavigation();
    loadNavigation();
  }, []);

  const loadNavigation = () => {
    const navBuild = [];

    if (!oidcRoutesContext.hasAccess) {
      setNavigation(navBuild);
      return;
    }

    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: "Home",
      route: `/dashboard`,
      current: nav === 'dashboard',
      hidden: oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true
    });


    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: t('Products'),
      route: `/products`,
      current: nav === 'content',
      hidden: oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true
    });

    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: t('orders'),
      route: `/orders`,
      current: nav === 'orders',
      hidden: oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true
    });

    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: 'Invoices',
      route: `/invoices`,
      current: nav === 'invoices',
      hidden: oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true
    });
    
    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: `${t('products')}`,
      route: `/internal/products-configuration/products`,
      current: nav?.startsWith('internal/products-configuration'),
      hidden: !oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true,
      children: [
        {
          name: 'Products',
          current: nav === 'internal/products-configuration/products',
          route: `/internal/products-configuration/products`
        },
        {
          name: 'Product Configuration',
          current: nav === 'internal/products-configuration/productconfiguration',
          route: `/internal/products-configuration/productconfiguration`
        },
        {
          name: 'Product Module Configuration',
          current: nav === 'internal/products-configuration/configuration',
          route: `/internal/products-configuration/configuration`
        }
      ]
    });

    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: `${t('management')}`,
      route: `/internal/management/customers`,
      current: nav?.startsWith('internal/management'),
      hidden: !oidcRoutesContext.isSimaAdminUser,
      featureEnabled: true,
      children: [
        {
          name: 'Customers',
          current: nav === 'internal/management/customers',
          route: `/internal/management/customers`
        },
        {
          name: 'Product Area',
          current: nav === 'internal/management/productArea',
          route: `/internal/management/productArea`
        }, 
        {
          name: 'Settings',
          current: nav === 'internal/management/settings',
          route: `/internal/management/settings`
        }      
      ]
    });

    navBuild.push({
      icon: <FontAwesomeIcon icon={faHouseUser} className="text-2xl" />,
      name: `${t('admin')}`,
      route: `/admin/manageteam`,
      current: nav?.startsWith('admin'),
      hidden: true,
      featureEnabled: true,
      children: [
        {
          name: 'Manage team',
          current: nav === 'admin/manageteam',
          route: `/admin/manageteam`
        },
        {
          name: 'Invitations',
          current: nav === 'admin/invitations',
          route: `/admin/invitations`
        }  
      ]
    });
    
    const currNav = navBuild.find(x => !!x.current);
    
    setCurrentNav(currNav)
    setNavigation(navBuild);
  }

  const loadUserNavigation = () => {
    const navigation = [];
    if (oidcRoutesContext.hasAccess) {
      if (oidcRoutesContext.userProfile.isCustomerAdmin) {
        navigation.push({ name: t('admin'), route: '/admin', newTab: false });
      }
      navigation.push({ name: t('audit'), route: '/auditlog', newTab: false });
    }

    navigation.push({ name: t('registerCompany'), route: '/company/add-new', newTab: false });

    if (oidcRoutesContext.userProfile.customerIds.length > 1) {
      navigation.push({ name: t('changeCompany'), onClick: changeCompany, newTab: false });
    }

    if (oidcRoutesContext.hasAccess) {
      navigation.push({ name: t('settings'), route: '/accountsettings', newTab: false });
    }
    navigation.push({ name: t('signOut'), onClick: logout });

    setUserNavigation(navigation);
  }

  const loadPendingCustomersCount = async () => {
    if (oidcRoutesContext.isSimaAdminUser) {
      const countValue = await getPendingCustomersCount();
      setPendingCustomersCount(countValue);
    }
  }

  useEffect(() => {
    console.log(currentNav);
  }, [currentNav])


  return (
    <div className="relative flex flex-col bg-gray-50 h-full">
      <header
        className="sticky top-0 z-10 w-full bg-white"
      >
        <div className="max-w-screen-3xl mx-auto pl-4 pr-6">
          <Popover className="flex justify-between h-20">
            <div className="flex px-2 lg:px-0">
              <div className="flex-shrink-0 flex items-center">
                <NavLink to="/">
                  <img src='/Logo.png' alt="logo" className="h-12 w-auto blur-sm" />
                </NavLink>
              </div>

              <nav
                aria-label="Global"
                className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-8"
              >
                {
                  navigation
                    .filter((m) => m.featureEnabled && !m.hidden)
                    .map((item) => (
                      <Link
                        key={item.name}
                        to={item.route}
                        className={classNames(
                          'block py-0.5 text-sm font-medium',
                          item.current
                            ? 'border-b border-brand-green-dark text-brand-black'
                            : 'text-gray-900 hover:border-b hover:border-brand-green-dark'
                        )}
                      >
                        {item.name}
                      </Link>
                    ))
                }
              </nav>
            </div>
            <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end" />
            <div className="flex items-center lg:hidden">
              <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="sr-only">{t('openMainMenu')}</span>
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Transition.Root as={Fragment}>
              <div className="lg:hidden">
                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Overlay
                    className="z-20 fixed inset-0 bg-black bg-opacity-25"
                    aria-hidden="true"
                  />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                      <div className="pt-3 pb-2">
                        <div className="flex items-center justify-between px-4">
                          <NavLink to="/">
                            <img src='/Logo.png' alt="logo" className="h-6 w-auto blur-sm" />
                          </NavLink>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                              <span className="sr-only">{t('closeMenu')}</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {navigation
                            .filter((m) => m.featureEnabled && !m.hidden)
                            .map((item) =>
                              !item.children ? (
                                <Link
                                  key={item.name}
                                  to={item.route}
                                  className={`${item.current
                                    ? 'bg-brand-green-light text-gray-900'
                                    : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                                    } block rounded-md py-1 px-3 text-sm font-medium `}
                                >
                                  {item.name}
                                </Link>
                              ) : (
                                <Disclosure
                                  as="div"
                                  key={item.name}
                                  className="space-y-1"
                                  defaultOpen={item.current}
                                >
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={
                                          'text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full flex items-center px-3 pr-1 py-2 text-left  rounded-md focus:outline-none cursor-pointer'
                                        }
                                      >
                                        <span className="flex-1 ">{item.name}</span>
                                        <svg
                                          className={`${open ? 'text-gray-400 rotate-90' : 'text-gray-300'
                                            } "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"`}
                                          viewBox="0 0 20 20"
                                          aria-hidden="true"
                                        >
                                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                        </svg>
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="space-y-1">
                                        {item.children.map((subItem) => (
                                          <Link
                                            key={subItem.name}
                                            to={subItem.route}
                                            className={`${subItem.current
                                              ? 'bg-brand-green-light text-gray-900'
                                              : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                                              } block rounded-md py-1 px-3 text-sm font-medium `}
                                          >
                                            {subItem.name}
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )
                            )}
                        </div>
                      </div>
                      <div className="pt-4 pb-2">
                        <div className="flex items-center px-5">
                          <div className="flex-shrink-0">
                            {oidcRoutesContext.userProfile?.userProfileImage ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={oidcRoutesContext.userProfile?.userProfileImage}
                                alt=""
                              />
                            ) : (
                              <UserIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </div>
                          <div className="ml-3">
                            <div className="text-base font-medium text-gray-800">
                              {oidcRoutesContext.customerInfo?.name}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                              {oidcRoutesContext.customerInfo?.orgNumber}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {
                            userNavigation.map((item) => (
                              item.route ?
                                <Link
                                  key={item.name}
                                  to={item.route}
                                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </Link>
                                : <button
                                  onClick={item.onClick}
                                  key={item.name}
                                  className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </button>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
            <div className="hidden ml-4 lg:flex lg:items-center">
              <Menu as="div" className="ml-4 relative flex-shrink-0">
                <div>
                  <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">{t('openUserMenu')}</span>
                    {oidcRoutesContext.userProfile?.userProfileImage ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={oidcRoutesContext.userProfile?.userProfileImage}
                        alt=""
                      />
                    ) : (
                      <UserIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {
                      userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            item.route
                              ? <Link
                                to={item.route}
                                className={classNames(
                                  active ? 'bg-brand-green-light' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                {item.name}
                              </Link>
                              : <button
                                onClick={item.onClick}
                                href={item.route}
                                className={classNames(
                                  active ? 'bg-brand-green-light' : '',
                                  'block px-4 py-2 text-sm w-full text-left text-gray-700 cursor-pointer'
                                )}
                              >
                                {item.name}
                              </button>
                          )
                          }
                        </Menu.Item>
                      ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </Popover>
        </div>

        {buildSubmenuOptionSelected && (
          <div className="max-w-screen-3xl mx-auto mx-auto px-4 sm:px-6">
            <div className="border-t border-brand-green-dark py-3">
              <nav className="flex" aria-label="Breadcrumb">
                <div className="hidden sm:block">
                  <ol role="smartForm" className="flex items-center space-x-4">
                    {buildSubmenuOptionSelected.map((item) => {
                      return (
                        <li key={item.name}>
                          <div className={` flex items-center`}>
                            <Link
                              key={item.name}
                              to={item.route}
                              className={`${item.current
                                ? 'bg-brand-green-light text-brand-black border border-brand-green-dark'
                                : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900'
                                } block rounded-md py-1 px-3 text-sm font-medium `}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </nav>
            </div>
          </div>
        )}
      </header>

      {children}
    </div>
  );
};

export default BaseLayout;
