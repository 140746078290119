import { useState } from "react";
import toast from "react-hot-toast";
import { useErrorHandler } from "./useErrorHandler"

/**
 * Executes and action and handles success/error toasts.
 * Optionally supports a loading toast state that will transition to either success or error. 
 */
export const useToastAction = (showValidationErrors = false, toastIdFixed, showErrorFromResponse = false) => {
  const errorHandler = useErrorHandler();
  const [isExecuting, setIsExecuting] = useState(false);

  const onExecute = async (action, errorMessage, successMessage, loadingMessage, onFinally) => {
    let toastId;
    try {
      setIsExecuting(true)
      toastId = toastIdFixed ? toastIdFixed : loadingMessage
        ? toast.loading(loadingMessage)
        : null;

      // if (toastId) {
      //   await wait(2000); // left in here if we ever wanted to force simulate a long running action
      // }

      await action();
      if (toastId) {
        if (successMessage) {
          toast.success(successMessage, {
            id: toastId
          })
        } else {
          toast.remove(toastId);
        }
      } else {
        if (successMessage) {
          toast.success(successMessage);
        }
      }
    }
    catch (error) {
      errorHandler.handleApiError(error, errorMessage, showValidationErrors, toastId, showErrorFromResponse);
    }
    finally {
      setIsExecuting(false);
      if(onFinally)
      {
        onFinally();
      }
    }
  }


  return {
    execute: (action, errorMessage, successMessage = null, loadingMessage = null, onFinally) => {
      onExecute(action, errorMessage, successMessage, loadingMessage, onFinally);
    },
    isExecuting,
  }
}
