import './styles.scss';
import React, {useEffect, useRef, useState} from 'react';
import { getInvoices, downloadInvoice, getOffers, downloadOffer, 
  downloadOrder, getOrder,updateOffer, rejectOffer, acceptOffer, sendEmailOrder,
  deleteItemFromOffer, convertOffer, createOfferForCustomer, reopenOffer, updateItemQuantity, sendEmailOffer  } from '../../api/rackbeat';
import InputText from '../../components/InputText';
import LinesTable from '../../components/LinesTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../../components/ButtonComponent';
import { useHistory } from "react-router-dom";
import Modal from '../../components/Modal';
import EditOfferAddressModal from '../../components/EditOfferAddressModal';
import {formatCurrency} from "../../utils/currency";
import { useTranslation } from 'react-i18next';
import { resources } from '../../translations/translationsConstants';
import { set } from 'lodash';

const OrderItem = () => {
  const [order, setOrder] = useState();
  const { orderNumber, type } = useParams();
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [bcc, setBcc] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [showAddressModal, setShowAddressModal] = useState(false);
  const history = useHistory();
  const [reference, setReference] = useState('');
  const [note, setNote] = useState('');
  const isFirstRenderRef = useRef(true);
  const [status, setStatus] = useState('')

  const { t } = useTranslation([resources.glasswall]);
  useEffect(() => {
    loadOrder();
  }, []);

  const loadOrder = async () => {
    const response = await getOrder(orderNumber, type);

    setOrder(response || {});
    setReference(order?.other_reference)
    setNote(order?.note)
    isFirstRenderRef.current = true

  
    if(response.is_rejected) {
      setStatus('Rejected');
    } else if(response.is_accepted) {
      setStatus('Accepted');
    } else if(response.is_sent) {
      setStatus('Sent');
    } else if(response.is_shipped) {
      setStatus('Shipped');
    } else if(response.is_booked) {
      setStatus('In Progress');
    } else if(response.is_offer) {
      setStatus('Draft');
    } else {
      setStatus('Created');
    }
  }

  const handleSubmitEmail = async () => {
    try {
      const emailData = {
        mail: {
          send: true,
          receivers: {
            to: to.split(',').map(email => email.trim()), 
            cc: cc.split(',').filter(email => email.trim()), 
            bcc: bcc.split(',').filter(email => email.trim()),
          },
          subject: subject,
          body: body,
        }
      };

      var response;

      if(order.type === "Order")
      {
        response = await sendEmailOrder(order.number, emailData);

      }else{
        response = await sendEmailOffer(order.number, emailData);
      }
      
  
      if (response.status === 200) {
        console.log('Order sent successfully');
        alert('Order sent successfully');
      } else {
        console.error('Failed to send Order', response);
        alert('Failed to send Order');
      }
    } catch (error) {
      console.error('Error sending Order by email:', error);
      alert('Error sending Order by email');
    } finally {
      setShowSendEmailModal(false);
    }
  };
  const handleDownloadOffer = async (number, orderType) => {

    try {
      if(orderType === "Order")
      {
        await downloadOrder(number);
      }else 
      {
        await downloadOffer(number);
      }

       
    } catch (error) {
        console.error('Error downloading:', error);
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case 'Rejected':
        return 'bg-red-500';
      case 'Accepted':
        return 'bg-green-500';
      case 'Sent':
        return 'bg-green-500';
      case 'Shipped':
        return 'bg-green-500';
      case 'Created':
        return 'bg-green-500';
      default:
        return 'bg-yellow-500';
    }
  }

  const handleConvertOffer = async (offerNumber) => {
    await convertOffer(offerNumber);
    
    // TODO: get the order number from the offer and redirect to the order other redirect is temporary
    //history.push(`/offers/${offerNumber}`);
    history.push(`/offers`);
  };
  
  const handleDeleteItem = async (itemId) => {
    await deleteItemFromOffer(order.number, itemId);
    loadOrder();
  }

  const handleCreateOfferForCustomer = async (offerNumber) => {
    await createOfferForCustomer(offerNumber);
    loadOrder();
  }

  const handleUpdateItemQuantity = async (itemId, quantity) => {
    await updateItemQuantity(order.number, itemId, quantity);
    loadOrder();
  }

  const update = async (orderNumber,offerData) => {

    await updateOffer(orderNumber,offerData);
  }

  const handleRejectOffer = async (offerNumber) => {
    await rejectOffer(offerNumber);
    loadOrder();
  };

  const handleAcceptOffer = async (offerNumber) => {
    await acceptOffer(offerNumber);
    
    // TODO: get the order number from the offer and redirect to the order other redirect is temporary
    //history.push(`/offers/${offerNumber}`);
    history.push(`/orders`);
  };

  const handleReopenOffer = async (offerNumber) => {
    await reopenOffer(offerNumber);

    loadOrder();
  }

  useEffect(() => {
    // This condition checks if it's not the initial render, because we only need to call the endpoint
    // when the user types and wait 500ms and don't do it on the first render
    if (!isFirstRenderRef.current) {
      const timeoutId = setTimeout(() => {

         const offerData = {
          reference,
          note
         }

         update(orderNumber,offerData);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      isFirstRenderRef.current = false;
    }
  }, [reference, note]);
 
  return (
    <main className="main-container focus:outline-none h-full relative bg-brand-gray-light">
      <div style={{ backgroundColor: '#AA9C89' }}  className='h-24 w-full border-brand-green-dark border-t border-b'>
        <div className='max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8'>
          <div className='space-y-3'>
            <div className='flex justify-between'>
              <div>
              <h1 className='uppercase text-lg'>{status === 'Draft' ? `ID: ${order?.number}` : `Order: ${order?.number}`} </h1>          
                <h2 className='uppercase text mt-1.5'>{order?.heading ? order?.heading : <>&nbsp;</>}</h2>
              </div>
              <div>
                <div className='flex gap-4'>
                  {
                    status &&
                    <div className={`px-10 py-2 font-extrabold uppercase ${statusColor(status)} border border-brand-green-dark`}>{status}</div>
                  }
                  <div style={{ backgroundColor: '#ede4b6' }} className='text-brand-green-dark px-10 py-2 rounded-sm uppercase border border-brand-green-dark'>
                    Order
                  </div>  
                </div>
              </div>
            </div>
            {/* <div className='flex justify-between pt-6'>
              <div className='flex gap-4'>
                <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>Send on email</Button>
                <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>View Pdf</Button>
              </div>
              {
                (!order?.is_rejected && !order?.is_accepted && !order?.is_sent) && order?.is_offer && (
                  <div className='flex gap-4'>
                    <Button variant={'secondary'} onClick={() => handleCreateOfferForCustomer(order?.number)}>Create offer</Button>
                    <Button variant={'primary'} onClick={() => handleConvertOffer(order?.number)}>Create order</Button>                    
                  </div>
                )
              }
              
              {
                (order?.is_rejected || order?.is_accepted) && (
                  <div className='flex gap-4'>
                    <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>Re Open</Button>
                  </div>
                )
              }
            </div> */}
          </div>
        </div>
      </div>
      <div className='max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8 bg-brand-gray-light flex justify-between pt-6'>
        <div className='flex gap-4'>
          <Button variant={'secondary'} onClick={() => setShowSendEmailModal(true)}>Send on email</Button>
          <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number, order?.type)}>View Pdf</Button>
        </div>
        <div className='flex gap-4'>
        {
          (!order?.is_rejected && !order?.is_accepted && !order?.is_sent) && order?.is_offer && (
            
            <>
              <Button variant={'secondary'} onClick={() => handleCreateOfferForCustomer(order?.number)}>Create offer</Button>
              <Button variant={'primary'} onClick={() => handleConvertOffer(order?.number)}>Create order</Button>
            </>
          )
        }
        {
          (order?.is_rejected || order?.is_accepted) && (
            
              <Button variant={'secondary'} onClick={() => handleDownloadOffer(order?.number)}>Re Open</Button>
          )
        }

        {
          !order?.is_draft &&
          <>
            <Button variant={'secondary'} onClick={() => handleReopenOffer(order?.number)}>Re Open</Button>
            <Button variant={'secondary'} onClick={() => handleRejectOffer(order?.number)}>Reject</Button>
            <Button variant={'primary'} onClick={() => handleAcceptOffer(order?.number)}>Accept</Button>
          </>
        }
        </div>
      </div>
      <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8 bg-brand-gray-light h-full">
        <div className="flex flex-col h-full">
          <div className="max-w-screen-3xl">
            <div className="w-full h-full flex gap-8 mt-5">
              <div className="w-2/3">
                <LinesTable canEdit={!!order?.is_draft} handleUpdateItemQuantity={handleUpdateItemQuantity} handleDeleteItem={handleDeleteItem} offerNumber={order?.number} lines={order?.lines} />
              </div>
              <div className="w-1/3 border p-6 bg-white border-brand-green-dark border">
                <div>
                  <div className="flex justify-between border-b-2 pb-1 mb-4">
                    <p className="text-base font-normal">Address</p>
                    {
                      order?.is_draft && 
                      <button onClick={() => setShowAddressModal(true)}>Edit</button>
                    }   
                  </div>
                  <div className='grid grid-cols-2 gap-2'>
                    <div>
                      <p className='text-base'>Biliing address</p>
                      <p className='text-sm'>{order?.billing_address?.name}</p>
                      <p className='text-sm'>{order?.billing_address?.street}</p>
                      <p className='text-sm'>{order?.billing_address?.street2}</p>
                      <p className='text-sm'>{order?.billing_address?.zipcode} {order?.delivery_address?.city}</p>
                      <p className='text-sm'>{order?.billing_address?.country}</p>
                      <p className='text-sm'>{order?.billing_address?.email}</p>
                      <p className='text-sm'>{order?.billing_address?.phone}</p>
                    </div>
                    <div>
                      <p className='text-base'>Delivery address</p>
                      <p className='text-sm'>{order?.delivery_address?.name}</p>
                      <p className='text-sm'>{order?.delivery_address?.street}</p>
                      <p className='text-sm'>{order?.delivery_address?.street2}</p>
                      <p className='text-sm'>{order?.delivery_address?.zipcode} {order?.delivery_address?.city}</p>
                      <p className='text-sm'>{order?.delivery_address?.country}</p>
                      <p className='text-sm'>{order?.delivery_address?.email}</p>
                      <p className='text-sm'>{order?.delivery_address?.phone}</p>
                    </div>

                  </div>
                </div>
                <div className='mt-8'>
                  <p className="text-base font-normal mb-3 border-b-2 pb-1  mb-4">Information</p>
                  <InputText disabled={!order?.is_draft} label={"Reference"} placeholder={""} value={reference} fullWidth={true} setValue={setReference} />
                  <InputText disabled={!order?.is_draft} label={"Notes"} placeholder={""} value={note} fullWidth={true} rows={6} setValue={setNote} />
                </div>
                <div className='mt-8'>
                  <p className="text-base font-normal mb-3 border-b-2 pb-1  mb-4">Costs</p>
                  <div className="grid grid-cols-2 gap-1">
                      <p className='text-sm'>Subtotal</p> 
                      <p className='text-sm'>{formatCurrency(order?.total_total)} <span className='text-xxs'>NOK</span></p>
                      
                      <p className='text-sm'>Vat</p> 
                      <p className='text-sm'>{formatCurrency(order?.total_total)} <span className='text-xxs'>NOK</span></p>
                      
                      <p className='text-base mt-2'>Total</p> 
                      <p className='text-base mt-2'>{formatCurrency(order?.total_total)} <span className='text-xs'>NOK</span> </p>
                  </div>                
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
      <EditOfferAddressModal offer={order} isShowModal={showAddressModal} setIsShowModal={setShowAddressModal} />
      <Modal isOpen={showSendEmailModal} onClose={() => setShowSendEmailModal(false)} size={'large'} title={`Send Email`}>
        <InputText label="To" placeholder="Recipient Email" value={to} setValue={setTo} fullWidth={true} />
        <InputText label="CC" placeholder="CC Email" value={cc} setValue={setCc} fullWidth={true} />
        <InputText label="BCC" placeholder="BCC Email" value={bcc} setValue={setBcc} fullWidth={true} />
        <InputText label="Subject" placeholder="Email Subject" value={subject} setValue={setSubject} fullWidth={true} />
        <InputText label="Body" placeholder="Email Body" value={body} setValue={setBody} fullWidth={true} rows={4} />

        <div className='flex justify-end gap-4 mt-4'>
          <Button variant={'primary'} size={'md'} type='submit' onClick={handleSubmitEmail} >{t('send')}</Button>
        </div>
      </Modal>
    </main>
  )
};

export default OrderItem;
