import { Modal } from '../index';
import { TextInput } from '../Form/TextInput';
import { t } from 'i18next';
import Button from '../ButtonComponent';
import InputText from '../InputText';
import { useEffect, useState } from 'react';
import { saveAddress as updateAddress } from '../../api/rackbeat';

const EditOfferAddressModal = ({ offer, isShowModal, setIsShowModal }) => {
  const [deliveryAddress, setDeliveryAddress] = useState(offer?.delivery_address);
  const [billingAddress, setBillingAddress] = useState(offer?.billing_address);

  useEffect(() => {
    setDeliveryAddress(offer?.delivery_address);
    setBillingAddress(offer?.billing_address);
  }, [offer]);
  
  const handleDeliveryAddressChange = (value, name) => {
    setDeliveryAddress({ ...deliveryAddress, [name]: value });
  }

  const handleBillingAddressChange = (value, name) => {
    setBillingAddress({ ...billingAddress, [name]: value });
  }

  const handleSaveAddress = async () => {
    await updateAddress(offer.number, {
      deliveryAddress,
      billingAddress
    });
  }

  return (
    <Modal isOpen={isShowModal} onClose={() => setIsShowModal(false)} size={'large'} title={`Change Address`}>
        <div className='grid grid-cols-2 gap-8'>
          <div>
            <p className="text-base font-normal mb-3 border-b-2 mb-4">Delivery Address</p>
            <InputText label="Name" value={deliveryAddress?.name} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'name')} fullWidth={true} />
            <InputText label="Street" value={deliveryAddress?.street} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'street')} fullWidth={true} />
            <InputText label="Street 2" value={deliveryAddress?.street2} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'street2')} fullWidth={true} />
            <InputText label="Zip Code" value={deliveryAddress?.zipcode} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'zipcode')} fullWidth={true} />
            <InputText label="City" value={deliveryAddress?.city} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'city')} fullWidth={true} />
            <InputText label="State" value={deliveryAddress?.state} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'state')} fullWidth={true} />
            <InputText label="Country" value={deliveryAddress?.country} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'country')} fullWidth={true} />
            <InputText label="Email" value={deliveryAddress?.email} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'email')} fullWidth={true} />
            <InputText label="Phone" value={deliveryAddress?.phone} onChange={(e) => handleDeliveryAddressChange(e.target.value, 'phone')} fullWidth={true} />
          </div>
          <div>
            <p className="text-base font-normal mb-3 border-b-2 mb-4">Billing Address</p>
            <InputText label="Name" value={billingAddress?.name} onChange={(e) => handleBillingAddressChange(e.target.value, 'name')} fullWidth={true} />
            <InputText label="Street" value={billingAddress?.street} onChange={(e) => handleBillingAddressChange(e.target.value, 'street')} fullWidth={true} />
            <InputText label="Street 2" value={billingAddress?.street2} onChange={(e) => handleBillingAddressChange(e.target.value, 'street2')} fullWidth={true} />
            <InputText label="Zip Code" value={billingAddress?.zipcode} onChange={(e) => handleBillingAddressChange(e.target.value, 'zipcode')} fullWidth={true} />
            <InputText label="City" value={billingAddress?.city} onChange={(e) => handleBillingAddressChange(e.target.value, 'city')} fullWidth={true} />
            <InputText label="State" value={billingAddress?.state} onChange={(e) => handleBillingAddressChange(e.target.value, 'state')} fullWidth={true} />
            <InputText label="Country" value={billingAddress?.country} onChange={(e) => handleBillingAddressChange(e.target.value, 'country')} fullWidth={true} />
            <InputText label="Email" value={billingAddress?.email} onChange={(e) => handleBillingAddressChange(e.target.value, 'email')} fullWidth={true} />
            <InputText label="Phone" value={billingAddress?.phone} onChange={(e) => handleBillingAddressChange(e.target.value, 'phone')} fullWidth={true} />
          </div>
        </div>
        

        <div className='flex justify-end gap-4 mt-4'>
          <Button variant={'secondary'} size={'md'} type='submit' onClick={() => setIsShowModal(false)} >Cancel</Button>
          <Button variant={'primary'} size={'md'} type='submit' onClick={handleSaveAddress} >Save</Button>
        </div>
      </Modal>
  );
};

export default EditOfferAddressModal;
