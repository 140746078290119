import React, { useEffect, useState } from 'react';
import { formatCurrency } from '../../utils/currency';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import InputText from '../InputText';
import Button from '../ButtonComponent';
import Modal from '../Modal';
import { use } from 'i18next';
import {NavLink} from "react-router-dom";

const LinesTable = ({ lines, canEdit = true, handleDeleteItem, handleUpdateItemQuantity }) => {
  const [showUpdateItemQuantityModal, setShowUpdateItemQuantityModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [newQuantity, setNewQuantity] = useState();

  const getTotal = () => {
    return (lines?.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.line_price*currentValue.quantity);
    }, 0))
  }
  
  const handleShowUpdateItemQuantityModal = (item) => {
    setSelectedItem(item);
    setShowUpdateItemQuantityModal(true);
  }

  const handleSave = async () => {
    await handleUpdateItemQuantity(selectedItem.id, newQuantity);
    setShowUpdateItemQuantityModal(false);
  }

  useEffect(() => {
    if(selectedItem) {
      setNewQuantity(selectedItem.quantity);
    }
  }, [selectedItem])

  return (
    <div className="flex flex-cols gap-5 justify-center w-full">
      <table className="min-w-full divide-y divide-brand-green-dark border-brand-green-dark border">
        <thead className="border-brand-green-dark">
        <tr className=''>
          <th scope="col" className="border-gray-200 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 text-base font-semibold">
            Number
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
            Product
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
            Quantity
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
            Unit Price
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
            Total
          </th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-base font-semibold">
            
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-brand-green-dark bg-white">
      {
        lines?.sort((a, b) => a.sort_index - b.sort_index).map((line, index) => {
          return (
            <tr key={index}>
              <td className="border-gray-200 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6 font-normal text-sm text-brand-gray-text">
                {
                  line.item ?
                    <NavLink to={`/products/${line.item?.number}`} className="focus:outline-none cursor-pointer">
                      <span>{line.item?.number}</span>
                    </NavLink>
                  :
                    <NavLink to={`/products/${line.text}`} className="focus:outline-none cursor-pointer">
                      <span className='text-brand-black'>{line.text}</span>
                    </NavLink>
                }
              </td>
              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {line.name}
              </td>
              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {
                line.item &&
                <>
                  {line.quantity}
                </>
              }
              </td>
              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {
                line.item &&
                <>
                  {formatCurrency(line.line_price)} NOK
                </>
              }
              </td>
              <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {
                line.item &&
                <>
                  {formatCurrency(line.quantity*line.line_price)} NOK
                </>
              } 
              </td>
              <td className="px-3 text-left text-sm font-semibold text-gray-900">
                {
                  canEdit && line.item &&
                  <div className='items-center flex'>
                    <button className="hover:text-brand-black h-6 w-6 mr-2" type="button" >
                      <PencilAltIcon aria-hidden="true" onClick={() => handleShowUpdateItemQuantityModal(line) } />
                    </button>
                    <button className="hover:text-red-600 h-6 w-6" type="button">
                      <TrashIcon aria-hidden="true" onClick={() => handleDeleteItem(line.id)} />
                    </button>
                  </div> 
                }
              </td>
            </tr>
          )
        })
      }
      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
      </th>
      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
      </th>
      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
      </th>
      <th scope="col" className="px-3 py-3.5 h-20 text-left text-sm font-semibold text-gray-900 font-semibold">
        Total
      </th>
      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 font-semibold">
        {formatCurrency(getTotal())} NOK
      </th>
      <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
      </th>
    </tbody>
  </table>

      <Modal isOpen={showUpdateItemQuantityModal} onClose={() => setShowUpdateItemQuantityModal(false)} size={'medium'} title={`Update Quantity`}>
        <div className='grid grid-cols-1 gap-8'>
          <div>
            
            <InputText disabled={true} label="Name" value={selectedItem?.name} onChange={(e) => handleUpdateItemQuantity(selectedItem.id, e.target.value,)} fullWidth={true} />
            <InputText label="Quantity" value={newQuantity} onChange={(e) => setNewQuantity(e.target.value) } fullWidth={true} />
          </div>
        </div>

        <div className='flex justify-end gap-4 mt-10'>
          <Button variant={'secondary'} size={'md'} type='submit' onClick={() => setShowUpdateItemQuantityModal(false)} >Cancel</Button>
          <Button variant={'primary'} size={'md'} type='submit' onClick={() => handleSave()} >Update</Button>
        </div>
      </Modal>    
  
    </div>
  );
};

export default LinesTable;
