import { get, post, put } from './api';

const controllerName = 'Products';

export const getDashboard = async () => {
    return await get(controllerName + '/Categories');
}

export const getProductConfigurations = async () => {
    return await get(controllerName);
}

export const updateProductConfiguration = async (request) => {
    return await put(controllerName, request);
}
export const createProductConfiguration = async (request) => {
    return await post(controllerName, request);
}

export const getProduct = async (productId) => {
    return await get(controllerName + '/' + productId);
}

export const getEmptyProduct = async () => {
    return await get(controllerName + '/empty');
}

export const recalculateProduct = async (request) => {
    return await post(controllerName + '/engine/recalculate', request);
}

export const getCategoriesOnProductType = async (productType) => {
    return await get(controllerName + `/type/${productType}`);
}

export const searchProductRackbeat = async (search) => {
    return await post(controllerName + `/search/rackbeat`, search);
}

export const getAllProductsFromRackbeat = async () => {
    return await get(controllerName + `/search/rackbeat`);
}

export const addProductModule = async (request) => {
    return await post(controllerName + "/engine/module/add", request);
}

export const removeProductModule = async (request) => {
    return await post(controllerName + "/engine/module/remove", request);
}

export const convertProductModule = async (request) => {
    return await post(controllerName + "/engine/module/convert", request);
}

export const getConfigurationModuleDocuments = async (configurationModuleId, language = null) => {
    return await get(controllerName + `/configurationmodules/${configurationModuleId}/documents` + (language ? `?lang=${language}` : ''))
}