export const resources = {
  common: 'common',
  dashboard: 'dashboard',
  audit: 'audit',
  simaAdmin: 'simaAdmin',
  simaProducts: 'simaProducts',
  cart: 'cart',
  orders: 'orders',
  glasswall: 'glasswall',
  projects: 'projects',
  projectItem: 'projectItem',
  pageHeader: 'pageHeader',
  accountSettings: 'accountSettings',
  admin: 'admin',
  internal: 'internal',
  company: 'company'
};

export const translations = {
  common: {
    view: 'view',
    search: 'search',
    back: 'back',
    dashboard: 'Dashboard',
    glasswall: 'Glasswall',
    orders: 'Orders',
    projects: 'Projects',
    previous: 'previous',
    next: 'next',
    of: 'of',
    results: 'results',
    showing: 'showing',
    to: 'to',
    cancel: 'cancel',
    save: 'save'
  },
  dashboard: {
    title: 'Crenordic Marketplace',
    text: 'This is the marketplace',
  },
  audit: {
    list: {
      title: 'list.title',
      text: 'list.text',
      filter: {
        source: 'list.filter.source',
        level: 'list.filter.level',
        from: 'list.filter.from',
        to: 'list.filter.to',
        search: 'list.filter.search',
      },
      table: {
        header: {
          event: "list.table.header.event",
          source: "list.table.header.source",
          type: "list.table.header.type",
          time: "list.table.header.time"
        }
      }
    },
    details: {
      title: "details.title",
      text: "details.text",
      source: "details.source",
      eventName: "details.eventName",
      eventId: "details.eventId",
      level: "details.level",
      user: "details.user",
      logged: "details.logged",
      opCode: "details.opCode",
      category: "details.category",
      keyword: "details.keyword",
      computer: "details.computer",
      gdpr: "details.gdpr"
    }
  },
  simaAdmin: {
    customers: {
      title: "customers.title",
      subtitle: "customers.subtitle",
      list: {
        header: {
          name: "customers.list.header.name",
          status: "customers.list.header.status",
          created: "customers.list.header.created",
          updated: "customers.list.header.updated"
        }
      },
      form: {
        activeStatus: "customers.form.activeStatus",
        pendingStatus: "customers.form.pendingStatus",
        rejectedStatus: "customers.form.rejectedStatus",
      },
      edit: {
        title: "customers.edit.title",
        subtitle: "customers.edit.subtitle",
        form: {
          company: "customers.edit.form.company",
          companyName: "customers.edit.form.companyName",
          country: "customers.edit.form.country",
          organizationNumber: "customers.edit.form.organizationNumber",
          state: "customers.edit.form.state",
          productAreas: "customers.edit.form.productAreas",
          assignAccessFor: "customers.edit.form.assignAccessFor"
        }
      }
    },
    productAreas: {
      list: {
        title: "productAreas.list.title",
        subtitle: "productAreas.list.subtitle",
        header: {
          name: "productAreas.list.header.name",
          createdBy: "productAreas.list.header.createdBy",
          createdDate: "productAreas.list.header.createdDate",
          updatedDate: "productAreas.list.header.updatedDate",
          description: "productAreas.list.header.description",
          logo: "productAreas.list.header.logo",
        }
      },
      edit: {
        title: "productAreas.edit.title",
        subtitle: "productAreas.edit.subtitle",
        form: {
          productArea: "productAreas.edit.form.productArea",
          name: "productAreas.edit.form.name",
          description: "productAreas.edit.form.description",
          createdBy: "productAreas.edit.form.createdBy",
          createdDate: "productAreas.edit.form.createdDate",
          updatedDate: "productAreas.edit.form.updatedDate",
          logo: "productAreas.edit.form.logo",
          change: "productAreas.edit.form.change",
          add: "productAreas.edit.form.add"
        }
      }
    },
    settings: {
      title: "settings.title",
      subtitle: "settings.subtitle",
      activeStatusByDefault: "settings.activeStatusByDefault"
    }
  },
  simaProducts: {
    documentForm: {
      category: "documentForm.category",
      documentContent: 'documentForm.documentContent',
      documentId: "documentForm.documentId",
      language: "documentForm.language",
      type: 'documentForm.type'
    },
    name: "name",
    group: "group",
    type: "type",
    form: {
      barCode: "form.barCode",
      description: "form.description",
      group: "form.group",
      headerIntro: "form.headerIntro",
      isDisabled: "form.isDisabled",
      name: "form.name",
      primarySupplier: "form.primarySupplier",
      productDocuments: "form.productDocuments",
      productNumber: "form.productNumber",
      price: "form.price",
      unit: "form.unit",
      stateInMarketplace: "form.stateInMarketplace",
      type: "form.productType",
      state: {
        active: "form.state.active",
        draft: "form.state.draft",
        disabled: "form.state.disabled"
      }
    },
    productTypes: {
      widthBuildingElementBegin: "productTypes.widthBuildingElementBegin",
      standardWidthWallElement: "productTypes.standardWidthWallElement",
      customWidthWallElement: "productTypes.customWidthWallElement",
      doorElement: "productTypes.doorElement",
      glassWall: "productTypes.glassWall",
      doorProducts: "productTypes.doorProducts",
      widthBuildingElementEnd: "productTypes.widthBuildingElementEnd",
      frameProfile_U: "productTypes.frameProfile_U",
      frameProfile_L: "productTypes.frameProfile_L",
      moduleAccessory: "productTypes.moduleAccessory",
      glassCutTop: "productTypes.glassCutTop",
      glassCutSideWPolish: "productTypes.glassCutSideWPolish",
      drywallPartionMount: "productTypes.drywallPartionMount",
    },
    configModuleTypes: {
      standardWidthGlassWall: "configModuleTypes.standardWidthGlassWall",
      customWidthGlassWall: "configModuleTypes.customWidthGlassWall",
      glassDoor: "configModuleTypes.glassDoor",
      solidDoor: "configModuleTypes.solidDoor",
      glassTape: "configModuleTypes.glassTape",
      glassFrame: "configModuleTypes.glassFrame",
      dryWallPartitionMount: "configModuleTypes.dryWallPartitionMount"
    },
    configModulesForm: {
      displayName: "configModulesForm.displayName",
      maxValue: "configModulesForm.maxValue",
      minValue: "configModulesForm.minValue",
      color: "configModulesForm.color",
      height: "configModulesForm.height",
      width: "configModulesForm.width",
      modifyElement: "configModulesForm.modifyElement",
      selectInput: "configModulesForm.selectInput",
      inputNumber: "configModulesForm.inputNumber",
      inputText: "configModulesForm.inputText",
      toggleSwitch: "configModulesForm.toggleSwitch",
      mounts: "configModulesForm.mounts",
      door: {
        doorHandle: "configModulesForm.door.doorHandle",
        hingeSide: "configModulesForm.door.hingeSide",
        topGlass: "configModulesForm.door.topGlass",
        doorThreshold: "configModulesForm.door.doorThreshold",
      },
      frame: {
        frameProfiles: "configModulesForm.frame.frameProfiles",
        rubberStrip: "configModulesForm.frame.rubberStrip",
        start: "configModulesForm.frame.start",
        end: "configModulesForm.frame.end",
      }
    }
  }
};
