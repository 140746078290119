import { useReactOidc } from '@axa-fr/react-oidc-context';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ManageTeamContext } from '../../../contexts';
import { useClassNames } from '../../../hooks/useClassNames';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const userStatusOptions = [
    { id: 10, name: 'Active' },
    { id: 90, name: 'Suspended' },
    { id: 99, name: 'Suspended' },
  ]

export default function UserTable({ users }) {
    const { oidcUser } = useReactOidc();
    const { classNames } = useClassNames();
    const { t } = useTranslation([resources.admin]);

    const isSuspended = (user) => {
        return user.statusId === 90 || user.statusId === 99;
    }
    
    const TableRow = ({ user }) => {
        const manageTeamContext = useContext(ManageTeamContext);
        return (
            <tr className="odd:bg-white even:bg-gray-100">
                <td className={classNames(
                    `px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900`,
                    isSuspended(user) && `line-through`
                    )
                }>
                    {user.name}
                </td>
                <td className={classNames(
                    `px-6 py-4 whitespace-nowrap text-sm text-gray-500`,
                    isSuspended(user) && `line-through`
                    )
                }>
                    {user.email}
                </td>
                <td className={classNames(
                    `px-6 py-4 whitespace-nowrap text-sm text-gray-500`,
                    isSuspended(user) && `line-through`
                    )
                }>
                {
                    isSuspended(user) ? "Suspended" : "Active"
                }
                </td>
                <td className={classNames(
                    `px-6 py-4 whitespace-nowrap text-sm text-gray-500`,
                    isSuspended(user) && `line-through`
                    )
                }>
                {manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.isReadOnly ? (
                    <p className="font-semibold hover:text-gray-900 ">{manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.roleName}</p>
                    ) : 
                    (<Link to={`/admin/team/role/${user.roleId}`} className="text-brand-black hover:text-brand-black-hover cursor-pointer">
                    {manageTeamContext?.roles.find(r => r.roleId === user.roleId)?.roleName}
                    </Link>)
                }

                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {user.id !== oidcUser.profile.sub && (
                        <Link to={`/admin/team/manage/${user.id}`} className="text-brand-black hover:text-brand-black-hover cursor-pointer">Edit</Link>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-brand-green-dark sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('name')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('Email')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('status')}
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('role')}
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">{t('edit')}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((u, i) => {
                                        return <TableRow user={u} key={u.name} />
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
