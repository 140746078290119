import React, { Fragment, useContext } from "react";
import { TableBody, TableColumn, TableHead, TableHeader, TableRow } from "../../../components/Table";
import { resources, translations } from "../../../translations/translationsConstants";
import { useTranslation } from "react-i18next";
import { Badge, Table as TableCore, useClassNames, useTableActions } from '@metaforcelabs/metaforce-core';
import { productStateName, productStates, productTypeNames } from "../../../utils/constants";
import { ConfigurationProductContext } from "../../../contexts";

export default function ConfigurationProductList({ products }) {
    const { classNames } = useClassNames();
    const { onProductSelect } = useContext(ConfigurationProductContext)
    // const { t: translateProducts } = useTranslation([resources.simaProducts]);
    // const { t: translateCommon } = useTranslation([resources.common]);
    const tableActions = useTableActions(products, 10);
    const { t } = useTranslation([resources.internal]);

    const handleRowClick = (product) => {
        onProductSelect(product);
    }

    return (
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-6">
            <table className="min-w-full divide-y divide-gray-300 border-brand-green-dark">
                <TableHead>
                    <TableHeader>{`${t('name')}`}</TableHeader>
                    <TableHeader>{`${t('type')}`}</TableHeader>
                    <TableHeader>{`${t('status')}`}</TableHeader>
                </TableHead>
                <TableBody>
                    {tableActions.pageData?.map((row, index) => (
                        <Fragment key={index}>
                            <TableRow className={classNames(
                                'cursor-pointer',
                                index % 2 === 0 ? undefined : 'bg-gray-50'
                            )}
                                onClick={e => {
                                    handleRowClick(row);
                                }}
                            >
                                <TableColumn>
                                    <div
                                        className="font-medium text-sm text-brand-black hover:text-indigo-500 border-0 shadow-none"
                                    >
                                        {row.name}
                                    </div>
                                </TableColumn>
                                <TableColumn>
                                    {productTypeNames[row.productType]}
                                </TableColumn>
                                <TableColumn>
                                    <Badge text={productStateName[row.state]} type={row.state === productStates.active ? "success" : row.state === productStates.disabled ? "error" : "info"} />
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}
                </TableBody>
            </table>
            <TableCore.Pagination tableActions={tableActions} />
        </div>
    )
}