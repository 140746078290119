import React from 'react';

export const OidcRoutesContext = React.createContext();
export const AuditContext = React.createContext();
export const AdminContext = React.createContext();
export const ManageTeamContext = React.createContext();
export const SimaAdminContext = React.createContext();
export const SimaProductsContext = React.createContext();
export const SimaSelectedProductContext = React.createContext();
export const SimaProductModuleConfigContext = React.createContext();
export const FormProductModuleConfigContext = React.createContext();
export const ConfigurationProductContext = React.createContext();
