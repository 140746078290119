import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { useToastAction } from '../../../hooks/useToastAction';
import * as Yup from 'yup';
import { resources, translations } from '../../../translations/translationsConstants';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import Modal from '../../../components/Modal/index';
import { createProductModule } from '../../../api/simaProductsConfiguration';
import { ProductState, ProductStates } from './ProductStates';
import { ProductModuleType, ProductModuleTypes } from '../ProductModuleTypes';
import FormikTextArea from '../../../components/Form/Formik/FormikTextArea';

const initialProduct = {
  name: '',
  productId: '',
  description: '',
  state: ProductState.Active,
  type: ProductModuleType.WidthBuildingElementBegin,
};

const NewProductForm = ({ showForm, onClose }) => {
  const { t: translateProducts } = useTranslation([resources.simaProducts]);
  const { t: translateCommon } = useTranslation([resources.common]);
  const [formValues, setFormValues] = useState(initialProduct);
  const saveProductAction = useToastAction();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Field is required"),
    productId: Yup.string().required("Field is required"),
    description: Yup.string().required("Field is required"),
    state: Yup.number().required("Field is required"),
    type: Yup.number().required("Field is required"),
  });

  const saveProduct = (values, { resetForm, setSubmitting }) => {
    saveProductAction.execute(async () => {
      await createProductModule(values);
    }, 'Failed to save product', "Product added with success", null,
      () => {
        resetForm();
        setSubmitting(false);
        onClose();
      });
  }

  return (
    <Modal
      isOpen={showForm}
      onClose={() => { setFormValues({ ...initialProduct }); onClose(); }}
      size={'large'}
      title={`Create new product form`}
    >
      <div className='space-y-4'>
        <div className='grid grid-cols-1 gap-x-2'>
          <Formik initialValues={formValues} onSubmit={saveProduct} enableReinitialize={true} validationSchema={validationSchema}>
            {(props) => (
              <Form className='space-y-4'>
                <div className="grid grid-cols-3 gap-x-3">
                  <FormikInput
                    label={translateProducts(translations.simaProducts.form.name)}
                    name="name"
                    formikProps={props}
                  />
                  <FormikInput
                    label={translateProducts(translations.simaProducts.form.productNumber)}
                    name="productId"
                    formikProps={props}
                  />
                  <FormikSelectInput
                    label={translateProducts(translations.simaProducts.form.stateInMarketplace)}
                    name="state"
                    valueIsNumber={true}
                    options={Object.getOwnPropertyNames(ProductStates).map(x => {
                      return {
                        name: translateProducts(ProductStates[x]),
                        value: x
                      }
                    })}
                    formikProps={props}
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-3">
                  <FormikSelectInput
                    label={translateProducts(translations.simaProducts.form.type)}
                    name="type"
                    valueIsNumber={true}
                    options={Object.getOwnPropertyNames(ProductModuleTypes).map(x => {
                      return {
                        name: translateProducts(ProductModuleTypes[x]),
                        value: x
                      }
                    })}
                    formikProps={props}
                  />
                  <FormikTextArea
                    type="textarea"
                    label={translateProducts(translations.simaProducts.form.description)}
                    name="description"
                    formikProps={props}
                  />
                </div>
                <div>
                  <div className="flex mt-4 justify-end">
                    <FormikSubmitButton
                      text={translateCommon(translations.common.save)}
                      formikProps={props}
                    />
                    <button
                      onClick={onClose}
                      type='button'
                      className="ml-2 px-4 py-2 border border-transparent font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm undefined">
                      {translateCommon(translations.common.cancel)}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default NewProductForm;