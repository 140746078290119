import { Formik } from 'formik';
import React, { useState } from 'react';
import { sendUserInvite } from '../../../api/teams';
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const actionResultType = {
  success: 0,
  failure: 1,
  warning: 2
};

function CreateInvite({ loading, initialValues, roles, onInviteCreated }) {
  const [inviteError, setInviteError] = useState(null);
  const { t } = useTranslation([resources.admin]);

  const sendInvite = async (values) => {
    setInviteError(null);

    try {
      if(values.customerUserRoleId === undefined){
        values = { ...values, customerUserRoleId: roles[0].roleId };
      }
      const resultInvite = await sendUserInvite(values);

      if (resultInvite.type === actionResultType.success) {
        toast.success(
          `${t('inviteCreated')}`
        );
        onInviteCreated();
      } else {
        toast.error(
          `${t('licenseLimited')}`
        );
      }
    } catch (error) {
      if (!error.response) {
        toast.error(`${t('requestFailed')}`);
        return;
      }

      const { status, data } = error.response;

      if (status === 400) {
        setInviteError(data.Message);
      }
    }
  };

  return (
    <div className="w-full">
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            sendInvite(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm
          }) => (
            <form
              onSubmit={handleSubmit}
              onReset={(e) => {
                resetForm();
              }}
            >
              <div className="grid grid-cols-1 gap-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('Email')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      name="email"
                      required
                      value={values.email}
                      onChange={handleChange}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('role')}
                    </label>
                    <div className="mt-2">
                      <select
                        name="customerUserRoleId"
                        value={values.customerUserRoleId}
                        onChange={handleChange}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-brand-green-dark rounded-md"
                      >
                        {roles.map((r) => {
                          return (
                            <option key={r.roleId} value={r.roleId}>
                              {r.roleName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="text-red-400">{inviteError}</div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 sm:gap-x-8 gap-y-4">
                  <button
                    type="reset"
                    className="w-full inline-flex justify-center items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-600 bg-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full inline-flex justify-center items-center px-3 py-2 rounded-md border border-transparent bg-brand-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span>{t('invite')}</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default CreateInvite;