import { useContext } from 'react';
import { OidcRoutesContext } from '../../contexts';
import NonSimaCompanyNormalUser from './NonSimaCompanyNormalUser';
import NonSimaCompanyAdminUser from './NonSimaCompanyAdminUser';
import NotValidSimaLicenseCompany from './NotValidSimaLicenseCompany';
import NotExistingCompanyUser from './NotExistingCompanyUser';
import BaseLayout from '../../layouts/BaseLayout';

const NoCompany = () => {
  const oicdContext = useContext(OidcRoutesContext);

  const getLockedReasonComponent = () => {
    const { userProfile, simaCustomerInfo, customerInfo } = oicdContext;

    if(!!!customerInfo){
      // Your are just a user without selected company
      return <NotExistingCompanyUser/>;
    }
    else if(!!!simaCustomerInfo && !userProfile.isCustomerAdmin)
    {
      // Your company does not have sima access
      return <NonSimaCompanyNormalUser/>;
    }
    else if(!!!simaCustomerInfo && userProfile.isCustomerAdmin)
    {
      // Your company does not have sim access but you are admin
      return <NonSimaCompanyAdminUser/>;
    }
    // Your sima company does not contain valid access
    return <NotValidSimaLicenseCompany/>;
  }

  return (
    <BaseLayout>
       <main
          className="main-container bg-brand-gray-light focus:outline-none h-full"
        >
            <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col pb-10 h-full">
                    <div className="max-w-screen-3xl">
                        <div className="w-full h-full">
                          {getLockedReasonComponent()}  
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </BaseLayout>
  );
};

export default NoCompany;
