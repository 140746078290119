import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useClassNames } from '../../../hooks/useClassNames';
import ProductDocuments from './ProductDocuments';
import { getProductData, getSelectDictionaries, updateProductModuleDetails, getProductConfigurationModules as getConfigModules, getProductDocuments as getProductDocumentsApi } from '../../../api/simaProductsConfiguration';
import ProductForm from './ProductForm';
import { SimaProductsContext, SimaSelectedProductContext } from '../../../contexts';
import { useToastAction } from '../../../hooks/useToastAction';
import { initialInventoryProduct } from './InitialInventoryProduct';
import { useTranslation } from 'react-i18next';
import { resources } from '../../../translations/translationsConstants';

const initTabs = [
  {
    name: 'Product Details',
    current: true,
    component: ProductForm,
  },
  {
    name: 'Documents',
    current: false,
    component: ProductDocuments
  }
];

const SimaProductsDetails = () => {
  const { classNames } = useClassNames();
  const [tabs, setTabs] = useState(initTabs);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductDocuments, setSelectedProductDocuments] = useState([]);
  const [dictionaries, setDictionaries] = useState({ groups: [], primarySuppliers: [] })
  const [cancelToken, setCancelToken] = useState(undefined);
  const productAction = useToastAction();
  const productDocumentsAction = useToastAction();
  const dictionariesAction = useToastAction();
  const productsContext = useContext(SimaProductsContext);
  const { t } = useTranslation([resources.internal]);

  const getProductDetails = () => {
    if (cancelToken !== undefined && productAction.isExecuting) {
      cancelToken.cancel();
      setCancelToken(undefined);
    }
    const tokenSource = axios.CancelToken.source();
    setCancelToken(tokenSource);

    productAction.execute(async () => {
      const product = await getProductData(productsContext.selectedProductId, tokenSource.token);
      console.log(product);
      setSelectedProduct(validateSelectInputValues(product));
    }, `${t('failedtoLoadProducts')}`, undefined, undefined, () => { setCancelToken(undefined) });
  };

  const getProductDocuments = () => {
    if (cancelToken !== undefined && productDocumentsAction.isExecuting) {
      cancelToken.cancel();
      setCancelToken(undefined);
    }
    const tokenSource = axios.CancelToken.source();
    setCancelToken(tokenSource);

    productDocumentsAction.execute(async () => {
      const productDocuments = await getProductDocumentsApi(productsContext.selectedProductId, tokenSource.token);
      setSelectedProductDocuments(productDocuments);
    }, `${t('failedtoLoadProducts')}`, undefined, undefined, () => { setCancelToken(undefined) });
  };


  const getDictionaries = () => {
    dictionariesAction.execute(async () => {
      const dictionaries = await getSelectDictionaries();
      setDictionaries({
        groups: Object.getOwnPropertyNames(dictionaries.groups).map(x => { return { name: x, value: dictionaries.groups[x] } }),
        primarySuppliers: Object.getOwnPropertyNames(dictionaries.suppliers).map(x => { return { name: x, value: dictionaries.suppliers[x] } })
      });
    }, `${t('failedtoLoadProducts')}`, undefined, undefined, () => { setCancelToken(undefined) });
  };

  const getTabComponent = () => {
    var current = tabs.find((tab) => tab.current);
    if (current.component) {
      const Component = current.component;
      return <Component />
    }
    return <div />;
  }

  const handleOnTabItemClick = (tab, e) => {
    e.preventDefault();

    setTabs((state) =>
      state.map((s) => {
        if (s.name === tab.name) {
          s.current = true;
        } else {
          s.current = false;
        }

        return s;
      })
    );
  };

  const handleUpdateProductDetails = async (values, { setSubmitting }) => {
    await updateProductModuleDetails(values);
    setSubmitting(false);
  }

  const validateSelectInputValues = (product) => {
    if (product.inventoryManagementProduct === null) {
      product.inventoryManagementProduct = initialInventoryProduct;
      return product;
    }
    product.inventoryManagementProduct.defaultSupplierId = product?.inventoryManagementProduct?.defaultSupplierId || "";
    product.inventoryManagementProduct.group = product?.inventoryManagementProduct?.group || "";
    return product;
  }

  useEffect(() => {
    if (productsContext.selectedProductId === null) {
      return;
    }

    getProductDetails();
    getProductDocuments();
  }, [productsContext.selectedProductId]);

  useEffect(() => {
    getDictionaries();
  }, []);

  return (
    <SimaSelectedProductContext.Provider value={{
      selectedProduct: selectedProduct,
      dictionaries: dictionaries,
      handleOnSubmit: handleUpdateProductDetails,
      productcDocuments: selectedProductDocuments,
      reloadProductDocuments: getProductDocuments
    }}>
      <nav className="flex justify-between border-b border-brand-green-dark pb-2 mb-2">
        <div className="block">
          <ol className="flex items-center space-x-4">
            {tabs.map((tab, index) => (
              <li key={index}>
                <div className=" flex items-center">
                  <button
                    className={classNames(
                      tab.current
                        ? 'bg-gray-300 text-gray-900'
                        : 'text-gray-900 hover:bg-gray-100 hover:text-gray-900 ',
                      'block rounded-md py-1 px-3 text-sm font-medium'
                    )}
                    onClick={(e) => handleOnTabItemClick(tab, e)}>
                    {tab.name}
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </nav>
      {getTabComponent()}
    </SimaSelectedProductContext.Provider>
  );
};

export default SimaProductsDetails;