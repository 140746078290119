import React, { useState, Fragment, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { resources, translations } from '../../../translations/translationsConstants';
import { useTranslation } from 'react-i18next';
import { useToastAction } from '../../../hooks/useToastAction';
import { useAutoSave } from '../../../hooks/useAutoSave';
import { getProducts } from '../../../api/simaProductsConfiguration';
import { TextInput } from '../../../components/Form/TextInput';
import Button from '../../../components/Button';
import axios from 'axios';
import { Table as TableCore, useTableActions } from '@metaforcelabs/metaforce-core';
import { SimaProductsContext } from '../../../contexts';
import { useClassNames } from '../../../hooks/useClassNames';
import NewProductForm from './NewProductForm';
import { ProductModuleTypes } from '../ProductModuleTypes';

const SimaProductsList = () => {
  const { t: translateProducts } = useTranslation([resources.simaProducts]);
  const { t: translateCommon } = useTranslation([resources.common]);
  const [recordsToShow, setRecordsToShow] = useState([]);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [searchValue, setSearchValue] = useState("");
  const [showNewProductForm, setShowNewProductForm] = useState(false);
  const productsAction = useToastAction();
  const productsContext = useContext(SimaProductsContext);
  const tableActions = useTableActions(recordsToShow, 10);
  const { classNames } = useClassNames();
  const { t } = useTranslation([resources.internal]);

  const filterProducts = () => {
    if (cancelToken !== undefined && productsAction.isExecuting) {
      cancelToken.cancel();
      setCancelToken(undefined);
    }
    const tokenSource = axios.CancelToken.source();
    setCancelToken(tokenSource);

    productsAction.execute(async () => {
      const products = await getProducts(searchValue, tokenSource.token);
      console.log(products)
      setRecordsToShow(products);
    }, `${t('failedtoLoadProducts')}`, undefined, undefined, () => { setCancelToken(undefined) });
  };

  const debouncedOnQueryKeyDown = useAutoSave(filterProducts, `${t('failedToFilterProducts')}`);
  const executeFilterProducts = (value) => {
    setSearchValue(value);
    debouncedOnQueryKeyDown.save();
  }

  useEffect(() => {
    filterProducts();
  }, []);

  return (
    <>
      <div className='grid grid-cols-2 gap-x-3'>
        <TextInput
          name={"name"}
          horizontal={true}
          value={searchValue}
          placeholder={translateCommon(translations.common.search)}
          type={'text'}
          onChange={executeFilterProducts}
        />
        <div className='flex justify-end'>
          <Button variant={Button.variants.primary} onClick={() => setShowNewProductForm(true)}>
            {t('new')}
          </Button>
        </div>
      </div>
      <Table>
        <TableHead>
        <TableHeader>{translateProducts(translations.simaProducts.form.productNumber)}</TableHeader>
          <TableHeader>{translateProducts(translations.simaProducts.name)}</TableHeader>          
          <TableHeader>{translateProducts(translations.simaProducts.type)}</TableHeader>
        </TableHead>
        <TableBody>
          {tableActions.pageData?.map((row, index) => (
            <Fragment key={index}>
              <TableRow className={classNames(
                'cursor-pointer',
                index % 2 === 0 ? undefined : 'bg-gray-50',
                row.id === productsContext.selectedProductId ? ' bg-indigo-50' : undefined
              )}
                onClick={() => productsContext.onProductSelected(row.id)}
              >

                <TableColumn>
                  <div
                    className= {classNames(
                      "font-medium text-sm text-black border-0 shadow-none",
                      row.state === 2 ? 'line-through' : '',
                      row.state === 3 ? 'text-gray-400' : 'text-black'
                    )}                    
                  >
                    {row.productId}
                  </div>
                </TableColumn>
                <TableColumn>
                  <div
                    className= {classNames(
                      "font-medium text-sm text-black border-0 shadow-none",
                      row.state === 2 ? 'line-through' : '',
                      row.state === 3 ? 'text-gray-400' : 'text-black'
                    )}
                  >
                    {row.name}
                  </div>
                </TableColumn>
                <TableColumn>
                  <div
                    className= {classNames(
                      "font-medium text-sm border-0 shadow-none",
                      row.state === 2 ? 'line-through' : '',
                      row.state === 3 ? 'text-gray-400' : 'text-black'
                    )}
                  >
                    {translateProducts(ProductModuleTypes[row.type])}
                  </div>
                </TableColumn>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TableCore.Pagination tableActions={tableActions} />
      <NewProductForm showForm={showNewProductForm} onClose={() => setShowNewProductForm(false)} />
    </>
  );
};

export default SimaProductsList;